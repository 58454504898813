import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import DataTable from 'react-data-table-component';
import { useForm } from "react-hook-form";
import FrameBioScore from './../../Images/Frame_BiometricScoring.png';
import ActionIconEye from './../../Images/ActionEyeIcon.png';
import UploadFilesIcon from './../../Images/uploadFilesIcon.png';
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { BIOPARSINGERRS, PAGENUMBER, PAGENUMBERS, PAGESEARCHS, PAGESIZES  } from '../../Redux/actions';

const BiometricScoring = () => {
    document.title = "Talent Scan - Biometric Scoring"
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [imgErrorMsgPopUpText, setImgErrorMsgPopUpText] = useState('');
    const [imgErrorMsgPopUp, setImgErrorMsgPopUp] = useState(false);
    const [linkGenShowModal2, setLinkGenShowModal2] = useState(false);
    const [PoliciesfilesErrorMessage, setPoliciesfilesErrorMessage] = useState();
    const columns = [
    
        { 
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Id</label>,
            sortable: true, 
             
            selector: (row)=>row.id, 
            cell: row => <> {row.id} </>,
        },
        { 
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Created At</label>,
            sortable: true, 
            
            selector: (row)=>row.created_at, 
            cell: row => <> {moment(row.created_at).format("DD-MM-YYYY")} </>,
        },
        { 
            name: <label className="DarkBlue4_Color font_weight_500 font_14">User</label>,
            sortable: true, 
            
            selector: (row)=>row.user_name, 
            cell: row => <> {row.user_name} </>,
        },
        { 
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Target Files</label>,
            sortable: true, 
            
            selector: (row)=>row.no_of_target_files, 
            cell: row => <> {row.no_of_target_files} </>,
        },
        { 
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Actions</label>,
            sortable: true,   selector: (row)=>row.id , cell: row => <> 
                <div className="DarkBlue4_Color font_weight_300 font_14">
                    <Link to={`/scoreboard?id=${row.id}`}>
                        <img src={ActionIconEye} alt="ActionIconEye" className="ActionIcon ActionIconEye img-fluid" />
                    </Link>
                </div>
            </>,
        },
    ];
    const [ihaveRadioBtn, setIhaveRadioBtn] = useState("");
    const [ihaveResumeRadioBtn, setIhaveResumeRadioBtn] = useState("");
    const [resumeHistory, setResumeHistory] = useState([]);
    const [employeeRequirement, setemployeeRequirement] = useState()
    const [employee_files, setemployee_files] = useState([])
    const { register, handleSubmit, getValues, setValue, formState:{errors}, setError} = useForm({});
    
    const [loader, setLoader] = useState(true);
    const [isViewParseBtnDisabled, setIsViewParseBtnDisabled] = useState(false);

    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [newskill, setnewskill] = useState("")
    const [newposition, setnewposition] = useState("")
    const [showModal, setShowModal] = useState(false);
    const [showModaljd, setshowModaljd] = useState(false);

    const handleModalClose = () => setShowModal(false);

    const handlePageChange = page => {

        getResumeHistory(parseInt(page));
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page = perPage) => {
        getResumeHistory(parseInt(page), newPerPage);
        setPerPage(newPerPage);
    };

    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    
    const getResumeHistory = async (page1, size = perPage) => {
        setLoader(true)
        let token = sessionStorage.getItem('Admintoken');
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
        }
        let offcet = page1 -1 
        axios.get(`apiV1/view-upload-record/?limit=${size}&offset=${offcet*size}`,config).then((res) => {
            setLoader(false)
            res.data.results.map((item, i) => {
                    item.Num = i + 1;
                    return item;
            })
            setResumeHistory(res.data.results)
            setTotalRows(res.data.count)
            setCurrentPage(page1)
            setPerPage(size)
        }).catch((e) => {
            setLoader(false)
        })
    }

    
    useEffect(() => {
        getResumeHistory(1);
        dispatch(BIOPARSINGERRS([]))
        dispatch(PAGESEARCHS(""))
        dispatch(PAGENUMBERS(""))
        dispatch(PAGESIZES(""))
    },[])
    const closejdparsevalue = () => {
        setshowModaljd(false)
        setIsViewParseBtnDisabled(false);
        setValue("employee_requirement", "")
        setValue("employee_file", "")
        setemployeeRequirement()
        setFiles([])
        setjdSkills([])
        setjdPosition([])
        setjdexperience([])
        setjdparsevalue({})
    }
    const [scoreBoardID, setscoreBoardID] = useState()
    const [jdSkills, setjdSkills] = useState([])
    const [jdPosition, setjdPosition] = useState([])
    const [jdexperience, setjdexperience] = useState([])
    const [jdparsevalue, setjdparsevalue] = useState({})
    const jobdescriptionparseapi = () =>{
        setIsViewParseBtnDisabled(true);
        let token = sessionStorage.getItem('Admintoken');
        const formData = new FormData();
        formData.append("job_description", employeeRequirement);
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
        }
        axios.post(`/apiV1/upload-jd/`, formData, config).then((res) => {
            if(res.data) {
                setshowModaljd(true)
                setjdparsevalue(res.data)
                setjdexperience(res.data.data.experience)
                setjdPosition(res.data.data.position)
                setjdSkills(res.data.data.skills)
            }
        }).catch((e) => {
            setIsViewParseBtnDisabled(false);
        });
    }
    const [afterjdparsesub, setafterjdparsesub] = useState(false)
    const [experienceerror, setexperienceerror] = useState()
    const sendparseddataintoapi = () => {
        setexperienceerror("")
        setafterjdparsesub(true)
        let token = sessionStorage.getItem('Admintoken');
        const formData = new FormData();
        jdexperience.map((exp, i)=>{
            return parseInt(exp)
        })
        jdparsevalue.data.experience = jdexperience
        jdparsevalue.data.skills = jdSkills
        jdparsevalue.data.position = jdPosition
        let error = 0;
        if(!jdPosition[0]){
            setafterjdparsesub(false)
            seterrorposition("Please enter position")
            return false
        }
        jdexperience.map((exp, i)=>{
            if(exp == null || exp == undefined || exp == ""){
                error = error + 1
            }
        })
        if(error !== 0){
            setexperienceerror("Please select valid experience")
            return false
        }
        if(jdexperience[0] > jdexperience[1]){
            setafterjdparsesub(false)
            setexperienceerror("Please select valid experience")
            return false
        }
        formData.append("job_description", JSON.stringify(jdparsevalue));
        formData.append("source_file", employeeRequirement);
        for (let i = 0 ; i < files.length ; i++) {
            formData.append("target_files", files[i]);
        }
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
        }
        axios.post(`/apiV1/compare-resume/`, formData, config).then((res) => {
            if(res.data) {
                setShowModal(true)
                setshowModaljd(false)
                setafterjdparsesub(false)
                setscoreBoardID(res.data.instance_id)
                dispatch(BIOPARSINGERRS(res.data.errors));

                setValue("employee_requirement", "")
                setValue("employee_file", "")
                setemployeeRequirement()
                setFiles([])
            }
        }).catch((e) => {
            setafterjdparsesub(false);
        });
    }
    const handleUploadResumesAction = (data) => {
        jobdescriptionparseapi();
    }

    const onloadcheckfiletype = (data) => {
 
        const updatedImageValue = data.target.files[0].name
        const name = data.target.files[0].name;
        const lastDot = name.lastIndexOf('.');
        const ext = name.substring(lastDot + 1);
        let file = data.target.files[0];
        let reader = new FileReader();
        reader.onload = function (e) {
          //setFile(e.target.result);
        };
        reader.readAsDataURL(data.target.files[0]);
    
        if (file.size > 5e6) {
          setImgErrorMsgPopUp(true)
          setTimeout(() => { setImgErrorMsgPopUp(false) }, 3000);
          setImgErrorMsgPopUpText("Please upload a file smaller than 5 MB")
          setValue('employee_requirement', '')
          setPoliciesfilesErrorMessage('please upload a file')
          setemployeeRequirement()
          return false;
    
        } else {
    
          if (data.target.files.length === 1) {
            var temp = data.target.files[0].type;
           
            if (ext === "pdf" || ext === "doc" || ext === "docx") {
    
            } else {
                alert(temp)
              setValue("employee_requirement", "")
             // setPoliciesfilesErrorMessage('Please select valid file')
              setError('employee_requirement', { type: 'custom', message: "Please select valid file" });
              setemployeeRequirement()
              return false
            }
            setPoliciesfilesErrorMessage('')
          } else {
            setPoliciesfilesErrorMessage('This field is required')
          }
        }
    }
    const onloadcheckfiletype2 = (data) => {
 
        const updatedImageValue = data.target.files[0].name
        const name = data.target.files[0].name;
        const lastDot = name.lastIndexOf('.');
        const ext = name.substring(lastDot + 1);
        let file = data.target.files[0];
        let reader = new FileReader();
        reader.onload = function (e) {
          //setFile(e.target.result);
        };
        reader.readAsDataURL(data.target.files[0]);
    
        if (file.size > 5e6) {
          setImgErrorMsgPopUp(true)
          setTimeout(() => { setImgErrorMsgPopUp(false) }, 3000);
          setImgErrorMsgPopUpText("Please upload a file smaller than 5 MB")
          setValue('employee_requirement', '')
          setPoliciesfilesErrorMessage('please upload a file')
          setemployeeRequirement()
          return false;
    
        } else {
    
          if (data.target.files.length === 1) {
            var temp = data.target.files[0].type;
            if (ext === "pdf" || ext === "doc" || ext === "docx") {
    
            } else {
              setValue("employee_requirement", "")
              setPoliciesfilesErrorMessage('Please select valid file')
              setemployeeRequirement()
              return false
            }
            setPoliciesfilesErrorMessage('')
          } else {
            setPoliciesfilesErrorMessage('This field is required')
          }
        }
    }
    const [addskill, setaddskill] = useState(false)
    const [errormsg, seterrormsg] = useState("")
    const [addposition, setaddposition] = useState(false)
    const [errormsgp, seterrormsgp] = useState("")
    const addSkillstocurrent = () => {
        setaddskill(true)
        let skillslist = jdSkills
        setjdSkills([...skillslist, ])
    }

    const removeskillsfromcurrent = (index) => {
        let skillslist = jdSkills
        skillslist.splice(index, 1)

        setjdSkills([...skillslist ])
    }
    const addnewskilltojd = (e) => {
        e.preventDefault()
        let skillslist = jdSkills

        if(newskill){
            let allentered = newskill.split(',');
            let array = [...skillslist, ...allentered]
            let uniq = [...new Set(array)];
            setjdSkills([...uniq ])
            
            
        }else{
            seterrormsg("Please enter valid skill")
        }
        
    
        setnewskill("")
    }
    const addpositiontocurrent = () => {
        setaddposition(true)
        let skillslist = jdPosition
        setjdPosition([...skillslist, ])
    }
    const addnewpositiontojd = () => {
        let positionslist = jdPosition
        if(newposition){
            if(positionslist.indexOf(newposition) == -1){
                setjdPosition([...positionslist, newposition ])
            }else{
                seterrormsgp("The position already exist")
            }
            
        }else{
            seterrormsgp("Please enter valid position")
        }
        setnewposition("")
    }
    const [errorposition, seterrorposition] = useState("")
    const onchangejdposition = (value)=>{
        let positionlist = jdPosition
        positionlist[0] = value
        setjdPosition([...positionlist])
    }
    const onchangejdexperience = (value,i) => {
        let experiencelist = jdexperience
        experiencelist[i] = value
        if(value > 21){
            alert("Please enter valid experience")
        }
        setjdexperience([...experiencelist])

        if(i == 0){
            if(jdexperience[0] && jdexperience[1]){
                if(parseInt(value) > parseInt(jdexperience[1]) ){
                    alert("Please min experience less then max experience")
                }
            } 
        }else{
            if(jdexperience[0] && jdexperience[1]){
                if(parseInt(jdexperience[0]) > parseInt(value)  ){
                    alert("Please min experience less then max experience")
                }
            } 
        }
        
    }
    const handleSubmitModal = () => {
        navigate(`/scoreboard?id=${scoreBoardID}`);
    }
    const removefileSelected = () => {
        setemployeeRequirement()
        setValue("employee_requirement", "")
    }
    const handleFileChange = (event) => {
        setError('employee_file', { type: 'custom', message: "" })
        const selectedFiles = Array.from(event.target.files);
        let count = 0
        selectedFiles.map((item)=>{
            const name = item.name;
            const lastDot = name.lastIndexOf('.');
            const ext = name.substring(lastDot + 1);
            if (ext === "pdf" || ext === "doc" || ext === "docx") {
            } else {
                count += count + 1
            }
        })
        if(count == 0){
            if(selectedFiles.length < 100){
                setFiles([...files, ...selectedFiles]);
            }else{
                setLinkGenShowModal2(true)
                setFiles([])
                setError('employee_file', { type: 'custom', message: "" })
                setValue("employee_file", "")
            }
        }else{
            setFiles([])
            setError('employee_file', { type: 'custom', message: "Please select valid files" })
            setValue("employee_file", "")
        }
    };
    const handleRemoveFile = (index) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };


    return (
        <>
            <div className="row mx-0">
                {/* <div className="col-12 py-5">
                    <div className="text-center">
                        <Link to="/demosite" className="btn btn-primary font_18 font_weight_500 LightGrey3_BgColor DarkBlue3_Color MyBtnSize border-0 mobileMarging10">Resume Parsing</Link>
                        <Link to="/biometricscoring" className="btn btn-primary font_18 font_weight_500 DarkBlue3_BgColor MyBtnSize ms-3 border-0">Biometric Scoring</Link>
                    </div>
                </div> */}
            </div>
            <form className="UploadSourceJobForm" id="UploadSourceJobForm" onSubmit={handleSubmit(handleUploadResumesAction)}>
                <div className="row px-0 mx-0">
                    <div className="col-xxl-8 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 mx-auto">
                        <div className="row">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="ImgContainer text-end">
                                    <img src={FrameBioScore} alt="Frame Bio Score Img" className="img-fluid FrameBioScoreImg" />
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 pt-4">
                                <div className="BannerContainer pb-5">
                                    <h2 className="font_44 DarkBlue4_Color font_weight_500">Talent Scan Biometric Scoring</h2>
                                    <div className="row">
                                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                            <p className="font_18 font_weight_300">Automate the process of extracting key information from resumes, such as skills, experience, and education, to efficiently identify qualified candidates.</p>
                                        </div>
                                    </div>
                                    {/* <Link to="/productdocumentationview" className="font_24 font_weight_400 LightGreen_Color">View Product Documentation</Link> */}
                                    <div className="row pt-3">
                                        {/* <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                            <p className="font_18 font_weight_300">Select your sources document and up to 50 target documents to score.</p>
                                        </div> */}
                                    </div>
                                    
                                    <div className="BtnGroup BiometricScoringBtns">
                                        <div className="row">
                                            <div className="col-xxl-8 col-xl-12 col-lg-12 col-md-8 col-sm-12 col-12">
                                                <form className="OptionalBtnForm" id="OptionalBtnForm">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-12 d-grid mb-2"  >
                                                            {/* <div className="cat IhaveJobBtn mb-2">
                                                                <label className="d-block">
                                                                    
                                                                    <input className="form-check-input" type="radio" name="IhaveA" value="I Have a Job" id="IhaveJobBtn" checked={ihaveRadioBtn === "I Have a Job"} {...register("employee_ihaveJob", { required: "Please select one option..!", })} /><span>I Have a Job</span>
                                                                </label>
                                                                
                                                            </div>
                                                            {errors.employee_ihaveJob && (<small className='text-danger font-bold'>{errors.employee_ihaveJob.message}  </small>)} */}
                                                            {/* <div className="cat IhaveResumeBtn mb-2">
                                                                <label className="d-block">
                                                                     
                                                                    <input className="form-check-input" type="radio" name="IhaveA" value="I Have a Resume" id="IhaveResumeBtn" checked={ihaveRadioBtn === "I Have a Resume"} {...register("employee_ihaveJob", { required: "Please select one option..!", })} /><span>I Have a Resume</span>
                                                                </label>
                                                            </div> */}
                                                        </div>
                                                        <div className="col-sm-6 col-12 d-grid mb-2" >
                                                            {/* <div className="cat IamScoringResumesJobBtn">
                                                                <label className="d-block">
                                                                    
                                                                    <input className="form-check-input" type="radio" name="IhaveResume" value="I am Scoring Resumes" id="IamScoringResumesJobBtn" checked={ihaveResumeRadioBtn === "I am Scoring Resumes"} {...register("employee_iamScoringResumeJob", { required: "Please select one option..!", })}  /><span>I am Scoring Resumes</span>
                                                                </label>
                                                                
                                                            </div>
                                                            {errors.employee_iamScoringResumeJob && (<small className='text-danger font-bold'>{errors.employee_iamScoringResumeJob.message}  </small>)} */}
                                                            {/* <div className="cat IamScoringJobBtn mb-2">
                                                                <label className="d-block">
                                                                     
                                                                    <input className="form-check-input" type="radio" name="IhaveResume" value="I am Scoring Job" id="IamScoringJobBtn" checked={ihaveResumeRadioBtn === "I am Scoring Job"} {...register("employee_iamScoringResumeJob", { required: "Please select one option..!", })} /><span>I am Scoring Job</span>
                                                                </label>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row px-0 py-5 mx-0">
                    <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-12 col-sm-12 col-12 mx-auto">
                        {!isViewParseBtnDisabled ?
                        <div className="row">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 px-3">
                                <div className="LightGrey6_BgColor p-3">
                                    <h3 className="font_20 font_weight_500 DarkBlue4_Color text-center">Upload Job Description</h3>
                                    <div className="row">
                                        <div className="col-xxl-10 mx-auto">
                                            <div className="FormGroup text-center position-relative border border-light bg-white rounded-3">
                                                <div className="FileUploadContainer1">
                                                    <label htmlFor="upload1" className="DarkBlue_Color font_weight_500 FileUploadLable1 d-block">
                                                        <input type="file" id="upload1" 
                                                            {...register("employee_requirement", { required: "Please select  employee_file..!", 
                                                            onChange:(e)=>{setemployeeRequirement(e.target.files[0]);  onloadcheckfiletype(e); }    
                                                            })}
                                                            accept=".doc, .docx, .pdf"
                                                            
                                                        />
                                                        {/* <input type="file" id="upload1" 
                                                            {...register("employee_requirement", { required: "Please select  employee_file..!", })}
                                                            accept=".doc, .docx" onChange={UploadedJobReqHandler}
                                                        /> */}
                                                        <div className="ImgUploadIcon"><img src={UploadFilesIcon} alt="upload Img" className="Upload_Image_Icon" /></div>
                                                        <div className="font_12 font_weight_300 DarkBlue4_Color">
                                                            <small>Upload files</small>
                                                        </div>
                                                    </label>

                                                    
                                                </div>
                                                
                                            </div>
                                            {errors.employee_requirement && (<small className='text-danger font-bold'>{errors.employee_requirement.message}  </small>)}
                                            
                                            <small className='text-danger'> {PoliciesfilesErrorMessage} </small>
                                            {employeeRequirement ? 
                                            <span>{employeeRequirement.name}   <span className="text-danger " onClick={()=>removefileSelected()} > X </span></span> : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 px-3">
                                <div className="LightGrey6_BgColor">
                                    <div className="LightGrey6_BgColor p-3">
                                        <h3 className="font_20 font_weight_500 LightGreen_Color text-center">Upload Target Resume</h3>
                                        <div className="row">
                                            <div className="col-xxl-10 mx-auto">
                                                <div className="FormGroup text-center position-relative border border-light bg-white rounded-3">
                                                    <div className="FileUploadContainer1">
                                                        <label htmlFor="uploadFile1" className="LightGreen_Color font_weight_500 FileUploadLable1 d-block">
                                                            {/* <input type="file" multiple onChange={handleFileChange} /> */}
                                                            <input type="file" id="uploadFile1" multiple 
                                                                {...register("employee_file", { required: "Please select resumes..!", onChange:handleFileChange})}
                                                                accept=".doc, .docx, .pdf"
                                                                
                                                            />
                                                            {/* <input type="file" id="uploadFile1" multiple 
                                                                {...register("employee_file", { required: "Please select  employee_file..!", })}
                                                                accept="application/pdf" onChange={UploadedResumeHandler}
                                                            /> */}
                                                            <div className="ImgUploadIcon"><img src={UploadFilesIcon} alt="upload Img" className="Upload_Image_Icon" /></div>
                                                            <div className="font_12 font_weight_300 LightGreen_Color">
                                                                <small>Upload files</small>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                {errors.employee_file && (<small className='text-danger font-bold'>{errors.employee_file.message}  </small>)}
                                                
                                                {files.map((files, i)=>{
                                                    return <div>{files.name} 
                                                    <span className="text-danger " role="button" onClick={()=>handleRemoveFile(i, files)} > X </span>
                                                    </div>
                                                }) }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  :""}

                        <div className="row">
                            <div className="col-12 pt-5">
                                {isViewParseBtnDisabled ? <div className='text-center'>
                        <div className='spinner-border ' role='status'>
                          <span className='sr-only'></span>
                        </div>
                      </div> :""}

                                <div className="BtnGroup text-center">
                                    {/* <Link to="/scoreboard" className="btn btn-primary DarkBlue3_BgColor MyBtnSize">Score</Link> */}
                                    <button type="submit" className="btn btn-primary DarkBlue3_BgColor font_20 font_weight_500" disabled={isViewParseBtnDisabled} >View Parse Results</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </form>          

            <div className="row LightGrey3_BgColor px-0 mx-0">
                <div className="col-xxl-7 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 mx-auto">
                    <div className="py-5">
                        <div>
                            {/* <p className="font_20 font_weight_400 DarkBlue4_Color text-center">Use it or lose it!! For Security purposes, demo Sessions are only available for review for 30 days.</p> */}
                        </div>
                        {/* <div className="ResumeParsingBlock mb-3">
                            <div className="card">
                                <div className="card-boday  rounded-2 LightBlue_BgColor">
                                    <div className="ResumeParsingHead p-3">
                                        <div className="row text-center">
                                            <div className="col-3">
                                                <div className="DarkBlue4_Color font_weight_500 font_16">Date</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="DarkBlue4_Color font_weight_500 font_16">User</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="DarkBlue4_Color font_weight_500 font_16">Document(s)</div>
                                            </div>
                                            <div className="col-3">
                                                <div className="DarkBlue4_Color font_weight_500 font_16">Actions</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* {
                            resumeHistory.map((item, index) => {
                                return(
                                    <div className="ResumeParsingBlock mb-3" key={index}>
                                        <div className="card">
                                            <div className="card-boday  rounded-2">
                                                <div className="ResumeParsingHead py-2">
                                                    <div className="row text-center d-flex justify-content-center align-items-center">
                                                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                            <div className="DarkBlue4_Color font_weight_300 font_14">{(item.created_at) ? moment(item.created_at).format("DD-MM-YYYY") : ""}</div>
                                                        </div>
                                                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                            <div className="DarkBlue4_Color font_weight_300 font_14">{item.user_name}</div>
                                                        </div>
                                                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                            <div className="DarkBlue4_Color font_weight_300 font_14">
                                                                <button type="button" className="btn LightBlue2_BgColor text-white btn-sm font_12">{item.no_of_source_files} document</button>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                            <div className="DarkBlue4_Color font_weight_300 font_14">
                                                                <Link to={`/signleresumeview?id=${item.id}`}>
                                                                    <img src={ActionIconEye} alt="ActionIconEye" className="ActionIcon ActionIconEye img-fluid" />
                                                                </Link>

                                                                <a href="/files/my-tax.pdf" download className=" ms-2">
                                                                    <button type="button" className="btn btn-transparent" onClick={DownloadFiles} >
                                                                        <img src={ActionIconDownload} alt="ActionIconDownload" className="ActionIcon ActionIconDownload img-fluid" />
                                                                    </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                
                            })
                        } */}
                        {!loader ? 
                        <DataTable 
                            columns={columns} 
                            data={resumeHistory.length > 0 ? resumeHistory : [] } 
                            fixedHeader
                            persistTableHead
                            pagination 
                            paginationServer

                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}  
                            onChangePage={handlePageChange}
                            fixedHeaderScrollHeight='550px'
                            paginationPerPage={perPage}
                            paginationComponentOptions={paginationComponentOptions}
                        />: <div className='text-center'>
                        <div className='spinner-border ' role='status'>
                            <span className='sr-only'></span>
                        </div>
                        </div>}
                        
                    </div>
                </div>
            </div>
            <div className="modals">
                <Modal size="md" show={showModal} onHide={handleModalClose} backdrop="static"  keyboard={false}>
                    <Modal.Header>
                        <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Parse Resume</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4 className='text-center'>Parsed Resume Successfully</h4>
                        <div className='text-center m-3'>
                            <button className='btn btn-primary DarkBlue3_BgColor font_20 font_weight_500' id="ScoreBoard_Assign_UpdateOkayBtn"
                            onClick={ handleSubmitModal }>   OK
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            <Modal size="lg" show={showModaljd} onHide={()=>setshowModaljd(false)} backdrop="static"  keyboard={false}>
                <Modal.Header>
                    <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Job Description Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <div className="">
                            <div className="row" style={{  padding:"10px 15px"}}>
                                <div className="col-md-6">
                                    <h4>Skills</h4>
                                </div>
                                <div className="col-md-6 text-end">
                                    {!addskill ?
                                    <span className="text-end" onClick={()=>{addSkillstocurrent(); seterrormsg(""); setnewskill("")}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-plus-square" viewBox="0 0 16 16"> <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/></svg>
                                    </span> : ""}
                                </div>
                            
                                <div className="col-md-12">
                                    <ul className="jdskills-list">
                                        {jdSkills.map((skills, i)=>{
                                            return (
                                                <li key={i} >
                                                    <span  className="skillname">{skills}</span>
                                                    <span className="jdskillclose" role="button" onClick={()=>removeskillsfromcurrent(i)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/></svg>
                                                    </span>
                                                </li>
                                            )
                                        })}
                                        
                                    </ul>
                                </div>
                            </div>
                            {addskill ?
                            <form id="SetTestSettingsData" className="SetTestSettingsData" onSubmit={addnewskilltojd}>
                                <div className="row" style={{margin:"30px 0px"}}>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" placeholder="Add skill name with comma separator" value={newskill} onChange={(e)=>{setnewskill(e.target.value); seterrormsg("")}} />
                                    </div>
                                    <div className="col-md-3">
                                        <button className="btn btn-primary"  type="submit" >Add</button> &nbsp;
                                        <button className="btn btn-danger" onClick={()=>setaddskill(false)}>Cancel</button>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="text-danger"> {errormsg}</div>
                                    </div>
                                </div> 
                            </form> : ""}

                        </div>

                        <div className="">
                            <div className="row" style={{  padding:"10px 15px"}}>
                                <div className="col-md-6">
                                    <h4>Position</h4>
                                </div>
                                {/* <div className="col-md-6 text-end">
                                    <span className="text-end" onClick={()=>addpositiontocurrent()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-plus-square" viewBox="0 0 16 16"> <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/></svg>
                                    </span>
                                </div> */}

                                <div className="col-md-12">
                                    <ul className="jdskills-list">
                                        {jdPosition.map((position, i)=>{
                                            return (
                                                <input type="text" className="form-control" value={position} onChange={(e)=>{onchangejdposition(e.target.value); seterrorposition("")}} />
                                                // <li key={i}>{position}</li>
                                            )
                                        })}
                                    </ul>
                                    <div className="col-md-12">
                                    <div className="text-danger"> {errorposition}</div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="">
                            <div className="row" style={{  padding:"10px 15px"}}>
                                <div className="col-md-6">
                                    <h4>Experience</h4>
                                </div>
                                <div className="col-md-6 text-end">
                                </div>
                            </div>
                            <div className="row" style={{  padding:"10px 15px"}}>
                                <div className="col-md-6">
                                    <label htmlFor="exampleInputEmailMin" class="form-label">Min</label> 
                                    <input className="form-control" id="exampleInputEmailMin" type="number" name="exampleInputEmailMin" value={jdexperience[0]} min={0} max={20} onChange={(e)=> {onchangejdexperience(e.target.value, 0); setexperienceerror("")}}/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="exampleInputEmailMax" class="form-label">Max</label>
                                    <input className="form-control" id="exampleInputEmailMax" type="number" name="exampleInputEmailMax" value={jdexperience[1]} min={0} max={20} onChange={(e)=> {onchangejdexperience(e.target.value, 1); setexperienceerror("")}}/>
                                </div>
                                
                                {/* {jdexperience.map((experience, i)=>{
                                    return (
                                        <div className="col-md-6" key={i}>
                                            {i == 0 ? <label htmlFor={`exampleInputEmail${i}`} class="form-label">Min</label> : ""}
                                            {i == 1 ? <label htmlFor={`exampleInputEmail${i}`} class="form-label">Max</label> : ""}
                                            <input className="form-control" id={`exampleInputEmail${i}`} type="number" name={`exampleInputEmail${i}`} value={experience} min={0} max={20} onChange={(e)=> {onchangejdexperience(e.target.value, i); setexperienceerror("")}}/>
                                        </div>
                                    )
                                })} */}
                                <div className="">
                                    <span className="text-danger">{experienceerror}</span>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {afterjdparsesub ? <div class="">
                    <div class="spinner-container">
                        <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                        <div>
                            Please wait, your resumes are parseing
                        </div>
                    </div>
                  </div>  :""}
                        </div>
                    </div>
                    <div className="row" style={{  padding:"10px 15px"}}>

                        <div className="col-md-6">

                        </div>
                        <div className="col-md-6 text-end">
                            
                            <button className="btn btn-primary DarkBlue3_BgColor " disabled={afterjdparsesub}  onClick={()=>closejdparsevalue()}>Cancel</button>&nbsp;
                            <button className="btn btn-primary DarkBlue3_BgColor " disabled={afterjdparsesub} onClick={()=>sendparseddataintoapi()}>Update</button> 
                        </div>
                    </div>
                </Modal.Body>

            </Modal>

            <Modal size='md' show={imgErrorMsgPopUp} onHide={() => setImgErrorMsgPopUp(false)} className='text-center  modal fade ' backdrop="static" keyboard={false}>
          <Modal.Header className='modal-header_box bg-my-primary p-3 d-flex justify-content-between ' closeButton> <h1 className="modal-title fs-5 ">Warning Msg </h1> </Modal.Header>
          <div className="modal-body" id="getCode">
            <div className="py-2">
              <div className="text-center">
                <h4 className="blue_dark_text font_weight_700"> {imgErrorMsgPopUpText}</h4>
              </div>
            </div>
            <div className="py-2 mb-3">
              <div className="row">
                <div className="col-12 text-center">
                  <button className='btn btn-primary btn-sm modal2 closeBtn ' onClick={() => setImgErrorMsgPopUp(false)}> Okay </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal size="md" show={linkGenShowModal2} onHide={()=>setLinkGenShowModal2(false)} backdrop="static"  keyboard={false}>
                <Modal.Header>
                    <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Upload</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className='text-center'>Your not allow more then 100 resumes</h4>
                    <div className='text-center m-3'>
                        <button className='btn btn-primary DarkBlue3_BgColor font_20 font_weight_500' id="ScoreBoard_Assign_UpdateOkayBtn"
                        onClick={ ()=>setLinkGenShowModal2(false) }>   OK
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BiometricScoring;