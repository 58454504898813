import React, { useEffect, useState, useRef } from "react";
import DataTable from 'react-data-table-component';
import axios from "axios";
import Layout from "../Components/Layout";
import { Link } from "react-router-dom";
import { FaShareAlt } from "react-icons/fa";
import NoVideo from './../Images/VideoNotAvaliable.png'
import CandidateCheatedTest from './../Images/CandidateCheatedTest.png'
import QuestionNotAttempted from './../Images/QuestionNotAttempted.png'
import CandidateTimeOut from './../Images/CandidateTimeOut.png'

import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";
const QuestionAnsResult = () => {
    const selectedCandidate = useSelector((state) => state.data);
    document.title = "Talent Scan - Question and Answer"
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState({ candidate_answer_data: {} })
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [next, setnext] = useState("")
    const [prev, setprev] = useState("")
    const [totalCount, settotalCount] = useState(0)
    const [linkGenShowModal, setLinkGenShowModal] = useState(false);
    const [linkGenShowModal2, setLinkGenShowModal2] = useState(false);
    const handleModalLinkGenClose = () => setLinkGenShowModal(false);
    const [toemail, settoemail] = useState("")
    const videoRef2 = useRef(null);
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    let obj = {};
    for (let pair of params.entries()) {
        obj[pair[0]] = pair[1];
    }

    const id = obj["id"] ? obj["id"] : 0;
    const attemtid = obj["attemtid"] ? obj["attemtid"] : 0;
    const sections = obj["section"] ? obj["section"] : 0;
    
    const is_qualified = obj["is_qualified"] ? obj["is_qualified"] : 0;
    const candidate_id = obj["candidate_id"] ? obj["candidate_id"] : 0;

    const [sectionsfromurl, setsectionsfromurl] = useState([])
    const [sectionwiseList, setsectionwiseList] = useState([])
    const [selectedQuection, setselectedQuection] = useState({choices:[] })
    const [score_info, setscore_info] = useState({})
    const [candidate_info, setcandidate_info] = useState({})
    const [test_info, settest_info] = useState({})
    const GetTestDashboardList = async (url) => {
        setLoader(false);
        let sectionslist = [];
        if(sections){
            sectionslist = sections.split(',');
        }else{
            if(selectedCandidate.data){
                sectionslist = selectedCandidate.data.sectionslist
            }else{
                sectionslist = []
            }
        }
        setsectionsfromurl([...sectionslist])
        let token = sessionStorage.getItem("Admintoken");
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
        }
        let completeurl = `apiV1/test-dashboard-result/${id}/?attempt_id=${attemtid}&candidate_id=${candidate_id}${sectionslist.length !== 0 ? `&section=${sectionslist[0]}` : ""}`;
        if (url) {
            completeurl = url
        }
        axios.get(completeurl, config).then((res) => {
            setLoader(true)
            if (res.data) {
                setcandidate_info(res.data.candidate_info)
                settest_info(res.data.test_info)
                if(res.data.data.length !== 0){
                    setsectionwiseList(res.data.data)
                    setselectedQuection(res.data.data[0])
                    res.data.data[0].score_info.map((score, i) => {
                        if (sectionslist[0] == score.section__name) {
                            setscore_info(score)
                        }
                    })
                }

            }
           
        })
        
    }

    const nextSectionvalue = async (value) => {

        let token = sessionStorage.getItem("Admintoken");
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
        }

        axios.get(`apiV1/test-dashboard-result/${id}/?attempt_id=${attemtid}&candidate_id=${candidate_id}&section=${value}`, config).then((res) => {
            if (res.data) {
                if(res.data.data.length !== 0){
                    setsectionwiseList(res.data.data)
                    setselectedQuection(res.data.data[0])

                    res.data.data[0].score_info.map((score, i) => {
                        if (value == score.section__name) {
                            setscore_info(score)
                        }
                    })
                }
                
            }
        }).catch((e) => {
            setLoader(false)
        })
    }
    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const changequestion = (i) => {
        let selected = sectionwiseList[i];
        setselectedQuection(selected)
    }
    const nextquestionisthere = () => {

        GetTestDashboardList(next)
    }
    const Previousquestionisthere = () => {
        GetTestDashboardList(prev)
    }

    const [selectedRow, setselectedRow] = useState({})
    const [errormsg, seterrormsg] = useState("")
    const mailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]{2,}\.+[A-Z]{2,}$/i
    // const shareurltoEmployeers = () => {
    //     seterrormsg("")
    //     if (toemail) {
    //         let url = `apiV1/email/videos/?attempt_id=${attemtid}&test_id=${id}&name=${name}&phone=${phone}&email=${email}&to_email=${toemail}&is_qualified=${is_qualified}`
    //         let token = sessionStorage.getItem("Admintoken");
    //         const config = {
    //             headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
    //         }
    //         setLinkGenShowModal(true)
    //             setLinkGenShowModal2(false)
    //         axios.get(url,config).then((res) => {
                

    //         }).catch((e) => {
    //             setLoader(false)
    //         })
    //     }else{
    //         seterrormsg("Please enter valid email")
    //     }
    // }
    useEffect(() => {
        GetTestDashboardList();
    }, [])
    return (
        <>
            <Layout>
                {/* {!loader ? 
                <div className="row mx-5">
                    <div className="col-md-12">
                        <div className="pt-4 pb-5" style={{fontSize:"30px"}}>
                            Question and Answer
                        </div>
                    </div>
                    
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-xxl-9 col-xl-11 col-lg-12 col-md-12 col-sm-11 col-12 mx-auto ">
                                <div>
                                    <div className="card  d-block ">
                                        <div className="card-body">
                                        <h5 className="font_weight_400 font_22">Total Questions {data.question_no}/{totalCount}</h5>
                                        <small>Question Type : {data.question_type}</small>
                                        <div>Category: {data.category}</div>
                                        <div>Score: {data.candidate_answer_data?.score}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row mt-5">
                            <div className="col-xxl-9 col-xl-11 col-lg-12 col-md-12 col-sm-11 col-12 mx-auto">
                                <div className="LightGrey8_BgColor rounded-2 py-3 text-left">
                                     
                                    <h4 className="font_weight_500 font_20 DarkBlue4_Color text-break">Q{data.question_no}. {data.question_name}</h4>

                                    <ul>
                                        {data.choices.map((choice, i)=>{
                                            return (
                                                <li >{choice.text}</li>
                                            )
                                        })}
                                        
                                    </ul>
                                    {data.candidate_answer_data?.answer_text ? 
                                    <div className="text-break"><b>Answer</b>: {data.candidate_answer_data?.answer_text}</div> :""}
                                    
                                </div>
                            </div>
                        </div>
                        <div className="BtnGroup text-center mt-4">
                            {prev ?  <button type="button" className="btn btn-primary DarkBlue3_BgColor MyBtnSize font_weight_500" onClick={()=>Previousquestionisthere()}  >Previous Question</button>  :""}
                            {next ?  <button type="button" className="btn btn-primary DarkBlue3_BgColor MyBtnSize font_weight_500 mx-2" onClick={()=>nextquestionisthere()}  >Next Question</button>  :""}
                            
                        </div>
                        
                    </div>
                    <div className="col-md-6">
                        <div className="videofile">
                            
                            
                        </div>
                    </div>

                    <div className="col-md-12 text-end">
                        <Link to="/testdashboard" className="btn btn-primary DarkBlue3_BgColor MyBtnSize font_weight_500 font_20" >Back</Link>
                    </div>
                </div> : 
                <div className='text-center'>
                    <div className='spinner-border ' role='status'>
                        <span className='sr-only'></span>
                    </div>
                </div>} */}
                {/* 
                {selectedQuection.candidate_answer_data?.video_file ? 
                            <video ref={videoRef2}  width={"100%"} height="400" controls autoPlay  src={selectedQuection.candidate_answer_data?.video_file} >
                                <source src={selectedQuection.candidate_answer_data?.video_file} type="video/mp4"/>
                                <source src={selectedQuection.candidate_answer_data?.video_file} type="video/WebM"/>
                            </video>  : ""} */}

                <div className="row mx-5 mt-3">
                    {/* Candidate Information and All Test Information Starting */}
                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12 py-3">
                        {/* candidate Information and All Test Information */}
                        <div className="CandidateInformationHeading py-2">
                            <h3 className="font_18 px-2">Candidate Information</h3>
                        </div>
                        <div className="CandidateInformation py-1 px-2 shadow mb-2">
                            {candidate_info.name ?
                            <div className="formData pb-2 align-middle">
                                <label className="FormKeyLabel font_14 font_weight_400">Name : </label>
                                <label className="FormValueLabel font_weight_600 font_15 ps-2"> {candidate_info.name}</label>
                            </div> : ""}
                            {candidate_info.email ? 
                            <div className="formData pb-2 align-middle">
                                <label className="FormKeyLabel font_14 font_weight_400">Email : </label>
                                <label className="FormValueLabel font_weight_600 font_15 ps-2"> {candidate_info.email}</label>
                            </div> : ""}
                            {candidate_info.phone ?
                            <div className="formData pb-2 align-middle">
                                <label className="FormKeyLabel font_14 font_weight_400">Phone : </label>
                                <label className="FormValueLabel font_weight_600 font_15 ps-2"> {candidate_info.phone}</label>
                            </div> : ""}
                            <div className="formData pb-2 align-middle">
                                <label className="FormKeyLabel font_14 font_weight_400" title="Over all Qualified Yes/No">Criticality  : </label>
                                <label className="FormValueLabel font_weight_600 font_14 ps-2" title="Yes/No"> { test_info?.criticality == 'easy' ? "Beginner" : test_info?.criticality == 'medium' ? "Intermediate" :test_info?.criticality == 'hard' ? "Advanced" : ""  }</label>
                            </div>
                            
                            {/* <div className="formData pb-2 align-middle">
                                <label className="FormKeyLabel font_14 font_weight_400" title="Over all Qualified Yes/No">Qualified  : </label>
                                <label className="FormValueLabel font_weight_600 font_14 ps-2" title="Yes/No"> {is_qualified == "true" ? "Yes" : "No"}</label>
                            </div>
                            <div className="formData pb-2 align-middle">
                                <label className="FormKeyLabel font_14 font_weight_400">Attempt : </label>
                                <label className="FormValueLabel font_weight_600 font_15 ps-2">{"Yes"}</label>
                            </div> */}
                            <div className="formData pb-2 align-middle">
                                <label className="FormKeyLabel font_14 font_weight_400" title="Over all Qualified Yes/No">Submitted At  : </label>
                                <label className="FormValueLabel font_weight_600 font_14 ps-2" title="Yes/No"> {candidate_info.submitted_at ? moment(candidate_info.submitted_at).format("DD-MM-YYYY hh:mm A") : "Not Submited"}</label>
                            </div>
                            {/* <div className="formData pb-2 align-middle  ">
                                <label className="FormKeyLabel font_14 font_weight_400">Share : </label>
                                <label className="FormValueLabel font_weight_600 font_15 ps-2" role="button" title="Share Result with" onClick={()=>{setLinkGenShowModal2 (true); settoemail(""); }}  >Share</label>
                            </div>  */}
                             {/* <div className="formData pb-2 align-middle">
                                <label className="FormKeyLabel font_18 font_weight_400 DarkBlue2_Color">Name : </label>
                                <label className="FormValueLabel DarkBlue2_Color font_weight_600 font_18 ">Test</label>
                            </div>  */}
                        </div>

                        <div className="CandidateAllTestInformation mt-4">
                            <ul className="CandidateAllTestList">
                                <li className="">
                                    <div className="CandidateTestItem activeOne">
                                        <div className="row px-2">
                                            <div className="col testName">{selectedQuection.category}</div>
                                            <div className="col testIsActive text-end">Active</div>
                                        </div>
                                    </div>
                                    <div className="px-2 shadow rounded">
                                        <div className="formData px-2 pb-2 d-flex justify-content-between align-middle">
                                            <label className="FormKeyLabel font_14 font_weight_400 DarkBlue2_Color">Total Score : </label>
                                            <label className="FormValueLabel DarkBlue2_Color font_weight_600 font_14 ps-2 text-end"> {score_info.total_score}</label>
                                        </div>
                                        <div className="formData px-2 pb-2 d-flex justify-content-between align-middle">
                                            <label className="FormKeyLabel font_14 font_weight_400 DarkBlue2_Color">Cutoff  Score : </label>
                                            <label className="FormValueLabel DarkBlue2_Color font_weight_600 font_14 ps-2 text-end"> {score_info.cutoff_score}</label>
                                        </div>
                                        <div className="formData px-2 pb-2 d-flex justify-content-between align-middle">
                                            <label className="FormKeyLabel font_14 font_weight_400 DarkBlue2_Color">Candidate Score : </label>
                                            <label className="FormValueLabel DarkBlue2_Color font_weight_600 font_14 ps-2 text-end">{score_info.score_secured}</label>
                                        </div>
                                        <div className="formData px-2 pb-2 d-flex justify-content-between align-middle">
                                            <label className="FormKeyLabel font_14 font_weight_400 DarkBlue2_Color">Behavioural Score : </label>
                                            <label className="FormValueLabel DarkBlue2_Color font_weight_600 font_14 ps-2 text-end">{score_info.behavioural_score}</label>
                                        </div>
                                        <div className="formData px-2 pb-2 d-flex justify-content-between align-middle">
                                            <label className="FormKeyLabel font_14 font_weight_400 DarkBlue2_Color">Qualified : </label>
                                            <label className="FormValueLabel font_14 ps-2 DarkBlue2_Color font_weight_600 font_14  text-end">{score_info.is_qualified ? "Yes" : "No"}</label>
                                        </div>
                                    </div>

                                </li>
                                {sectionsfromurl.map((section, i) => {
                                    return (
                                        section !== selectedQuection.category ?
                                            <li className="CandidateTestItem" key={i} onClick={() => nextSectionvalue(section)}>
                                                <div className="row px-2">
                                                    <div className="col testName" >{section}</div>
                                                    <div className="col testIsActive text-end"></div>
                                                </div>
                                            </li> : ""
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="col-xxl-8 col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12 py-3">
                       
                        {loader ?
                            <div className="QuestionAndAnswer">
                                {selectedQuection.attempted_status == "Attempted" ? 
                                <div className="videofile shadow-lg bg-dark rounded">
                                    
                                    {selectedQuection.candidate_answer_data?.video_file ?
                                        <video className="" ref={videoRef2} width={"100%"} height="500" controls autoPlay src={selectedQuection.candidate_answer_data?.video_file} >
                                        </video> : <img src={NoVideo} alt="NoVideo" className="noVideo" width="100%" />}
                                </div> : selectedQuection.attempted_status == "Time Out!" ?  
                                <div className="videofile shadow-lg bg-dark rounded">
                                    
                                    
                                    {selectedQuection.candidate_answer_data?.video_file ?
                                        <video className="rounded" ref={videoRef2} width={"100%"} height="80" src={selectedQuection.candidate_answer_data?.video_file} > </video> : <img src={CandidateTimeOut} alt="NoVideo" className="noVideo" width="100%" />}
                                </div> : selectedQuection.attempted_status == "Cheated!!" ?
                                    <div className="videofile shadow-lg bg-dark rounded">
                                    
                                    
                                    {selectedQuection.candidate_answer_data?.video_file ?
                                        <video className="rounded" ref={videoRef2} width={"100%"} height="80" src={selectedQuection.candidate_answer_data?.video_file} > </video> : <img src={CandidateCheatedTest} alt="NoVideo" className="noVideo" width="100%" />}
                                </div> : selectedQuection.attempted_status == "Not Attempted" ?<div className="videofile shadow-lg bg-dark rounded">
                                    
                                    
                                    {selectedQuection.candidate_answer_data?.video_file ?
                                        <video className="rounded" ref={videoRef2} width={"100%"} height="80" src={selectedQuection.candidate_answer_data?.video_file} > </video> : <img src={QuestionNotAttempted} alt="NoVideo" className="noVideo" width="100%" />}
                                </div> : ""}


                                {/* <div className="videofile shadow-lg bg-dark rounded">
                                    {selectedQuection.candidate_answer_data?.video_file ?
                                        <video className="" ref={videoRef2} width={"100%"} height="500" controls autoPlay src={selectedQuection.candidate_answer_data?.video_file} >
                                        </video> : <img src={NoVideo} alt="NoVideo" className="noVideo" width="100%" />}
                                </div> */}
                                {selectedQuection.candidate_answer_data && selectedQuection.candidate_answer_data.criticality ? <div className="mt-2"><span className="font_weight_500">Criticality :</span> {   capitalizeFirstLetter(selectedQuection.candidate_answer_data?.criticality) }</div>: ""}
                                {selectedQuection.question_name ? <h3 className="QuestionIs font_weight_500 font_20 DarkBlue4_Color text-break pt-3 pb-4">Question :  {selectedQuection.question_name}</h3> : ""}
                                <ul>
                                    {selectedQuection.choices.map((choice, i) => {
                                        return (
                                            <li key={i} >{choice.text}</li>
                                        )
                                    })}

                                </ul>


                                {selectedQuection.candidate_answer_data?.answer_text ? <p className={selectedQuection.category == "Coding" ? "AnswerIs font_weight_400 font_16 p-0 m-0" : "font_weight_400 font_16 p-0 m-0"}><span className="font_weight_500">Answer :</span>   {selectedQuection.candidate_answer_data?.answer_text}</p> : ""}
                                
                                
                                {selectedQuection.choices.length !== 0 ?
                                <p>
                                    <b>Correct Answer:</b>{selectedQuection.choices.map((choice, i) => {
                                        return ( choice.is_correct ?  " " + choice.text : "" )
                                    })} 
                                </p> : ""}

                                {selectedQuection.candidate_answer_data?.feedback ? <p className="font_weight_400 font_16 p-0 m-0 mt-5"><span className="font_weight_500">Feedback :</span>   {selectedQuection.candidate_answer_data?.feedback}</p> : ""}
                            </div> : 
                            <div className='text-center'>
                                <div className='spinner-border ' role='status'>
                                    <span className='sr-only'></span>
                                </div>
                            </div>
                        }

                    </div>

                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 py-3">

                        <ul className="VideoFileList">
                            {sectionwiseList.map((section, i) => {
                                return (
                                    <li className="VideoFileItem mb-2  " key={i+1000} onClick={() => changequestion(i)}>
                                        <div className="videofile row shadow">
                                            {section.attempted_status == "Attempted" ? 
                                            <div className={section.id == selectedQuection.id ? "col-6 p-1 rounded bg-dark activeVideo" : "col-6 p-1 rounded bg-dark"}>
                                                
                                                
                                                {section.candidate_answer_data?.video_file ?
                                                    <video className="rounded" ref={videoRef2} width={"100%"} height="80" src={section.candidate_answer_data?.video_file} > </video> : <img src={NoVideo} alt="NoVideo" className="noVideo" width="100%" />}
                                            </div> : section.attempted_status == "Time Out!" ?  
                                            <div className={section.id == selectedQuection.id ? "col-6 p-1 rounded bg-dark activeVideo" : "col-6 p-1 rounded bg-dark"}>
                                                
                                                
                                                {section.candidate_answer_data?.video_file ?
                                                    <video className="rounded" ref={videoRef2} width={"100%"} height="80" src={section.candidate_answer_data?.video_file} > </video> : <img src={CandidateTimeOut} alt="NoVideo" className="noVideo" width="100%" />}
                                            </div> : section.attempted_status == "Cheated!!" ?
                                             <div className={section.id == selectedQuection.id ? "col-6 p-1 rounded bg-dark activeVideo" : "col-6 p-1 rounded bg-dark"}>
                                                
                                                
                                             {section.candidate_answer_data?.video_file ?
                                                 <video className="rounded" ref={videoRef2} width={"100%"} height="80" src={section.candidate_answer_data?.video_file} > </video> : <img src={CandidateCheatedTest} alt="NoVideo" className="noVideo" width="100%" />}
                                         </div> : section.attempted_status == "Not Attempted" ? <div className={section.id == selectedQuection.id ? "col-6 p-1 rounded bg-dark activeVideo" : "col-6 p-1 rounded bg-dark"}>
                                                
                                                
                                                {section.candidate_answer_data?.video_file ?
                                                    <video className="rounded" ref={videoRef2} width={"100%"} height="80" src={section.candidate_answer_data?.video_file} > </video> : <img src={QuestionNotAttempted} alt="NoVideo" className="noVideo" width="100%" />}
                                            </div> : ""}
                                            <div className={section.id == selectedQuection.id ? "col-6 ps-0 bg-light activeVideo" : "col-6 ps-0 bg-light"}>
                                                <div className="TestQuestionVideoTitle font_weight_500 font_14 lh-sm pt-1 px-1" title={section.question_name}>{section.question_name}</div>
                                                <div className="ScoreInfo font_12 px-1">Score: <span className="ScoreValue"> {section.candidate_answer_data?.score ? section.candidate_answer_data?.score : 0}</span></div>
                                                <div className="ScoreInfo font_10 px-1">  <span className="ScoreValue"> {section.attempted_status ? section.attempted_status : "Candidate Cheating" }</span></div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="">
                    <div className=" text-end mb-5">
                        <Link   to={`/attemptedtestlist2?id=${id}`} className="btn btn-primary DarkBlue3_BgColor MyBtnSize font_weight_500 font_20" >Back</Link>
                    </div>
                </div>
                </div>
                
            </Layout>
            {/* <Modal size="md" show={linkGenShowModal2} onHide={()=>setLinkGenShowModal2(false)} backdrop="static"  keyboard={false}>
                <Modal.Header>
                    <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Share With Related Interviewer Team</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="formgroup">
                        <label className="form-field-label" title="Provided Hr Emails to share Candidate Information">Interviewer Emails</label>
                        <input type="email" placeholder="Interviewer Emails with common separation" value={toemail} onChange={(e)=>{settoemail(e.target.value); seterrormsg("")}} className="form-control" />
                        <small className="text-danger"> {errormsg}</small>
                    </div>
                    <div className='text-center m-3'>
                        <button className='btn btn-primary DarkBlue3_BgColor font_20 font_weight_500' id="ScoreBoard_Assign_UpdateOkayBtn" onClick={ ()=>setLinkGenShowModal2(false) }>   Close </button>
                        <button type="button" class="btn btn-primary DarkBlue3_BgColor font_20 font_weight_500" onClick={()=>shareurltoEmployeers()}>Share</button>
                    </div>
                </Modal.Body>
            </Modal> */}

            <Modal size="md" show={linkGenShowModal} onHide={handleModalLinkGenClose} backdrop="static"  keyboard={false}>
                <Modal.Header>
                    <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Share</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className='text-center'>Thanks! We're merging question videos into one. Check email soon.</h4>
                    <div className='text-center m-3'>
                        <button className='btn btn-primary DarkBlue3_BgColor font_20 font_weight_500' id="ScoreBoard_Assign_UpdateOkayBtn"
                        onClick={ handleModalLinkGenClose }>   OK
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default QuestionAnsResult