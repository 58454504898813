import React, {useState, useEffect} from "react";
import Layout from "../../Components/Layout";
import { Link } from "react-router-dom";
import TestIcon1 from './../../Images/Test_Icon_1.png';
import TestIcon2 from './../../Images/Test_Icon_2.png';
import TestIcon3 from './../../Images/Test_Icon_3.png';
import TestIcon4 from './../../Images/Test_Icon_4.png';
import TestIcon5 from './../../Images/Test_Icon_5.png';
import axios from "axios";
import talentscanlogo from './../../Images/talent-scan-logo-final-white.png';
import Modal from 'react-bootstrap/Modal';
const StartTest = () => {
    const [listOfTests, setlistOfTests] = useState([])
    const [selectedTestids, setselectedTestids] = useState([])
    const [linkGenShowModal2, setLinkGenShowModal2] = useState(false)
    const [loader, setloader] = useState(false)
    const getcandidateassignedtestlist = () => {
        setloader(false)
        axios.defaults.headers.common = { 'Authorization': `${sessionStorage.getItem("token")}` }
        axios.get('/apiV1/candidate-assigned-test-list/').then((res) => {
                if(res.data.results.length !== 0 ){
                    setlistOfTests(res.data.results)
                    setloader(true)
                }
                setloader(true)
            }
        ).catch((e) => {
            console.log()
            setloader(true)
        })
    }
    useEffect(()=>{
        getcandidateassignedtestlist();
    }, [])
    return (
        <>
            <Layout className="position-relative">
                <img src={TestIcon1} alt="TestIcon1" className="TestIcons" id="TestIcon1" />
                <img src={TestIcon2} alt="TestIcon2" className="TestIcons" id="TestIcon2" />
                <img src={TestIcon1} alt="TestIcon3" className="TestIcons" id="TestIcon3" />
                <img src={TestIcon3} alt="TestIcon4" className="TestIcons" id="TestIcon4" />
                <img src={TestIcon4} alt="TestIcon5" className="TestIcons" id="TestIcon5" />
                <img src={TestIcon5} alt="TestIcon6" className="TestIcons" id="TestIcon6" />
                <img src={TestIcon5} alt="TestIcon7" className="TestIcons" id="TestIcon7" />
                <img src={TestIcon4} alt="TestIcon8" className="TestIcons" id="TestIcon8" />
                <div className="row mx-0">
                    <div className="col-12 my-2">
                        <div className="rounded-5 DarkBlue3_BgColor py-3">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    {/* <Link to="/" className="text-decoration-none"> */}
                                    <img src={talentscanlogo} alt="logo" />
                                    {/* </Link> */}
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-md-end text-sm-end text-start">
                                    <h2 className="font_40 font_weight_800 LineHeight29 mb-0 py-3 pe-5"><span className="text-light">Pre</span><span className="LightGreen_Color">view</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-12 my-5">
                        <div className="row">
                            {loader ?
                            <div className="col-md-12">
                                {listOfTests.length !== 0 ?
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Test Name</th>
                                            <th>Attempted</th>
                                            <th>Designation</th>
                                            {/* <th>Description</th> */}
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listOfTests.map((tests,i)=>{
                                            return (
                                                <tr key={i}>
                                                    <td>{tests.test__test_name}</td>
                                                    <td>{tests.is_attempted ? "Done": "Not Done"}</td>
                                                    <td>{tests.test__test_designation}</td>
                                                    {/* <td>{tests.test__description}</td> */}
                                                    <td><button type="button"  className="btn btn-primary DarkBlue3_BgColor" onClick={()=>{setLinkGenShowModal2(true); setselectedTestids(tests)}}>Start</button></td>
                                                </tr>
                                            )
                                        })}
                                    
                                    </tbody>
                                </table>  : <div className="font_25 text-center">No tests are assigned to you please contact your hr</div>}
                            </div>  : 
                            <div className='text-center'>
                                <div className='spinner-border ' role='status'>
                                    <span className='sr-only'> </span>
                                </div>
                            </div>}
                        </div>
                    </div>

                </div>
            </Layout> 
            <Modal size="lg" show={linkGenShowModal2} onHide={()=>setLinkGenShowModal2(false)} backdrop="static"  keyboard={false}>
                <Modal.Header>
                    <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Instructions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <ul> 
                            <li>Complete the test within the given time frame </li>
                            <li>Make sure you have a stable internet connection and are in a quiet, distraction-free environment.</li>
                            <li>Do not refresh or close the test window during the examination, as this may result in an incomplete submission.</li>
                            <li>Test your computer’s webcam, microphone, and speakers/headphones to ensure they are working properly as some tests may include audio or video components</li>
                            <li>For optimal audio capture, kindly ensure you speak loudly and clearly, maintaining an appropriate distance from the microphone</li>
                            <li>For submit your answer the button will be appears for the 10 sec</li>
                            <li>Do not click outside of the window if click that we will come as cheating</li>
                            <li>Please refrain from using any additional applications during the duration of the test. Non-compliance with this rule will result in the automatic termination of the test session.</li>
                        </ul>
                    </div>
                    <div className='text-center m-3'>
                        <Link className="btn btn-primary" to={`/starttest2?id=${selectedTestids.id}&candidate_id=${selectedTestids.candidate_id}`}  >Start</Link>
                        {/* <button type="button" class="btn btn-primary DarkBlue3_BgColor font_20 font_weight_500" onClick={()=>shareurltoEmployeers()}>Share</button> */}
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default StartTest;