import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Layout from "../../Components/Layout";
import LoginBg from './../../Images/LoginBg.webp';
import axios from "axios";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import talentscanlogo from './../../Images/talent-scan-logo-final.png';
const LoginTest = () => {

    const navigate = useNavigate();
    const [loginEmailerror, setLoginEmailerror] = useState("");
    const [type, setType] = useState('password');
    const [passwordIcon, setPasswordIcon] = useState(<FaEyeSlash />)
    const [loader, setLeader] = useState(false)
    const { register, handleSubmit, formState: { errors }, trigger,} = useForm({});

    const loginAction = (data) => {
        setLeader(true)
        setLoginEmailerror("")
        let req = {
            email: data.Email,
            password: data.Password
        }
        axios.post('apiV1/candidate-login/', req).then((res) => {
                sessionStorage.setItem('token', res.data.token)
                sessionStorage.setItem('testLogin', true)
                sessionStorage.removeItem("tabCount")
                setLeader(false)
                navigate("/starttest")
            }
        ).catch((e) => {
            setLeader(false)
            if(e.response.data.message){
                setLoginEmailerror(e.response.data.message)
            }
        });
    }

    const passwordHandleToggle = () => {
        if (type === 'password') {
            setPasswordIcon(<FaEye />);
            setType('text');
        } else {
            setPasswordIcon(<FaEyeSlash />);
            setType('password');
        }
    }

    return (
        <>
            <Layout>
                <div className="row mx-0">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-sm-12 col-12 mx-auto pt-5">
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="row shadow rounded-1">
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-8 col-12 px-5 pt-4">
                                        <div className="text-center pt-5 pb-5">
                                            <h2 className="font_24 DarkBlue_Color font_weight_600 LineHeight29 mb-0">Sign in to your Account for Start Test</h2>
                                            <img src={talentscanlogo} alt="Talent scan logo" />
                                            
                                        </div>
                                        <div className="">
                                            <h3 className="font_20 font_weight_500 text-dark LineHeight24">Sign In</h3>
                                        </div>
                                        <form id="LoginForm" className="LoginForm" onSubmit={handleSubmit(loginAction)}>
                                            {/* {
                                                Object.keys(validationErrors).length !== 0 && 
                                                <p className="text-center">
                                                    <small className="text-danger ">Incorrect Email or Password</small>
                                                </p>
                                            } */}
                                            <div className="FormGroup">
                                                <label htmlFor="LoginTestEmailInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >Enter Email <span className="font_14 text-danger">*</span></label>
                                                <input type="email" className="form-control FormInput" id="LoginTestEmailInput" name="LoginEmail"
                                                    {...register("Email", {
                                                        required: "This field is required",
                                                        pattern: {
                                                            value: /^[0-9+]{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/,
                                                            message: "Please enter a valid Email ID",
                                                        },
                                                        minLength: {
                                                            value: 3,
                                                            message: "Please enter min 3 characters ",
                                                        },
                                                    })}
                                                    onKeyUp={() => {
                                                        trigger("Email");
                                                    }}
                                                //  value={LoginEmail} onChange={(e) => setLoginEmail(e.target.value)} 
                                                />
                                                {errors.Email && (<small className='text-danger font-bold'>{errors.Email.message}  </small>)}
                                                {/* <small id="LoginTestEmailInputErr" className="font_12 text-danger">Email Error</small> */}
                                            </div>

                                            <div className="FormGroup position-relative">
                                                <label htmlFor="LoginTestPasswordInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color">Enter Password  <span className="font_14 text-danger">*</span></label>
                                                <input type={type} className="form-control FormInput" id="LoginTestPasswordInput" name="LoginPassword"
                                                {...register("Password", {
                                                    required: "This field is required",
                                                })}
                                                onKeyUp={() => {
                                                    trigger("Password");
                                                }}
                                                //  value={LoginPassword} onChange={(e) => setLoginPassword(e.target.value)} 
                                                />
                                                {errors.Password && (<small className='text-danger font-bold'>{errors.Password.message}  </small>)}

                                                <button type="button" className="position-absolute btn PassShowBtn" onClick={passwordHandleToggle}>{passwordIcon}</button>
                                                <small id="LoginTestPasswordInputErr" className="font_12 text-danger">Password Error</small>
                                            </div>

                                            <div className="FormGroup pb-4">
                                                <div className="row">
                                                    <div className="col-sm-6 col-12">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="RememberMeCheck" />
                                                            <label className="form-check-label FormLabel DarkBlue2_Color font_14 font_weight_400" htmlFor="RememberMeCheck">
                                                                Remember Me
                                                            </label>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>

                                            <small className='text-danger font-bold'>{loginEmailerror}</small>

                                            <div className="BtnGroup d-grid LineHeight22">
                                            {!loader ? (
                                                ""
                                                ) : (
                                                <div className='text-center'>
                                                    <div className='spinner-border ' role='status'>
                                                    <span className='sr-only'></span>
                                                    </div>
                                                </div>
                                                )}
                                                {/* <Link to="/starttest" className="btn btn-primary BlugGradBtn font_18 font_weight_600" id="SignInBtn">Sign In</Link> */}
                                                <button type="submit" disabled={loader} className="btn btn-primary BlugGradBtn font_18 font_weight_600" id="SignInBtn">Sign In</button>
                                            </div>
                                        </form>

                                        <div className="py-3 text-center">
                                            <div>
                                                <small className="font_14 font_weight_400 DarkBlue2_Color">Talent Scan &copy; All rights reserved.</small>
                                            </div>
                                            {/* <div>
                                                <Link  className="font_14 font_weight_400 DarkBlue2_Color  text-decoration-none">Privacy Policy</Link>
                                            </div> */}
                                        </div>

                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-4 col-12  LightGrey_BgColor">
                                        <div className="LoginBg d-flex justify-content-center align-items-center">
                                            <div className="d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none px-5">
                                                <img src={LoginBg} alt="LoginBg" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default LoginTest;