import React from 'react';
 
import {Link} from "react-router-dom";
import talentscanlogo from './../../Images/talent-scan-logo-final.png';
function TermsAndConditions() {
	 document.title = "Talent Scan | Terms-and-Conditions";
  	return (
		<>
			<div className='text-center'>
				<div className='topbar pt-4'>
					<Link to='/'>
					<div className="d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none px-5">
						<img src={talentscanlogo} alt="LoginBg" className="img-fluid" />
					</div>
					</Link>
				</div>
			</div>
			<div className="container mt-4" >
			<div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 icon-boxes d-flex flex-column align-items-stretch justify-content-center  " data-aos="fade-left">
              <h3 class="">Terms & Conditions Template for Talent Scan</h3>
              <p>By accessing Talent Scan, you agree to be bound by these Terms and Conditions. If you disagree with any part of the terms, do not use our Service.</p>
              <h4 class="privacy-head">Use of the Service</h4>
              <p>Talent Scan provides a platform for job recruitment testing. You agree to use the Service only for lawful purposes and in a way that does not infringe the rights of anyone else.</p>

              <h4 class="privacy-head">User Accounts</h4>
              <p>You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer.</p>

              <h4 class="privacy-head">Intellectual Property</h4>
              <p>The Service and its original content, features, and functionality are and will remain the exclusive property of Talent Scan and its licensors.</p>

              <h4 class="privacy-head">User Conduct</h4>
              <p>You agree not to use the Service for any purpose that is illegal or prohibited by these Terms.</p> 


              <h4 class="privacy-head">Disclaimer</h4>
              <p>Our Service is provided "as is". Talent Scan makes no warranties, may it be expressed or implied, therefore negates all other warranties.</p> 


              <h4 class="privacy-head">Limitation of Liability</h4>
              <p>In no event shall Talent Scan, nor its directors, employees, be liable for anything arising out of or in any way connected with your use of the Service.</p> 

              <h4 class="privacy-head">Amendments to Terms</h4>
              <p>We reserve the right to amend these Terms at any time. By continuing to access or use our Service after any revisions, you agree to be bound by the revised terms.</p> 

              <h4 class="privacy-head">Contact Us</h4>
              <p>For any questions about these Terms, please contact us at [enquiry@talentscan.com].</p> 

              <p>Thank you for trusting us with your information!</p>

              <p>Product has been developed by <strong>Pranathi Software Services Private Limited.</strong></p>
             
            </div>
            </div>
			</div>

		</>
	
  	);
}

export default TermsAndConditions;
