import React, { useState} from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./Components/Layout";
import SideNavigation from "./Components/SideNavigation";
import TopBar from "./Components/TopBar";

const  PrivateRouter1 =( {children, titleChild} ) => {
    const Navigate = useNavigate();
    const sideBar = useState(false);

    const getCookie = (emailId) => {
        let email = emailId + '=';
        let decodedCookie = decodeURIComponent(document.cookie);
        console.log("email--" + email)
        console.log("decoded Cookie is --" + decodedCookie)
    }
    return (
        <>
            <div id="Wrapper" className={sideBar ? "toggled" : ""}>
                {!getCookie ? 
                    <><Navigate to="/" /></> : 
                    <>
                        
                        {/* <> <TopHeader2 changesidebar={(data)=>changesidebar(data)} /></> */}
                        <Layout>
                        
                            <div className="d-flex">
                                <div className={sideBar ? "FullWidth" : "HalfWidth"} id="Sidebar">
                                    <SideNavigation />
                                </div>
                                <div className={sideBar ? "MainBodyHalfWidth" : "MainBodyFullWidth"} id="MainBodyContainer">
                                    <TopBar>
                                        {titleChild}
                                    </TopBar>
                                {children}
                                </div>
                            </div>
                        </Layout>
                    </>
                }
            </div>
        </>
    )
}

export default PrivateRouter1;