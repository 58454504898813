import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import React, {useEffect, useState} from "react";
import SignIn from './Pages/SignIn/SignIn';
import SignUp from './Pages/SignUp/SignUp';
import PrivateRouter from './PrivateRouter';
import PrivateRouter1 from './PrivateRouter1';
import Dashboard from './Pages/Dashboard';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import EnterCode from './Pages/SignIn/EnterCode';
import VerifyEmailId from './Pages/ChangePassword/VerifyEmailId';
import CodeForEmailVerification from './Pages/ChangePassword/CodeForEmailVerification';
import ForgotPassword from './Pages/ChangePassword/ForgotPassword';
import ChangedPasswordSuccess from './Pages/ChangePassword/ChangedPasswordSuccess';
import SignUpSuccess from './Pages/SignUp/SignUpSuccess';
import './App.css';
import DemoSite from './Pages/DemoSite/DemoSite';
import ProductDocumentationView from './Pages/DemoSite/ProductDocumentationView';
import ResumeParsingUploads from './Pages/DemoSite/ResumeParsingUploads';
import SignleResumeView from './Pages/DemoSite/SignleResumeView';
import BiometricScoring from './Pages/DemoSite/BiometricScoring';
import ScoreBoardView from './Pages/DemoSite/ScoreBoardView';
import QuestionsManager from './Pages/TestConfiguration/QuestionsManager';
import TestSets from './Pages/TestConfiguration/TestSets';
import TestAccess from './Pages/TestConfiguration/TestAccess';
import TestStartPage from './Pages/TestConfiguration/TestStartPage';
import GradingSummary from './Pages/TestConfiguration/GradingSummary';
import TimeSettings from './Pages/TestConfiguration/TimeSettings';
import CertificateTemplate from './Pages/TestConfiguration/CertificateTemplate';
import BasicSettings from './Pages/TestConfiguration/BasicSettings';
import LoginTest from './Pages/StartTest/LoginTest';
import StartTest from './Pages/StartTest/StartTest';
import Instructions from './Pages/StartTest/Instructions';
import LiveInterview from './Pages/StartTest/LiveInterview';
import QuestionType from './Pages/StartTest/QuestionType';
import QuestionTiming from './Pages/StartTest/QuestionTiming';
import AnswerTheQuestion from './Pages/StartTest/AnswerTheQuestion';
import CodingTypeOfQuection from './Pages/StartTest/CodingTypeOfQuection';
import CodingAnswerTheQuestion from './Pages/StartTest/CodingAnswerTheQuestion';
import RecordView from './Pages/StartTest/testVideo';
import StartTest2 from "./Pages/StartTest/StartTest2";
import Instructions2 from "./Pages/StartTest/Instructions2";
import SignleResumeView2 from "./Pages/DemoSite/SignleResumeView2";
import PrivateRouter2 from "./PrivateRouter2";
import DashboardTest from "./Dashboard/dashboardtest";
import Attemptedtestlist from "./Dashboard/attemptedtestlist";
import ChangeOldPasswordtoNew from "./Pages/ChangePassword/changeOldPassword";
import QuestionAnsResult from "./Dashboard/questionsans";
import Attemptedtestlist2 from "./Dashboard/attemptedtestlist2";
import TermsAndConditions from "./Pages/SignUp/TermsAndConditions";
import ErrorPage from "./Pages/StartTest/errorpage";
import AnswerVideoListShow from "./Dashboard/testvideoslist";
import Instructions3 from "./Pages/StartTest/Instructions3";
import ErrorPage2 from "./Pages/StartTest/errorpage2";

import 'react-toastify/dist/ReactToastify.css';

function App() {
  // const disableRightClick = (event) => {
  //   event.preventDefault();
  // };
  // useEffect(() => {
  //   // Attach the event listener when the component mounts
  //   document.addEventListener('contextmenu', disableRightClick);
  
  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener('contextmenu', disableRightClick);
  //   };
  // }, []); // The empty dependency array ensures that the effect runs only once on mount
  const [count, setCount] = useState(0)
//   document.addEventListener("visibilitychange", (event) => {
//     if (document.visibilityState == "visible") {
//       console.log("tab is active")
//     } else {
//       setCount(count + 1)
//       if(count == 2){
//        // alert("welcome to popup")
//       }
//       console.log("tab is inactive")
//     }
// });
 
  return (
    <>
      <div className='container-fluid px-0'>
        <Router>
          <Routes>
            <Route exact path="/" element={<SignIn />} />
            <Route exact path='/signup' element ={<SignUp />} />
            <Route exact path='/termsandconditions' element ={<TermsAndConditions />} />
            <Route exact path='/signupsuccess' element={<SignUpSuccess />} />
            <Route exact path='/verifyemailid' element={<VerifyEmailId />} />
            <Route exact path='/forgotpassword' element={<ForgotPassword />} />
            <Route exact path='/changepassword' element={<PrivateRouter><ChangeOldPasswordtoNew /></PrivateRouter>} />
            <Route exact path='/changedpasswordsuccess' element={<ChangedPasswordSuccess />} />
            <Route exact path='/codeforemailverification' element={<CodeForEmailVerification />} />
            <Route exact path ="/privacypolicy" element ={<PrivacyPolicy />} />
            <Route exact path ="/entercode" element ={<EnterCode />} />
            <Route exact path="/dashboard" element={ <PrivateRouter><Dashboard /></PrivateRouter>} />
            <Route exact path="/resumeparsing" element={ <PrivateRouter><DemoSite /></PrivateRouter> } />
            <Route exact path='/productdocumentationview' element={ <PrivateRouter><ProductDocumentationView /></PrivateRouter>} />
            <Route exact path="/resumeparsinguploads" element={ <PrivateRouter><ResumeParsingUploads /></PrivateRouter> } />
            <Route exact path="/signleresumeview" element={ <PrivateRouter><SignleResumeView /></PrivateRouter> } />
            <Route exact path="/signleresumeviewparse" element={ <PrivateRouter><SignleResumeView2 /></PrivateRouter> } />
            <Route exact path="/biometricscoring" element={ <PrivateRouter><BiometricScoring /></PrivateRouter> } />
            <Route exact path='/scoreboard' element={<PrivateRouter><ScoreBoardView /></PrivateRouter>} />
            <Route exact path='/testdashboard' element={<PrivateRouter><DashboardTest /></PrivateRouter>} />
            <Route exact path='/attemptedtestlist' element={<PrivateRouter><Attemptedtestlist /></PrivateRouter>} />
            <Route exact path='/attemptedtestlist2' element={<PrivateRouter><Attemptedtestlist2 /></PrivateRouter>} />
            <Route exact path='/questionresult' element={<PrivateRouter><QuestionAnsResult /></PrivateRouter>} />
            <Route exact path='/testvideos' element={ <AnswerVideoListShow />} />

            <Route exact path='/basicsettings' element={<PrivateRouter1><BasicSettings /></PrivateRouter1>} />
            <Route exact path='/questionsmanager' element={<PrivateRouter1><QuestionsManager /></PrivateRouter1>} />
            <Route exact path='/testsets' element={<PrivateRouter1><TestSets /> </PrivateRouter1>} />
            <Route exact path='/testaccess' element={<PrivateRouter1><TestAccess /></PrivateRouter1>} />
            <Route exact path='/teststartpage' element={<PrivateRouter1><TestStartPage /></PrivateRouter1>} />
            <Route exact path='/gradingsummary' element={<PrivateRouter1><GradingSummary /></PrivateRouter1>} />
            <Route exact path='/timesettings' element={<PrivateRouter1><TimeSettings /></PrivateRouter1>} />
            <Route exact path='/certificatetemplate' element={<PrivateRouter1><CertificateTemplate /></PrivateRouter1>} />

            <Route exact path='/logintest' element={<LoginTest />} />
            <Route exact path='/starttest' element={<PrivateRouter2><StartTest /></PrivateRouter2>} />
            <Route exact path='/errorbrowser' element={<PrivateRouter2><ErrorPage /></PrivateRouter2>} />
            <Route exact path='/errorcrossbrowser' element={<PrivateRouter2><ErrorPage2 /></PrivateRouter2>} />

            <Route exact path='/starttest2' element={<PrivateRouter2><StartTest2 /></PrivateRouter2>} />
            <Route exact path='/instructions' element={<PrivateRouter2><Instructions /></PrivateRouter2>} />
            <Route exact path='/instructions2' element={<PrivateRouter2><Instructions2 /></PrivateRouter2>} />
            <Route exact path='/submitedtest' element={<PrivateRouter2><Instructions3 /></PrivateRouter2>} />
            <Route exact path="/liveinterview" element={<PrivateRouter2><LiveInterview /></PrivateRouter2>} />
            <Route exact path='/questiontype' element={<PrivateRouter2><QuestionType /></PrivateRouter2>} />
            <Route exact path='/questiontiming' element={<PrivateRouter2><QuestionTiming /></PrivateRouter2>} />
            <Route exact path='/answerthequestion' element={<PrivateRouter2><AnswerTheQuestion /></PrivateRouter2>} />
            <Route exact path='/codingtypequestion' element={<PrivateRouter2><CodingTypeOfQuection /></PrivateRouter2>} />
            <Route exact path='/codinganswerthequestion' element={<PrivateRouter2><CodingAnswerTheQuestion /></PrivateRouter2>} />

            <Route exact path='/RecordView' element={<RecordView />} />
            
          </Routes>
        </Router>
      </div>
      
    </>
  );
}

export default App;
