import React, { useState,useEffect } from "react";
import Layout from "../../Components/Layout";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import ActionIconEye from './../../Images/ActionEyeIcon.png';
import ProgressIcon_1 from './../../Images/Icon_9.png';
import ProgressIcon_2 from './../../Images/Icon_10.png';
import ProgressIcon_3 from './../../Images/Icon_11.png';
import { CircularProgressbar, CircularProgressbarWithChildren} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SignleResumeView2 from "./SignleResumeView2";
import { useSelector, useDispatch } from 'react-redux';
import { BIOPARSINGERRS  } from '../../Redux/actions';
const ScoreBoardView = () => {
    const dispatch = useDispatch();
    const [CollectData, setCollectData] = useState();
    const biometricparseerror = useSelector((state) => state.biometricparseerror);
    const [perCircelBarState, setPerCircleBarState] = useState(33);
    const [skillsPerCirBarState, setSkillsPerCirBarState] = useState(33);
    const [certificatePerCirBarState, setCertificatePerCirBarState] = useState(33);
    
    const [loader, setLoader] = useState(true);
    const { register, handleSubmit, formState:{errors}, reset, setValue, setError } = useForm({});
    const { register:register1, handleSubmit:handleSubmit1, formState:{errors:errors1}, reset:reset1, setValue:setValue1,getValues:getValues1, setError:setError1 } = useForm({});
    const [isViewParseBtnDisabled, setIsViewParseBtnDisabled] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showparsedResumeData, setshowparsedResumeData] = useState(false);
    const [selectedIDTest, setselectedIDTest] = useState([]);
    const [selectedTestData, setselectedTestData] = useState([]);
    const [selectedskillData, setselectedskillData] = useState([]);
    const [testDesignation, settestDesignation] = useState("")
    const [search, setsearch] = useState("")
    const [testSkills, settestSkills] = useState([])
    const [linkGenShowModal, setLinkGenShowModal] = useState(false);
    const [linkGenShowModal2, setLinkGenShowModal2] = useState(false);
    const handleModalClose = () => {setShowModal(false); };
    const handleModalLinkGenClose = () => setLinkGenShowModal(false);
    const [sectionData, setSectionData] = useState([])
    const [errorselectdata, setErrorselectdata] = useState("")
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isCodeing, setisCodeing] = useState(true)
    const [Codeing, setCodeing] = useState("")
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    let obj = {};
    for(let pair of params.entries()) {
        obj[pair[0]] =pair[1];
    }
    
    const id = obj["id"];
    const handlePageChange = page => {

        GetBiometricScoringData( parseInt(page));
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page = perPage) => {
        GetBiometricScoringData(parseInt(page), newPerPage);
        setPerPage(newPerPage);
    };
    const changecondidateemail = (data) => {
        let  temparray = CollectData
        setIsValid(true)
        temparray.map((item,i)=>{
            if(item.id == data){
                setcandidate_email(item.candidate_email)
                setValue("candidate_email", item.candidate_email )
                item.editemail = true
            }else{
                item.editemail = false
            }
        })
        
        setCollectData([...temparray])
    }
     
    const [isValid, setIsValid] = useState(true);
 
    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };
    const saveCandidateemail = async (value, data) => {
        let email = validateEmail(value);
        if(email == false){
            setIsValid(false)
            return false
        }
        let req = {
            candidate_email: value
        }
        await axios.patch(`/apiV1/resume-score-list/${data.id}/`,req).then((res) => {
            if(res.data) {
                GetBiometricScoringData(1);
            }
        }).catch((e) => {
            setLoader(false)
            console.log(e)
        })
    }

    const [candidate_email, setcandidate_email] = useState()
    const columns = [
        {   name: <label className="DarkBlue4_Color font_weight_500 font_14">Talent Scan Score</label>, 
            selector:(row)=>row.score,  
            cell: row => (     <CircularProgressbar      value={`${row.score}`}       text={`${row.score}%`}       styles={{ path: { stroke: `#F3AFF9` }, }} />  ), },
        
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Name</label>, selector:(row)=>row.candidate_name, sortable: true,  cell: row => 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_14 align-middle align-middle">
                    <span>{row.candidate_name}</span>
                </div>
            </>,         
        },
        
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Email</label>, selector:(row)=>row.candidate_email, sortable: true,cell: row => 
            <>
                {!row.editemail ? 
                <div className="DarkBlue4_Color font_weight_300 font_14 align-middle align-middle">
                    <span>{row.candidate_email}</span>
                    <span onClick={()=>changecondidateemail(row.id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16"> <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/></svg>
                    </span>
                </div> : 
                
                <div className="input-group">
                    <input type="text" className="form-control"  {...register("candidate_email")} onChange={(e)=>{setcandidate_email(e.target.value);}}  />
                    <div className="input-group-append">
                        <button className="btn btn-outline-success paddingcustom" type="button" onClick={()=>saveCandidateemail(candidate_email, row)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-floppy" viewBox="0 0 16 16"><path d="M11 2H9v3h2z"/><path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/></svg>
                        </button>
                        <button className="btn btn-outline-danger paddingcustom" type="button" onClick={()=>changecondidateemail(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/></svg>
                        </button>
                    </div>
                    {!isValid && <p className="text-danger">Please enter a valid email address.</p>}
                </div>
                }
            </>,   
            width:"20%"      
        },
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Current/Last Position</label>, sortable: true,  selector:(row)=>row.current_last_positon, cell: row=> 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                    <span>{row.current_last_positon ? row.current_last_positon : ""}</span>
                </div>
            </>,
        },
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Experience</label>, sortable: true,  selector:(row)=>row.experience, cell: row=> 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_14 align-middle">
                    {row.experience  ? <span className="btn LightBlue2_BgColor text-white btn-sm font_12">{ row.experience}</span> : "" }
                    {/* <span className="btn LightBlue2_BgColor text-white btn-sm font_12">{row.experience  ? row.experience : 0 }</span> */}
                </div>
            </>
        },
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Industry</label>, selector:(row)=>row.industry, sortable: true,  cell: row => 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_14 align-middle align-middle">
                    <span>{row.industry ? row.industry : ""}</span>
                </div>
            </>,         
        },
        { name:<label className="DarkBlue4_Color font_weight_500 font_14">Education</label>, sortable: true, selector:(row)=>row.education,  cell: row=> 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_14 align-middle">
                    {row.education ?<span className="btn LightOrange_BgColor text-white btn-sm font_12"> {row.education} </span> : ""}
                    {/* <span className="btn LightOrange_BgColor text-white btn-sm font_12">{row.education ? row.education : "Education"}</span> */}
                </div>
            </>,
        },
        { name:<label className="DarkBlue4_Color font_weight_500 font_14">Doc ID</label>, 
            selector:(row)=>row.meta , cell: row => <> 
                <div className="DarkBlue4_Color font_weight_300 font_14 cursor-pointer"  role="button" onClick={()=>{setshowparsedResumeData(true); setselectedTestData(row.meta); setselectedskillData(row)}}>
                    
                        <img src={ActionIconEye} alt="ActionIconEye" className="ActionIcon ActionIconEye img-fluid" />
                     
                </div>
            </>,
        },
    ];
    
    const handleModalShow = () => {
        setisCodeing(true)
        setCodeing("")
        setlevelcri("")
        setValue("TestName", "")
        setValue("LevelsCriticality", "")
        setgenerating(false)
        setSectionData([])
        setErrorselectdata("")
        if(selectedIDTest.length === 0){
            setLinkGenShowModal2(true)
           // alert("Please select at least one resume")
            return false
        }

        let token = sessionStorage.getItem("Admintoken");
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
        }

         axios.get(`/apiV1/sections/`,config).then((res) => {
            // if(res.data == 200) {
                res.data.map((err, i)=>{
                    err.errorDuration = false;
                    err.errorTotalQuestions = false;
                    err.errorTotalScore = false;
                    err.errorQuaffingScore = false;
                    err.errorQuestionType = false;
                    err.errorLevel = false;

                    return err
                })
                setSectionData(res.data)
                
            //     console.log(res.data)
            // }
            console.log(res.data)
        })
        setShowModal(true)
    }

    const [generating, setgenerating] = useState(false)
    const convertToTimeFormat = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        const seconds = 0; // You can set seconds to 0 or adjust accordingly based on your requirements
        return `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };
    const SetTestSettingsAction = async (data) => {
        
        setErrorselectdata("")
        let testCat = [];
        var errorcount = 0;
        var sectionselection = 0
        
        sectionData.map((sec,i)=>{
            let testduration = convertToTimeFormat(sec.TestDuration)
            let tempObj ={
                "section": sec.id,
                "section_name": sec.name,
                "no_of_questions":sec.TestNoOfQuestions,
                "duration" :testduration,
                "total_score" :sec.TestTotalScore,
                "cutoff_score":sec.TestNoOfQuestions,
                "question_type": sec.TypeOfQuestions_type,
                "criticality" : data.LevelsCriticality,
            }
            if(sec.checked){
                sectionselection = sectionselection + 1
                if(!sec.TestDuration){
                    errorcount = errorcount + 1
                    sec.errorDuration = true;

                }
                if(!sec.TestNoOfQuestions){
                    errorcount = errorcount + 1
                    sec.errorTotalQuestions = true
                }
                if(!sec.TestTotalScore){
                    errorcount = errorcount + 1
                    sec.errorTotalScore = true
                }
                
                if(!sec.TypeOfQuestions_type){
                    errorcount = errorcount + 1
                    sec.errorQuestionType = true
                }
                 

                testCat.push(tempObj)
            }else{
                sec.errorDuration = false;
                sec.errorTotalQuestions = false;
                sec.errorTotalScore = false;
               
                sec.errorQuestionType = false;
                sec.errorLevel = false;

            }
            return sec
        })
        if(errorcount !== 0){
            setSectionData([...sectionData])
            return false
        }
        if(sectionselection === 0){
            
            setErrorselectdata("Please select at least one in above")
            return false
        }
        setgenerating(true)
        let req = {
            "test_name": data.TestName,
            "is_auto_generated" : true,
            "test_categories" : testCat,
            "assigned_candidates": selectedIDTest,
            "test_designation": testDesignation,
            "test_skills": testSkills
        }
        setIsViewParseBtnDisabled(true)
        const config = {
            headers: { Authorization: `Bearer ${sessionStorage.getItem("Admintoken")}`}
        }
         
        axios.post(`/apiV1/auto-generate-questions/`, req, config).then((res) => {
            if(res.data) {
                setgenerating(false)
                setShowModal(false)
                setLinkGenShowModal(true)
                setIsViewParseBtnDisabled(false)
                setSectionData([])
                reset();
            }
        }).catch((e) => {
            setgenerating(false)
            setIsViewParseBtnDisabled(false)
            if(e.response.data.message){
                setErrorselectdata(e.response.data.message)
            }
        })
    }

    const GetBiometricScoringData = async (page1, size = perPage, urls) => {
        setLoader(true)
        let token = sessionStorage.getItem("Admintoken");
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
        }
        let offcet = page1 -1 

        let url = `/apiV1/resume-score-list/${id}/?limit=${size}&offset=${offcet*size}${getValues1('searchValue') && `&search=${getValues1('searchValue')}`}`

        if(urls){
            url = urls
        }else{
            url =  `/apiV1/resume-score-list/${id}/?limit=${size}&offset=${offcet*size}${getValues1('searchValue') && `&search=${getValues1('searchValue')}`}`
        }

        axios.get(url,config).then((res) => {
            if(res.data) {
                setTotalRows(res.data.count)
                setLoader(false)
                setCollectData(res.data.results)
                setCurrentPage(page1)
                setPerPage(size)
            }
        }).catch((e) => {
            setLoader(false)
            console.log(e)
        })
    }

    const GetBiometricScoringData2 = async (value, type) => {
        let token = sessionStorage.getItem("Admintoken");
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
        }
        let titleScore = perCircelBarState
        let skillscore = skillsPerCirBarState
        let certificate = certificatePerCirBarState
        
        if(type == "title"){
            titleScore = parseInt(value)
        }
        if(type == "skills"){
            skillscore = parseInt(value)
        }
        if(type == "certificatecore"){
            certificate = parseInt(value)
        }
        
        let numArray = [
            {
                title:"-designation_score",
                value : titleScore,
            },
            {
                title:"-skills_score",
                value: skillscore,
            },
            {
                title:"-experience_score",
                value: certificate,
            },
        ];
        numArray = numArray.sort((a, b) => b.value - a.value);
        let orderedText = []
        numArray.map((filer,i)=>{
            orderedText.push(filer.title)
        })
        let order_by = orderedText.join(",")
        let offcet = currentPage -1
        axios.get(`/apiV1/resume-score-list/${id}/?order_by=${order_by}&limit=${perPage}&offset=${offcet*perPage}${getValues1('searchValue') && `&search=${getValues1('searchValue')}`}`,config).then((res) => {
            if(res.data) {
                setLoader(false)
                setCollectData(res.data.results)
            }
        }).catch((e) => {
            setLoader(false)
            console.log(e)
        })
    }

    const getJobTitleValue = async (value,data) => {
        // var lastNum = 0;
        let  jobtitle=  parseInt(value)
        let numArray = [jobtitle, skillsPerCirBarState, certificatePerCirBarState];
        let subtrack = (100 - jobtitle)/2
        setSkillsPerCirBarState(subtrack)
        setCertificatePerCirBarState(subtrack)
        numArray = numArray.sort((a, b) => b - a);
        // for (let k = 0; k < data.length; k++ ) {
        //     if(0 < value) {
        //         // console.log(data.results[k].score)
        //         console.log(value)
        //         numAscending = [...data].sort((a, b) => a.score - b.score);
        //         // lastNum = value;
        //     } else {
        //         // console.log(data.results[k].score)
        //         numAscending = [...data].sort((a, b) => b.score - a.score);
        //         console.log(value)
        //         // lastNum = 0;
        //     }
        // }   
        // console.log(numAscending)
       
        // setCollectData([...numAscending])


    }

    const getSkillValue = (value, data) => {
        let temp = value;
        let SkillAscending = [];

        let  jobtitle=  parseInt(value)
        let numArray = [perCircelBarState, jobtitle, certificatePerCirBarState];
        numArray = numArray.sort((a, b) => b - a);
       
        let subtrack = (100 - jobtitle)/2
        setPerCircleBarState(subtrack)
        setCertificatePerCirBarState(subtrack)
        for (let k = 0; k < data.length; k++ ) {
            if(temp < value) {
                // console.log(data.results[k].score)
                console.log(value)
                SkillAscending = [...data].sort((a, b) => a.skills_score - b.skills_score);
                // lastNum = value;
            } else {
                // console.log(data.results[k].score)
                SkillAscending = [...data].sort((a, b) => b.skills_score - a.skills_score);
                console.log(value)
                // lastNum = 0;
            }
        }   
       
        setCollectData([...SkillAscending])
    }

    const getCertificateValue = (value, data) =>{
        let CertificateAscending = [];
        let  jobtitle=  parseInt(value)
        let subtrack = (100 - jobtitle)/2
        setPerCircleBarState(subtrack)
        setSkillsPerCirBarState(subtrack)
        for (let k = 0; k < data.length; k++ ) {
            if(0 < value) {
                // console.log(data.results[k].score)
                console.log(value)
                CertificateAscending = [...data].sort((a, b) => a.certification_score - b.certification_score);
                // lastNum = value;
            } else {
                // console.log(data.results[k].score)
                CertificateAscending = [...data].sort((a, b) => b.certification_score - a.certification_score);
                console.log(value)
                // lastNum = 0;
            }
        }   
         
        setCollectData([...CertificateAscending])
    }

    // const getOverAllValue = (value, data) => {
    //     let OverallAscending = [];
    //     let  jobtitle=  parseInt(value)
    //     let numArray = [perCircelBarState, skillsPerCirBarState, certificatePerCirBarState, jobtitle];
    //     numArray = numArray.sort((a, b) => b - a);
    //     for (let k = 0; k < data.length; k++ ) {
    //         if(0 < value) {
    //             // console.log(data.results[k].score)
    //             console.log(value)
    //             OverallAscending = [...data].sort((a, b) => a.entire_score - b.entire_score);
    //             // lastNum = value;
    //         } else {
    //             // console.log(data.results[k].score)
    //             OverallAscending = [...data].sort((a, b) => b.entire_score - a.entire_score);
    //             console.log(value)
    //             // lastNum = 0;
    //         }
    //     }   
         
    //     setCollectData([...OverallAscending])
    // }

    const ChangeCircleBarStateCall = (value) => {
        setPerCircleBarState( parseInt(value))
        getJobTitleValue(value,CollectData)
         
        
    }

    const ChangeCircleBarSkillStateCall = (value) => {
        setSkillsPerCirBarState(parseInt(value))
         getSkillValue(value,CollectData)
        
    }

    const ChangeCircleBarCertifStateCall = (value) => {
        setCertificatePerCirBarState(parseInt(value));
         getCertificateValue(value,CollectData)
         
    }

    // const ChangeCircleBarOverAllStateCall = (value) => {
       
    //      getOverAllValue(value,CollectData)
         
    // }

    useEffect(() => {
        GetBiometricScoringData(1);
    },[id])

    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };

    
    const handleChangeCheckBox = (e) => {
        let BioInsIds = [];
        let Emails = [];
        let EmailandPhone = [];
        let testSkills = []
        let Designation = ""
        e.selectedRows.map((rows,i) => {

            BioInsIds.push(rows.id);
            testSkills = rows.jd_skills
            Designation = rows.jd_designation
            Emails.push(rows.candidate_email);
            EmailandPhone.push({
                email: rows.candidate_email,
                phone: rows.candidate_phone,
                name: rows.candidate_name
            });
            return rows;
        })
        settestSkills([...testSkills])
        settestDesignation(Designation)
        setselectedIDTest([...BioInsIds]);
    }
    const settesttotalScore = (value, index) => {
        sectionData.map((item, i)=>{
            if(i == index){
                item.TestNoOfQuestions = value
                item.TestTotalScore = value * 2
            }
        })
        setSectionData([...sectionData])
    }
    const [levelcri, setlevelcri] = useState("")
    const changeselectionValue = (value, id, index, name) => {
        sectionData.map((item, i)=>{
            if(i == index && item.id == id && name == "duration"){
                item.TestDuration = value
            }
            if(i == index && item.id == id && name == "questions"){
                if(20 >= value){
                    item.TestNoOfQuestions = value
                    item.TestTotalScore = value * 2
                } else{
                    item.TestNoOfQuestions = 20
                    item.TestTotalScore = 20 * 2
                    alert('Your not allow more than 20 questions')
                }
               
            }
            if(i == index && item.id == id && name == "qualify"){
                if(value >  item.TestTotalScore ){
                    alert('please enter valid score')
                }else{
                    item.TestQuaffingScore = value
                }
                
            }
            if(i == index && item.id == id && name == "type"){
                item.TypeOfQuestions_type = value
            }
 
        })
        setSectionData([...sectionData])
    }
    const setLevelCriticalityvalue = (value) => {
        setlevelcri(value)
        let tempdata = sectionData
        tempdata.map((item, i)=>{
            if(item.name=="Profiling"){
                item.checked = true
                item.TestDuration = 9
                item.TestNoOfQuestions = 3
                item.TestTotalScore = 3 * 2
                item.TestQuaffingScore = 3
                item.TypeOfQuestions_type = "DESC"
            }
            if(item.name=="Behavioural"){
                item.checked = true
                item.TestDuration = 12
                
                item.TestNoOfQuestions = 4
                item.TestTotalScore = 4 * 2
                item.TestQuaffingScore = 4
                item.TypeOfQuestions_type = "DESC"
            }
            if(item.name=="Technical"){
                item.checked = true
                item.TestDuration = 18
                item.TestNoOfQuestions = 6
                item.TestTotalScore = 6 * 2
                item.TestQuaffingScore = 6
                item.TypeOfQuestions_type = "DESC"
            }
            if(item.name=="English"){
                item.checked = true
                item.TestDuration = 3
                item.TestNoOfQuestions = 3
                item.TestTotalScore = 3 * 2
                item.TestQuaffingScore = 3
                item.TypeOfQuestions_type = "MCQ"
            }
            if(item.name=="Coding" && Codeing == "Yes"){
                item.checked = true
                item.TestDuration = 40
                item.TestNoOfQuestions = 4
                item.TestTotalScore = 4 * 2
                item.TestQuaffingScore = 4
                item.TypeOfQuestions_type = "DESC"
            }
        })
        setSectionData([...tempdata])
    }
    const changeselectedcheck = (e, index) => {

        let data = sectionData
        
        data.map((item, i)=>{
            if(i === index){
                item.checked = e.target.checked
            }
        })

        setSectionData([...data])
         
    }
    const onchangecodingtofalse = (value) => {
        let data = sectionData
        data.map((item, i)=>{
            if(item.name == "Coding"){
                item.codinground = value
            }else{
                item.codinground = true
            }
            
        })
        setSectionData([...data])
    }
    const onSubmits = async (data) => {
        let offcet = currentPage -1
        let url = `/apiV1/resume-score-list/${id}/?limit=${perPage}&offset=${offcet*perPage}&search=${data.searchValue}`
        GetBiometricScoringData(currentPage, perPage, url)
    }
    const onSubmits2 = async (data) => {
        let offcet = currentPage -1
        let url = `/apiV1/resume-score-list/${id}/?limit=${perPage}&offset=${offcet*perPage}${data && `&search=${data}`}`
        GetBiometricScoringData(currentPage, perPage, url)
    }

    return (
        <>
            <Layout>
                {/* <div className="row mx-0">
                    <div className="col-12 py-5 px-0">
                        <div className="text-center">
                            <Link to="/resumeparsing" className="btn btn-primary font_18 font_weight_500 LightGrey3_BgColor DarkBlue3_Color MyBtnSize border-0">Resume Parsing</Link>
                            <Link to="/biometricscoring" className="btn btn-primary font_18 font_weight_500 DarkBlue3_BgColor MyBtnSize ms-3 border-0">Biometric Scoring</Link>
                        </div>
                    </div>
                </div> */}
                {!showparsedResumeData ? 
                <>
                <div className="row mx-0 mt-5">
                    <div className="col-12">
                        <p className="font_20 font_weight_300 text-center mt-0 text-muted">Adjust the importance of each Category here. This  will affect how  each match is Scored.</p>
                    </div>
                </div>
                <div className="row mx-0 pb-5">
                    <div className="col-xxl-8 col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12 mx-auto">
                        <div className="row">
                            <div className="col-xxl-4 col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-2">
                                <div className="CategoryCard">
                                    <div className="card">
                                        <div className="card-body">
                                            <form id="JobTitleProgressForm" className="JobTitleProgressForm">
                                                <div className="row">
                                                    <div className="col-sm-7 col-7">
                                                        <div className="d-flex flex-sm-row flex-column ParentContainerCircleBar">
                                                            <CircularProgressbarWithChildren value={perCircelBarState} className="ParentCircleBar" styles={{
                                                                path: {
                                                                    stroke: `#7695F8`,
                                                                },
                                                            }}>
                                                                <img style={{ width: 40, marginTop: -5 }} src={ProgressIcon_1} alt="Job Title Img" className="ProgressBarIconImage" />
                                                                <div className="font_16 font_weight_500" style={{ marginTop: -5 }}>
                                                                    <strong>{perCircelBarState}%</strong>
                                                                </div>
                                                            </CircularProgressbarWithChildren>
                                                            <div className="OverAllRangeInput">
                                                                <input type="range" value={perCircelBarState} className="" id="JobTitleRange" min="0" max="100" onChange={(event) => {ChangeCircleBarStateCall(event.target.value); GetBiometricScoringData2(event.target.value, "title")}} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5 col-5 d-flex  justify-content-center align-items-center">
                                                        <div className="">
                                                            <h5 className="CirProgressTitle font_14">Job Title</h5>
                                                            {/* <h5 className="CirProgressCount">500</h5> */}
                                                        </div>
                                                    </div>

                                                    {/* <div className="col-sm-7 col-12">
                                                    <CirProgressBar/>
                                                    
                                                    </div>   */}

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xxl-4 col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-2">
                                <div className="CategoryCard">
                                    <div className="card">
                                        <div className="card-body">
                                            <form id="JobTitleProgressForm" className="JobTitleProgressForm">
                                                <div className="row">
                                                    <div className="col-sm-7 col-7">
                                                        <div className="d-flex flex-sm-row flex-column ParentContainerCircleBar">
                                                            <CircularProgressbarWithChildren value={skillsPerCirBarState} className="ParentCircleBar" styles={{
                                                                path: {
                                                                    stroke: `#8CD88A`,
                                                                },
                                                            }}>
                                                                <img style={{ width: 40, marginTop: -5 }} src={ProgressIcon_2} alt="Skills Img" />
                                                                <div className="font_16 font_weight_500" style={{ marginTop: -5 }}>
                                                                    <strong>{skillsPerCirBarState}%</strong>
                                                                </div>
                                                            </CircularProgressbarWithChildren>
                                                            <div className="OverAllRangeInput">
                                                                <input type="range" value={skillsPerCirBarState} className="" id="SkillsRange" min="0" max="100" onChange={(event) => {ChangeCircleBarSkillStateCall(event.target.value); GetBiometricScoringData2(event.target.value, "skills")}} />
                                                            </div>
                                                        </div>
                                                        
                                                        {/* <CircularProgressbar value={skillsPerCirBarState} text={`${skillsPerCirBarState}%`} /> */}
                                                        
                                                    </div>
                                                    <div className="col-sm-5 col-5 d-flex  justify-content-center align-items-center">
                                                        <div className="">
                                                            <h5 className="CirProgressTitle font_14">Skills</h5>
                                                            {/* <h5 className="CirProgressCount">325</h5> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xxl-4 col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-2">
                                <div className="CategoryCard">
                                    <div className="card">
                                        <div className="card-body">
                                            <form id="CertificationsProgressForm" className="CertificationsProgressForm">
                                                <div className="row">
                                                    <div className="col-sm-7 col-7">
                                                        <div className="d-flex flex-sm-row flex-column ParentContainerCircleBar">
                                                            <CircularProgressbarWithChildren value={certificatePerCirBarState} className="ParentCircleBar" styles={{
                                                                path: {
                                                                    stroke: `#EFAF62`,
                                                                },
                                                            }}>
                                                                <img style={{ width: 40, marginTop: -5 }} src={ProgressIcon_3} alt="Certifications Img" />
                                                                <div className="font_16 font_weight_500" style={{ marginTop: -5 }}>
                                                                    <strong>{certificatePerCirBarState}%</strong>
                                                                </div>
                                                            </CircularProgressbarWithChildren>
                                                            {/* <CircularProgressbar value={certificatePerCirBarState} text={`${certificatePerCirBarState}%`} /> */}
                                                            <div className="OverAllRangeInput">
                                                                <input type="range" value={certificatePerCirBarState} className="" id="CertificationsRange" min="0" max="100" onChange={(event) =>{ ChangeCircleBarCertifStateCall(event.target.value); GetBiometricScoringData2(event.target.value, "certificate")}} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5 col-5 d-flex  justify-content-center align-items-center">
                                                        <div className="">
                                                            <h5 className="CirProgressTitle font_14">Experience </h5>
                                                            {/* <h5 className="CirProgressCount">175</h5> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-2">
                                <div className="CategoryCard">
                                    <div className="card">
                                        <div className="card-body">
                                            <form id="OverAllProgressForm" className="OverAllProgressForm">
                                                <div className="row">
                                                    <div className="col-sm-7 col-7">
                                                        <div className="d-flex flex-sm-row flex-column ParentContainerCircleBar">
                                                            <CircularProgressbarWithChildren value={overAllPerCirBarState} className="ParentCircleBar" styles={{
                                                                path: {
                                                                    stroke: `#A97FDE`,
                                                                },
                                                            }}>
                                                                <img style={{ width: 40, marginTop: -5 }} src={ProgressIcon_4} alt="Over All Img" />
                                                                <div className="font_16 font_weight_500" style={{ marginTop: -5 }}>
                                                                    <strong>{overAllPerCirBarState}%</strong>
                                                                </div>
                                                            </CircularProgressbarWithChildren>
                                                            <div className="OverAllRangeInput">
                                                                <input type="range" value={overAllPerCirBarState} className="" id="OverAllRange" min="0" max="100" onChange={(event) =>{ ChangeCircleBarOverAllStateCall(event.target.value); GetBiometricScoringData2(event.target.value, "overAll")}} />
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="col-sm-5 col-5 d-flex  justify-content-center align-items-center">
                                                        <div className="">
                                                            <h5 className="CirProgressTitle font_14">Over All</h5>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div id="ScoreBoardForm" className="ScoreBoardForm">
                    <div className="row LightGrey3_BgColor px-0 mx-0">
                        <div className="col-xxl-8 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 mx-auto">
                            <div className="py-5">
                                <div className="row">
                                    <div className="col-xxl-10 col-lg-9 col-md-9 col-sm-8">

                                        <ul>
                                            {biometricparseerror.map((item,i)=>{
                                                return (
                                                    <li className="text-danger">{item}</li>
                                                )
                                            })}
                                            
                                        </ul>
                                    </div>
                                    <div className="col-xxl-2 col-lg-3 col-md-3 col-sm-4">
                                        <div className="pb-3">
                                        <form className='AddNewDept_form d-inline-block pt-3 pb-3' id='AddNewDept_form' name='AddNewDept_form' onSubmit={handleSubmit1(onSubmits)}>
                                            <input type="text" className="form-control" placeholder="Search"  {...register1("searchValue")}  onKeyUp={(e) => { onSubmits2(e.target.value); }}/>
                                            <input type="submit" style={{display:"none"}}></input>
                                        </form>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                <div className="ResumeParsingBlock mb-3">
                                   
                                    <div className="card border-0">
                                        <div className="card-body">
                                            
                                            <div className="table-responsive ">
                                                {!loader ? 
                                                <DataTable 
                                                    columns={columns} 
                                                    data={(CollectData !== undefined ) && (CollectData.length > 0)? CollectData : [] } pagination
                                                    paginationComponentOptions={paginationComponentOptions}
                                                    selectableRows
                                                    onSelectedRowsChange={(e) => handleChangeCheckBox(e)}
                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationDefaultPage={currentPage}
                                                    onChangeRowsPerPage={handlePerRowsChange}  
                                                    onChangePage={handlePageChange}
                                                    paginationPerPage={perPage}

                                                />: <div className='text-center'>
                                                <div className='spinner-border ' role='status'>
                                                  <span className='sr-only'></span>
                                                </div>
                                              </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mb-4">
                            <button className="btn btn-primary font_18 font_weight_500 bg-white shadow DarkBlue3_Color MyBtnSize border-0 me-3" type="button" onClick={handleModalShow}>Generate</button>
                            {/* <button className="btn btn-primary font_18 font_weight_500 bg-white shadow DarkBlue3_Color MyBtnSize border-0 me-3">Generate</button> */}
                            {/* <Link className="btn btn-primary font_18 font_weight_500 bg-white shadow DarkBlue3_Color MyBtnSize border-0 me-3" to="/basicsettings">Generate</Link> */}
                            {/* <button className="btn btn-primary font_18 font_weight_500 bg-white shadow DarkBlue3_Color MyBtnSize border-0">Pre-Generate</button> */}
                        </div>
                        <div className="col-xxl-8 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 mx-auto">
                            <div className=" text-end mb-5">
                                <Link   to={`/biometricscoring`} className="btn btn-primary DarkBlue3_BgColor MyBtnSize font_weight_500 font_20" onClick={()=>dispatch(BIOPARSINGERRS([]))} >Back</Link>
                            </div>
                        </div>
                        
                    </div>
                </div>
                </> : 
                <SignleResumeView2 data={selectedTestData} backpage={(data)=>setshowparsedResumeData(!showparsedResumeData)} completedata={selectedskillData}/> }

                <>
                    <div className="modals">
                        <Modal size={isCodeing ? "md" : "xl"} show={showModal} onHide={handleModalClose} backdrop="static"  keyboard={false}>
                            <form id="SetTestSettingsData" className="SetTestSettingsData" onSubmit={handleSubmit(SetTestSettingsAction)}>
                                <Modal.Header>
                                    <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Generate Test</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                        {isCodeing ?  
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                    <h2>Do you want coding round ?</h2>
                                            </div>
                                            
                                        </div> : 
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label htmlFor="TestNameId DarkGrey7_Color font-weight_400 font_16">Test Name</label>
                                                    <input type="text" className="form-control" id="TestNameId" {...register("TestName", { required: "Please Enter Hr Test Name..!", })} />
                                                    {errors.TestName && (<small className='text-danger'> {errors.TestName.message} </small>)}
                                                </div>

                                                
                                            </div>
                                            <div className="col-6">
                                            <label htmlFor="TestNameId DarkGrey7_Color font-weight_400 font_16">Test Criticality</label>
                                                <select className="form-select" aria-label="Criticality select example" id="LevelCriticality" {...register("LevelsCriticality", { required: "Please select test criticality..!", })} onChange={(e)=>{setLevelCriticalityvalue(e.target.value); setError('LevelsCriticality', { type: 'custom', message: "" })}}  >
                                                    <option value="">Select Criticality</option>
                                                    <option value="easy">Beginner</option>
                                                    <option value="medium">Intermediate</option>
                                                    <option value="hard">Advanced </option>
                                                </select>
                                                {errors.LevelsCriticality && (<small className='text-danger'> {errors.LevelsCriticality.message} </small>)}
                                            </div>

                                            <div className="col-12">
                                                <div className="table-responsive">
                                                {levelcri ?
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th className="font_14 font_weight_500 DarkGrey7_Color">Round</th>
                                                                <th className="font_14 font_weight_500 DarkGrey7_Color">Duration</th>
                                                                <th className="font_14 font_weight_500 DarkGrey7_Color">No of Questions</th>
                                                                <th className="font_14 font_weight_500 DarkGrey7_Color">Total Score</th>
                                                                {/* <th className="font_14 font_weight_500 DarkGrey7_Color">Qualifying Score</th> */}
                                                                <th className="font_14 font_weight_500 DarkGrey7_Color">Question Type</th>
                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { 
                                                                // Object.values(sectionData).map((item,index) => {
                                                                //     console.log(index, item)
                                                                // })
                                                                sectionData.map((item,index) => {
                                                                    // console.log("My loop item.." +item)
                                                                    // console.log("My loop index...", index)
                                                                    return(
                                                                        item.codinground ? 
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="checkbox"  id={`SectionNameChecked${item.id}`}  onChange={(e)=>changeselectedcheck(e, index)} value={item.checked} checked={item.checked}   />
                                                                                    <label className="form-check-label font_14 font_weight_400" htmlFor={`SectionNameChecked${item.id}`}>{item.name}</label>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <input type="text" className="form-control" id="TestDuration" value={item.TestDuration} onChange={(e)=>  changeselectionValue(e.target.value, item.id, index, "duration")}  />
                                                                                {item.errorDuration ? <small className="text-danger">This field is required</small> :""}
                                                                            </td>
                                                                            
                                                                            <td>
                                                                                <input type="number" className="form-control" id="TestTotalScore" value={item.TestNoOfQuestions} maxLength={20} onChange={(e)=> { changeselectionValue(e.target.value, item.id, index, "questions")}} />
                                                                                {item.errorTotalQuestions ? <small className="text-danger">This field is required</small> :""}
                                                                            </td>
                                                                            <td>
                                                                                <input type="number" className="form-control" id="TestQuaffingScore" value={item.TestTotalScore} disabled={true}  />
                                                                                 
                                                                            </td>
                                                                            {/* <td>
                                                                                <input type="number" className="form-control" id="TestNoOfQuestions" value={item.TestQuaffingScore} onChange={(e)=>  changeselectionValue(e.target.value, item.id, index, "qualify")} />
                                                                                {item.errorQuaffingScore ? <small className="text-danger">This field is required</small> :""}
                                                                            </td> */}
                                                                            <td>
                                                                                <select disabled className="form-select" aria-label="Question Type select example" id="TypeOfQuestions_" value={item.TypeOfQuestions_type} onChange={(e)=>  changeselectionValue(e.target.value, item.id, index, "type")} >
                                                                                    <option value="">Question Type</option>

                                                                                    {item.name =="Profiling" ||  item.name =="English"  ? <option value="MCQ">MCQ</option> : ""}
                                                                                   
                                                                                    {item.name =="Profiling" ||  item.name =="Behavioural" || item.name =="Technical" || item.name =="Coding" ? <option value="DESC">DESC</option> : ""}
                                                                                </select>
                                                                                {item.errorQuestionType ? <small className="text-danger">This field is required</small> :""}
                                                                            </td>
                                                                            
                                                                        </tr>  : ""
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table> : ""}

                                                    <div className="text-danger">{errorselectdata}</div>
                                                   
                                                </div>
                                            </div>
                                        </div> }
                                        <div className="row">
                                            <div className="col-md-12">
                                            {generating ? <><div className='spinner-border ' role='status'>
                                                  <span className='sr-only'></span>
                                                </div><div className="  text-danger">Your questions are generating please wait ....</div></> : ""}
                                            </div>
                                            
                                        </div>
                                </Modal.Body>
                                <Modal.Footer className="mx-auto border-0">
                                    {isCodeing ?  
                                    <><Button variant="secondary"  type="button" className="DarkBlue5_BgColor text-white border-0" onClick={()=>{setCodeing("No"); onchangecodingtofalse(false);  setisCodeing(false);}}  >
                                        No
                                    </Button>
                                    <div role="button"   className="DarkBlue5_BgColor text-white border-0 btn btn-secondary" onClick={()=>{setCodeing("Yes"); onchangecodingtofalse(true);  setisCodeing(false);}} >
                                        Yes
                                    </div></> :

                                    <><Button variant="secondary" onClick={handleModalClose} className="DarkBlue5_BgColor text-white border-0"  disabled={isViewParseBtnDisabled}>
                                        Close
                                    </Button>
                                    <Button  type="submit" variant="primary" className="DarkBlue5_BgColor text-white border-0" disabled={isViewParseBtnDisabled}>
                                        Link
                                    </Button></> }
                                </Modal.Footer>
                            </form>
                        </Modal>

                        <Modal size="md" show={linkGenShowModal} onHide={handleModalLinkGenClose} backdrop="static"  keyboard={false}>
                            <Modal.Header>
                                <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Generate Test</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h4 className='text-center'>Link Generate Successfully</h4>
                                <div className='text-center m-3'>
                                    <button className='btn btn-primary DarkBlue3_BgColor font_20 font_weight_500' id="ScoreBoard_Assign_UpdateOkayBtn"
                                    onClick={ handleModalLinkGenClose }>   OK
                                    </button>
                                </div>
                            </Modal.Body>
                        </Modal>

                        <Modal size="md" show={linkGenShowModal2} onHide={()=>setLinkGenShowModal2(false)} backdrop="static"  keyboard={false}>
                            <Modal.Header>
                                <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Generate Test</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h4 className='text-center'>Please select at least one resume</h4>
                                <div className='text-center m-3'>
                                    <button className='btn btn-primary DarkBlue3_BgColor font_20 font_weight_500' id="ScoreBoard_Assign_UpdateOkayBtn"
                                    onClick={ ()=>setLinkGenShowModal2(false) }>   OK
                                    </button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </>
            </Layout>
        </>
    )
}

export default ScoreBoardView;