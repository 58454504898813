import React from "react";
import Layout from "../../Components/Layout";
import LoginBg from './../../Images/LoginBg.webp';
import { Link } from "react-router-dom";

const EnterCode = () => {
    return (
        <>
            <Layout>
                <div className="row mx-0">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-sm-12 col-12 mx-auto pt-5">
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="row shadow rounded-1">
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-4 col-12  LightGrey_BgColor">
                                        <div className="LoginBg d-flex justify-content-center align-items-center">
                                            <div className="d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none px-5">
                                                <img src={LoginBg} alt="LoginBg" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-8 col-12 px-5 pt-4">
                                        <div className="text-center pt-5 pb-4 mt-5">
                                            <h2 className="font_40 font_weight_800 LineHeight29 mb-0 pt-5"><span className="DarkBlue_Color">Talent </span> <span className="LightGreen_Color">SCAN</span></h2>
                                        </div>
                                        <div className="text-center">
                                            <h3 className="font_24 font_weight_600 DarkBlue_Color">Enter Code</h3>
                                        </div>
                                        <div className="text-center pt-3">
                                            <p className="font_18 font_weight_400 DarkBlue2_Color">A code has been emailed to you. Please enter it below to Complete Sign-in</p>
                                        </div>
                                        <form id="LoginCodeEnterForm" className="LoginCodeEnterForm" method="post">
                                            <div className="FormGroup">
                                                <label htmlFor="LoginEnterCodeInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >Enter your Code <small className="text-danger">*</small></label>
                                                <input type="text" className="form-control FormInput" id="LoginEnterCodeInput" />
                                                <small id="LoginEnterCodeInputErr" className="font_12 text-danger invisible">Code Error</small>
                                            </div>

                                            <div className="FormGroup">
                                                <button type="button" id="ResendCode" className="btn btn-sm btn-transparent DarkBlue2_Color font_14 font_weight_400 px-0">Check here to resend the code.</button>
                                            </div>

                                            <div className="BtnGroup d-grid LineHeight22 ">
                                                <Link to='/dashboard' className="btn btn-primary BlugGradBtn font_18 font_weight_600" id="VerifyCodeEmailIdBtn">Continue</Link>
                                                {/* <button type="button" className="btn btn-primary BlugGradBtn font_18 font_weight_600" id="VerifyCodeEmailIdBtn" onclick="VerifyCodeEmailIdCall()">Continue</button> */}
                                            </div>

                                            <div className="BtnGroup text-center pt-3 font_18">
                                                <label className="DarkBlue2_Color">Back to  </label> 
                                                <Link to="/" className="DarkBlue2_Color font_weight_500 font_18 text-decoration-none"> Sign In</Link>
                                            </div>
                                            <div className="py-5 my-3"></div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default EnterCode;