import React, { useState, useEffect } from "react";
import Layout from "../../Components/Layout";
import LoginBg from './../../Images/LoginBg.webp';
import { Link, useNavigate } from "react-router-dom";


import { useForm } from "react-hook-form";
import axios from "axios";

const ChangeOldPasswordtoNew = () => {
    const navigate = useNavigate();
    const [signUpValidationErrors, setSignUpValidationErrors] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successfullmessage, setsuccessfullmessage] = useState('')

    const {register, handleSubmit, formState : {errors}, trigger, setValue, getValues, reset} = useForm({});

    const changePasswordAction = (data) => {
        setIsSubmitting(true)
        if(data.ChangePassword != data.ChangeConfirmPassword){
            seterrorforPasswordmatch("Password & Confirm Password does not match ")
            setIsSubmitting(false)
            return false
        }
        let userinfo = JSON.parse(sessionStorage.getItem("user-info"))
        setSignUpValidationErrors("")
        let req = {
            old_password:data.oldpassword,
            password: data.ChangePassword,
            password2: data.ChangeConfirmPassword
        }
        let token = sessionStorage.getItem('Admintoken');
        const config = {
            headers: { Authorization: `Bearer ${token}`}
        }
        axios.patch(`apiV1/change-password/${userinfo.id}/`, req, config).then(
            (rep) => {
                setIsSubmitting(false)
                //setsuccessfullmessage('Password changed successfully')
                sessionStorage.setItem('Admintoken', "");
                reset()
                navigate("/");
                
            }
        ).catch((e) => {
            setIsSubmitting(false)
            if(e.response.data.old_password) {
                setSignUpValidationErrors(e.response.data.old_password.old_password)
            }
            if(e.response.data.password) {
                setSignUpValidationErrors(e.response.data.password[0])
            }
        })
    }
    const [errorforPasswordmatch, seterrorforPasswordmatch] = useState("")
    const checkpasswordtwo = (value) => {
       let pasword =  getValues("ChangePassword");
        if(value.length >= pasword.length - 1){
            if(getValues("ChangePassword") == value){
                seterrorforPasswordmatch("")
                
            }else{
                seterrorforPasswordmatch("Password & Confirm Password does not match ")
             
            }
        }
    }
    return (
        <>
            <Layout>
                <div className="row mx-0">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-sm-12 col-12 mx-auto pt-5">
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="row  ">
                                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12"></div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-8 col-12 px-5 pt-4">
                                        {/* <div className="text-center pt-5 pb-4 mt-5">
                                            <h2 className="font_40 font_weight_800 LineHeight29 mb-0 pt-5"><span className="DarkBlue_Color">Talent </span> <span className="LightGreen_Color">SCAN</span></h2>
                                        </div> */}
                                        {/* <div className="text-center">
                                            <h3 className="font_24 font_weight_600 DarkBlue_Color">Code for Email Verification</h3>
                                        </div> */}
                                        {/* <div className="text-center pt-3">
                                            <p className="font_18 font_weight_400 DarkBlue2_Color">Please enter Generated OTP in below</p>
                                        </div> */}
                                        <form id="ForgotPasswordValidateForm" className="ForgotPasswordValidateForm" onSubmit={handleSubmit(changePasswordAction)}>
                                            <div className="FormGroup">
                                                <label htmlFor="LoginForgotPasswordInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >Enter Old Password <small className="text-danger">*</small></label>
                                                <input type="password" className="form-control FormInput" id="LoginForgotPasswordInput" name="password" placeholder="Password" 
                                                {...register("oldpassword", {
                                                    required: "This field is required",
                                                    pattern: {
                                                      value:
                                                        /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                      //  (?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])at least one special chareacter
                                                      message:
                                                        "Password should contain at least one digit & one lower case & one upper case & length will be minimum 8 characters ",
                                                    },
                                                  })}
                                                  onKeyUp={() => {
                                                    trigger("oldpassword");
                                                  }}
                                                />
                                                {errors.oldpassword && (
                                                    <small className='text-danger font-bold'>
                                                        {errors.oldpassword.message}
                                                    </small>
                                                )}
                                                {/* <small id="LoginForgotPasswordInputErr" className="font_12 text-danger invisible">Password Error</small> */}
                                            </div>
                                            <div className="FormGroup">
                                                <label htmlFor="LoginForgotPasswordInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >Enter Password <small className="text-danger">*</small></label>
                                                <input type="password" className="form-control FormInput" id="LoginForgotPasswordInput" name="password" placeholder="Password" 
                                                {...register("ChangePassword", {
                                                    required: "This field is required",
                                                    pattern: {
                                                      value:
                                                        /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                      //  (?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])at least one special chareacter
                                                      message:
                                                        "Password should contain at least one digit & one lower case & one upper case & length will be minimum 8 characters ",
                                                    },
                                                  })}
                                                  onKeyUp={() => {
                                                    trigger("ChangePassword");
                                                  }}
                                                />
                                                {errors.ChangePassword && (
                                                    <small className='text-danger font-bold'>
                                                        {errors.ChangePassword.message}
                                                    </small>
                                                )}
                                                {/* <small id="LoginForgotPasswordInputErr" className="font_12 text-danger invisible">Password Error</small> */}
                                            </div>

                                            <div className="FormGroup mb-2">
                                                <label htmlFor="LoginForgotConfirmPasswordInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >Enter Confirm Password <small className="text-danger">*</small></label>
                                                <input type="password" className="form-control FormInput" id="LoginForgotConfirmPasswordInput" name="password" placeholder="Password" 
                                                {...register("ChangeConfirmPassword", {
                                                    required: "This field is required",
                                                    pattern: {
                                                      value:
                                                        /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                      //  (?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])at least one special chareacter
                                                      message:
                                                        "Password should contain at least one digit & one lower case & one upper case & length will be minimum 8 characters ",
                                                    },
                                                  })}
                                                  onKeyUp={(e) => {
                                                    trigger("ChangeConfirmPassword");
                                                    checkpasswordtwo(e.target.value)
                                                  }}
                                                />
                                                {errors.ChangeConfirmPassword && (
                                                    <small className='text-danger font-bold'>
                                                        {errors.ChangeConfirmPassword.message}
                                                    </small>
                                                )}
                                                <small className="font_12 text-danger">{signUpValidationErrors}</small>
                                                <small id="SignUpConfirmPasswordInputErr" className="font_12 text-danger">{errorforPasswordmatch}</small>
                                                <small id="SignUpConfirmPasswordInputErr" className="font_12 text-success">{successfullmessage}</small>
                                            </div>
                                            {!isSubmitting ? (
                                                ""
                                                ) : (
                                                <div className='text-center'>
                                                    <div className='spinner-border ' role='status'>
                                                    <span className='sr-only'></span>
                                                    </div>
                                                </div>
                                                )}
                                            <div className="BtnGroup d-grid LineHeight22 ">
                                                {/* <Link to="/changedpasswordsuccess"  className="btn btn-primary BlugGradBtn font_18 font_weight_600" id="ChangePasswordBtn"> Change Password</Link> */}
                                                <button type="submit" className="btn btn-primary BlugGradBtn font_18 font_weight_600" disabled={isSubmitting} id="ChangePasswordBtn"  >Change Password</button>
                                            </div>

                                            {/* <div className="BtnGroup text-center pt-3 font_18">
                                                <label className="DarkBlue2_Color">Back to  </label> 
                                                <Link to="/" className="DarkBlue2_Color font_weight_500 text-decoration-none"> Sign In</Link>
                                            </div> */}
                                            <div className="py-5 my-3"></div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ChangeOldPasswordtoNew;