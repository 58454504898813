import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import { FaCog, FaQuestionCircle, FaListOl, FaLock, FaFileSignature, FaRegClone, FaClock, FaCertificate } from "react-icons/fa";
import { path } from "d3";

const SideNavigation = () => {

    const location = useLocation();

    const { pathname } = location;

    const splitLocation = pathname.split("/")
    return (
        <>
            <aside id="SidebarWrapper">
                <div className="SidebarLogo text-center py-5">
                    <Link to="/" className="navbar-brand">
                        <h2 className="font_40 font_weight_800 LineHeight29 mb-0"><span className="text-light">Talent </span> <span className="LightGreen_Color">SCAN</span></h2>
                    </Link>
                </div>
                <ul className="list-group SideNavBar DarkBlue5_BgColor">
                    <li className="list-group-item SideNavItem">
                        <NavLink to="/basicsettings" className={ splitLocation[1] === "" ? "SideNavLink align-middle active": "SideNavLink align-middle"} ><span className="align-middle font_18"><FaCog /></span> <span className="align-middle font_20">Basic Settings</span></NavLink>
                    </li>

                    <li className="list-group-item SideNavItem">
                        <NavLink to="/questionsmanager"  className={ splitLocation[1] === "" ? "SideNavLink align-middle active": "SideNavLink align-middle"} ><span className="align-middle font_18"><FaQuestionCircle /></span> <span className="align-middle font_20">Questions Manager</span></NavLink>
                    </li>

                    <li className="list-group-item SideNavItem">
                        <NavLink to="/testsets"  className={ splitLocation[1] === "" ? "SideNavLink align-middle active": "SideNavLink align-middle"} ><span className="align-middle font_18"><FaListOl /></span> <span className="align-middle font_20">Test Sets</span></NavLink>
                    </li>

                    <li className="list-group-item SideNavItem">
                        <NavLink to="/testaccess"  className={ splitLocation[1] === "" ? "SideNavLink align-middle active": "SideNavLink align-middle"} ><span className="align-middle font_18"><FaLock /></span> <span className="align-middle font_20">Test Access</span></NavLink>
                    </li>

                    <li className="list-group-item SideNavItem">
                        <NavLink to="/teststartpage"  className={ splitLocation[1] === "" ? "SideNavLink align-middle active": "SideNavLink align-middle"} ><span className="align-middle font_18"><FaFileSignature /></span> <span className="align-middle font_20">Test Start Page</span></NavLink>
                    </li>

                    <li className="list-group-item SideNavItem">
                        <NavLink to="/gradingsummary"  className={ splitLocation[1] === "" ? "SideNavLink align-middle active": "SideNavLink align-middle"} ><span className="align-middle font_18"><FaRegClone /></span> <span className="align-middle font_20">Grading & Summary</span></NavLink>
                    </li>

                    <li className="list-group-item SideNavItem">
                        <NavLink to="/timesettings"  className={ splitLocation[1] === "" ? "SideNavLink align-middle active": "SideNavLink align-middle"} ><span className="align-middle font_18"><FaClock /></span> <span className="align-middle font_20">Time Settings</span></NavLink>
                    </li>

                    <li className="list-group-item SideNavItem">
                        <NavLink to="/certificatetemplate"  className={ splitLocation[1] === "" ? "SideNavLink align-middle active": "SideNavLink align-middle"} ><span className="align-middle font_18"><FaCertificate /></span> <span className="align-middle font_20">Certificate Template</span></NavLink>
                    </li>
                </ul>
            </aside>
        </>
    )
}

export default SideNavigation;