import React from "react";
import Layout from "../../Components/Layout";
import successright from './../../Images/successright.png';
import talentscanlogo from './../../Images/talent-scan-logo-final-white.png';
const Instructions3 = () => {
    sessionStorage.setItem("tabCount", 0)
    return (
        <>
            <Layout>
                <div className="row mx-0">
                    <div className="col-12 my-2">
                        <div className="rounded-5 DarkBlue3_BgColor py-3">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <img src={talentscanlogo} alt="logo"/>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-md-end text-sm-end text-start">
                                    <h2 className="font_40 font_weight_800 LineHeight29 mb-0 py-3 pe-5"><span className="text-light">Instru</span><span className="LightGreen_Color">ctions</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <>
                        <div className="col-12 my-5 pt-5 text-center">
                            <div className="mb-5">
                                <img src={successright} alt="success" width={209} height={192} />
                            </div>
                            <h2>Response Submitted Successfully <br/>If Shortlisted you may be Contacted from HR Team!</h2>
                        </div>
                    </>
                </div>
            </Layout>
        </>
    )
}

export default Instructions3;