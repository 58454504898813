import React, { useState, useEffect } from "react";
import Layout from "../../Components/Layout";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";

import Accordion_Icon_1 from './../../Images/Icon_1.png';
import Accordion_Icon_2 from './../../Images/Icon_2.png';
import Accordion_Icon_3 from './../../Images/Icon_3.png';
import Accordion_Icon_4 from './../../Images/Icon_4.png';
import Accordion_Icon_5 from './../../Images/Icon_5.png';
import Accordion_Icon_6 from './../../Images/Icon_6.png';
import Accordion_Icon_7 from './../../Images/Icon_7.png';
import Accordion_Icon_8 from './../../Images/Icon_8.png';

import PropTypes from "prop-types";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Typography } from "@mui/material";
// import OverView from "./OverView";
// import TextView from "./TextView";
// import HtmlView from "./HtmlView";
// import TechnicalApiDetailsView from "./TechnicalApiDetailsView";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { RESUMEPARSINGERRS  } from '../../Redux/actions';
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
     
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}


const SignleResumeView = ({data}) => {
    const resumeparsingerror = useSelector((state) => state.resumeparsingerror);
    // console.log(`my data might be ${data}`)
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const fromDb = undefined;
    const [validationErrors, setValidationErrors] = useState({});
    const [uploadFileerror, setUploadFileerror] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [parseHistoryData, setParseHistoryData] = useState([]);
    const [parseHistoryDataEmpHistory, setParseHistoryDataEmpHistory] = useState([]);
    const [parseHistoryDataEduHistory, setParseHistoryDataEduHistory] = useState([]);
    const [nextUrl, setNextUrl] = useState("")
    const [PrevUrl, setPrevUrl] = useState("")
    const [value, setValue] = useState(0);

    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    let obj = {};
    for (let pair of params.entries()) {
        obj[pair[0]] = pair[1]
    }
    const [id, setId] = useState(obj["id"] ? obj["id"] : 0)
    // console.log(`Id is here + ${obj['id']}`)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const GetResumeData = async (next) => {

        let token = sessionStorage.getItem('Admintoken');
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
        }
        // console.log("data is loading")
        let url = `apiV1/upload-detail/${id}/`
        if(next){
            url = next
        }
        axios.get(url,config).then((res) => {
            const EmpHistResult = [];
            const EduHistResult = [];
            if(res && res.data) {
                if(res.data.next){
                    setNextUrl(res.data.next)
                }else{
                    setNextUrl("")
                }
                
                if(res.data.previous){
                    setPrevUrl(res.data.previous)
                }
                else{
                    setPrevUrl("")
                }
                
                console.log('sdfsdf')
                setParseHistoryData(res.data.results[0])
                if(res.data.results[0].employment_history){
                    for (const [key, value] of Object.entries(res.data.results[0].employment_history)) {
                        EmpHistResult.push(value)
                    }
                }
                if(res.data.results[0].education_history){
                    for (const [key, value] of Object.entries(res.data.results[0].education_history)) {
                        EduHistResult.push(value)
                       
                    }
                }
                
                // if(res.data.results[0].employment_history.length !== 0){
                //     res.data.results[0].employment_history.map((item, i) => {
                //         EmpHistResult.push(item)
                //     })
                    
                // }
                setTimeout(() => {
                    
                    setParseHistoryDataEmpHistory([...EmpHistResult])
                    setParseHistoryDataEduHistory([...EduHistResult])
                }, 1000);
                
                // if(res.data.results[0].education_history.length !== 0) {
                //     res.data.results[0].education_history.map((item, i) => {
                //         EduHistResult.push(item)
                //     })
                    
                //     // setParseHistoryDataEduHistory(res.data.results[0].education_history[0])
                //     console.log(EduHistResult)
                // }
                
                console.log("my axios get accessed2")
            } 
                // console.log(res.data.results[0].employment_history[0].employer_name,"hdbvbyfvbfbvfdbb")
                // console.log(parseHistoryData.employment_history[0].id)
            }
        ).catch((e) => {
            setIsSubmitting(false);
            // if (e.response.data.errors !== undefined) {
            //     setValidationErrors(e.response.data.errors);
            //     console.log("errors response");
            // }
            // if (e.response.data.error !== undefined) {
            //     setValidationErrors(e.response.data.error);
            //     console.log("error response");
            // }
            // if(e.response.data.detail){
            //     setUploadFileerror(e.response.data.detail)
            //     console.log("error details");
            // }
        });
    }
    const nextResumetoFetch = () => {
        GetResumeData(nextUrl);
    }
    const PrevResumetoFetch = () => {
        GetResumeData(PrevUrl);
    }
    useEffect(() => {
        GetResumeData();
    },[data])
    return (
        <>
            <Layout>
                  

                <div className="row LightGrey3_BgColor py-5 mx-0">
                    <div className="col-12">
                        <Box sx={{ width: '100%' }}>
                            <Box className="text-center">
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" id="SingleResumeViewTabs">
                                    <Tab label="Over View" {...a11yProps(0)} id="OverViewTab" className="rounded-pill me-3" />
                                    {/* <Tab label="Text" {...a11yProps(1)} id="TextTab" className="rounded-pill me-3" />
                                    <Tab label="HTML" {...a11yProps(2)} id="HTMLTab" className="rounded-pill me-3" />
                                    <Tab label="Technical API Details" {...a11yProps(3)} id="TechnicalAPIDetailsTab" className="rounded-pill" /> */}
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                {/* <ResumeParsing /> */}
                                <>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="FormGroup py-4">
                                                <h2 className="font_24 font_weight_500 DarkBlue4_Color text-center">{((parseHistoryData.first_name) == null ? " " : parseHistoryData.first_name) +" " + ((parseHistoryData.middle_name) == null ? " " : parseHistoryData.middle_name) +" " + ((parseHistoryData.last_name) == null ? " " : parseHistoryData.last_name)}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-10 col-sm-12 col-12 mx-auto">
                                        <ul>
                                            {resumeparsingerror.map((item,i)=>{
                                                return (
                                                    <li className="text-danger">{item}</li>
                                                )
                                            })}
                                            
                                        </ul>
                                            <div className="accordion" id="OverViewSignleViewAccordion">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="CandidatenalysisAccordion">
                                                        <button className="accordion-button DarkBlue4_Color font_weight_400 font_20" type="button" data-bs-toggle="collapse" data-bs-target="#CollapseOverViewAccordion" aria-expanded="true" aria-controls="CollapseOverViewAccordion">
                                                            <img src={Accordion_Icon_1} alt="Icon 1" className="Accordion_Icon me-3" />
                                                            Talent Scan Candidate Analysis
                                                        </button>
                                                    </h2>
                                                    <div id="CollapseOverViewAccordion" className="accordion-collapse collapse show" aria-labelledby="CandidatenalysisAccordion"  >
                                                        <div className="accordion-body">
                                                            {/* <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Id</label>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="col-sm-8 col-12">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.id}</label>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">First Name</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="  col-6 col-xl-8">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{ parseHistoryData.first_name  ? parseHistoryData.first_name : " " }</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {parseHistoryData.middle_name ? 
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Middle Name</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className=" col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.middle_name  ?  parseHistoryData.middle_name : " " }</label>
                                                                    </div>
                                                                </div>
                                                            </div> : ""}
                                                            {parseHistoryData.last_name ?
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Last Name</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className=" col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.last_name ?  parseHistoryData.last_name : ""}</label>
                                                                    </div>
                                                                </div>
                                                            </div> : ""}
                                                            {parseHistoryData.city ? 
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">City</label>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className=" col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.city ? parseHistoryData.city : " " }</label>
                                                                    </div>
                                                                </div>
                                                            </div> :""}
                                                            {parseHistoryData.postal_code ?
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Postal Code</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="  col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.postal_code  ? parseHistoryData.postal_code : " "}</label>
                                                                    </div>
                                                                </div>
                                                            </div> : ""}
                                                            {parseHistoryData.country ?
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Country</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className=" col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.country == null ?  parseHistoryData.country : ""}</label>
                                                                    </div>
                                                                </div>
                                                            </div> : ""}
                                                            {parseHistoryData.total_experience ?
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Total Experience</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="  col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.total_experience ? parseHistoryData.total_experience : "" }</label>
                                                                    </div>
                                                                </div>
                                                            </div>:""}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="ContactInfoAccordion">
                                                        <button className="accordion-button collapsed DarkBlue4_Color font_weight_400 font_20" type="button" data-bs-toggle="collapse" data-bs-target="#ContactInfoCollapseAccordion" aria-expanded="false" aria-controls="ContactInfoCollapseAccordion">
                                                            <img src={Accordion_Icon_2} alt="Icon 1" className="Accordion_Icon me-3" />
                                                            Contact Info
                                                        </button>
                                                    </h2>
                                                    <div id="ContactInfoCollapseAccordion" className="accordion-collapse collapse show" aria-labelledby="ContactInfoAccordion"  >
                                                        <div className="accordion-body">
                                                            {parseHistoryData.email ?
                                                        <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Email</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className=" col-xl-8 col-6 text-break">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.email ? parseHistoryData.email : "" }</label>
                                                                    </div>
                                                                </div>
                                                            </div> : ""}
                                                            {parseHistoryData.phone ?
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Phone</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className=" col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.phone ? parseHistoryData.phone : ""}</label>
                                                                    </div>
                                                                </div>
                                                            </div> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="accordion-item">
                                                    <h2 className="accordion-header" id="JobObjectiveAccordion">
                                                        <button className="accordion-button collapsed DarkBlue4_Color font_weight_400 font_20" type="button" data-bs-toggle="collapse" data-bs-target="#JobObjectiveCollapseAccordion" aria-expanded="false" aria-controls="JobObjectiveCollapseAccordion">
                                                            <img src={Accordion_Icon_3} alt="Icon 1" className="Accordion_Icon me-3" />
                                                            Job Objective
                                                        </button>
                                                    </h2>
                                                    <div id="JobObjectiveCollapseAccordion" className="accordion-collapse collapse" aria-labelledby="JobObjectiveAccordion" data-bs-parent="#OverViewSignleViewAccordion">
                                                        <div className="accordion-body">
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Job Objective</label>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="col-sm-8 col-12">
                                                                        <label className="font_16 font_weight_600 AccordionValue"></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                 {parseHistoryDataEmpHistory.length !== 0 ?
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="EmploymentHistoryAccordion">
                                                        <button className="accordion-button collapsed DarkBlue4_Color font_weight_400 font_20" type="button" data-bs-toggle="collapse" data-bs-target="#EmploymentHistoryCollapseAccordion" aria-expanded="false" aria-controls="EmploymentHistoryCollapseAccordion">
                                                            <img src={Accordion_Icon_4} alt="Icon 1" className="Accordion_Icon me-3" />
                                                            Employment History
                                                        </button>
                                                    </h2>
                                                    <div id="EmploymentHistoryCollapseAccordion" className="accordion-collapse collapse show" aria-labelledby="EmploymentHistoryAccordion"  >
                                                        <div className="accordion-body">
                                                            <div className="table-responsive">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Employer Name</th>
                                                                            <th>Position</th>
                                                                            <th>Start Date</th>
                                                                            <th>End Date</th>
                                                                            {/* <th>Current Status</th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            
                                                                            parseHistoryDataEmpHistory.map((item,index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{item.employer_name}</td>
                                                                                        <td>{item.position}</td>
                                                                                        <td>{item.start_date}</td>
                                                                                        <td>{item.end_date}</td>
                                                                                        {/* <td>{item.current_status}</td> */}
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            {/* <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Employer Name</label>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="col-sm-8 col-12">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryDataEmpHistory.employer_name}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Position</label>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="col-sm-8 col-12">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryDataEmpHistory.position}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Start Date</label>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="col-sm-8 col-12">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryDataEmpHistory.start_date}</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">End Date</label>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="col-sm-8 col-12">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryDataEmpHistory.end_date}</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Current Status</label>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="col-sm-8 col-12">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryDataEmpHistory.current_status}</label>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div> : ""}
                                                {parseHistoryDataEduHistory.length !== 0 ?
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="EducationHistoryAccordion">
                                                        <button className="accordion-button collapsed DarkBlue4_Color font_weight_400 font_20" type="button" data-bs-toggle="collapse" data-bs-target="#EducationHistoryCollapseAccordion" aria-expanded="false" aria-controls="EducationHistoryCollapseAccordion">
                                                            <img src={Accordion_Icon_5} alt="Icon 1" className="Accordion_Icon me-3" />
                                                            Education History
                                                        </button>
                                                    </h2>
                                                    <div id="EducationHistoryCollapseAccordion" className="accordion-collapse collapse show" aria-labelledby="EducationHistoryAccordion" >
                                                        <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                            <div className="table-responsive">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>School Name</th>
                                                                            <th>Degree Name</th>
                                                                            <th>Passed Out</th>
                                                                            <th>Major Subjects</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            parseHistoryDataEduHistory.map((item,index) => {
                                                                                return(
                                                                                    <tr key={index}>
                                                                                        <td>{item.school_name}</td>
                                                                                        <td>{item.degree_name}</td>
                                                                                        <td>{item.last_education_date}</td>
                                                                                        <td>{item.major}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            {/* <div className="row">
                                                                <div className="col-3">
                                                                    <label className="font_16 font_weight_300  AccordionKey">School Name</label>
                                                                </div>
                                                                <div className="col-1">
                                                                    <strong> : </strong>
                                                                </div>
                                                                <div className="col-sm-8 col-12">
                                                                    <label className="font_16 font_weight_600 AccordionValue">{parseHistoryDataEduHistory.school_name}</label>
                                                                </div>
                                                            </div> */}
                                                        </div>

                                                        {/* <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                            <div className="row">
                                                                <div className="col-3">
                                                                    <label className="font_16 font_weight_300  AccordionKey">Degree Name</label>
                                                                </div>
                                                                <div className="col-1">
                                                                    <strong> : </strong>
                                                                </div>
                                                                <div className="col-sm-8 col-12">
                                                                    <label className="font_16 font_weight_600 AccordionValue">{parseHistoryDataEduHistory.degree_name}</label>
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                        {/* <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                            <div className="row">
                                                                <div className="col-3">
                                                                    <label className="font_16 font_weight_300  AccordionKey">Passed Out</label>
                                                                </div>
                                                                <div className="col-1">
                                                                    <strong> : </strong>
                                                                </div>
                                                                <div className="col-sm-8 col-12">
                                                                    <label className="font_16 font_weight_600 AccordionValue">{parseHistoryDataEduHistory.passed_out_year}</label>
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                        {/* <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                            <div className="row">
                                                                <div className="col-3">
                                                                    <label className="font_16 font_weight_300  AccordionKey">Major</label>
                                                                </div>
                                                                <div className="col-1">
                                                                    <strong> : </strong>
                                                                </div>
                                                                <div className="col-sm-8 col-12">
                                                                    <label className="font_16 font_weight_600 AccordionValue">{parseHistoryDataEduHistory.major}</label>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div> : ""}
                                                {parseHistoryData.skills ?
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="SkillsAccordion">
                                                        <button className="accordion-button collapsed DarkBlue4_Color font_weight_400 font_20" type="button" data-bs-toggle="collapse" data-bs-target="#SkillsCollapseAccordion" aria-expanded="false" aria-controls="SkillsCollapseAccordion">
                                                            <img src={Accordion_Icon_6} alt="Icon 1" className="Accordion_Icon me-3" />
                                                            Skills
                                                        </button>
                                                    </h2>
                                                    <div id="SkillsCollapseAccordion" className="accordion-collapse collapse show" aria-labelledby="SkillsAccordion"  >
                                                        <div className="accordion-body">
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Skills</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className=" col-xl-8 col-6 text-break">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.skills + ", " + " "}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : ""}
                                                {parseHistoryData.certifications ? 
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="CertificationsAccordion">
                                                        <button className="accordion-button collapsed DarkBlue4_Color font_weight_400 font_20" type="button" data-bs-toggle="collapse" data-bs-target="#CertificationsCollapseAccordion" aria-expanded="false" aria-controls="CertificationsCollapseAccordion">
                                                            <img src={Accordion_Icon_7} alt="Icon 1" className="Accordion_Icon me-3" />
                                                            Certifications
                                                        </button>
                                                    </h2>
                                                    <div id="CertificationsCollapseAccordion" className="accordion-collapse collapse show" aria-labelledby="CertificationsAccordion" >
                                                        <div className="accordion-body">
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Certifications</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="  col-xl-8 col-6 text-break">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.certifications}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : ""}
                                            </div>
                                            <div className="row">
                                       
                                                <div className="col-xxl-6 col-xl-3 col-lg-3 col-md-5 col-sm-6 col-6 mx-auto">
                                                    <div className="text-left">
                                                        {PrevUrl ?
                                                        <button className="btn btn-primary DarkBlue3_BgColor font_weight_500 " onClick={()=>PrevResumetoFetch()}>Prev</button> : ""}
                                                    </div>
                                                    
                                                </div>
                                                <div className="col-xxl-6 col-xl-3 col-lg-3 col-md-5 col-sm-6 col-6 mx-auto">
                                                    <div className="text-end">
                                                        {nextUrl ? 
                                                        <button className="btn btn-primary DarkBlue3_BgColor font_weight_500 " onClick={()=>nextResumetoFetch()}>Next</button> : ""}
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="text-end mt-3">
                                                <Link className="btn btn-primary DarkBlue3_BgColor MyBtnSize font_weight_500 font_20" to="/resumeparsing" onClick={()=>dispatch(RESUMEPARSINGERRS([]))}>Back</Link>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </>
                                {/* <OverView /> */}
                            </CustomTabPanel>
                        </Box>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default SignleResumeView;