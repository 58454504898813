import React, {useState} from "react";
import Layout from "../../Components/Layout";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const BasicSettings = () => {
    const [showModal, setShowModal] = useState(false);
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    return (
        <>
            <Layout>
                <div className="mx-4">
                    <form className="BasicSettingsForm" id="BasicSettingsForm">
                        <div className="row">
                            <div className="col-12">
                                    <div className="text-center mb-4">
                                    <button className="btn btn-primary font_18 font_weight_500 bg-white shadow DarkBlue3_Color MyBtnSize border-0 me-3" type="button" onClick={handleModalShow}>Generate</button>
                                    {/* <Link className="btn btn-primary font_18 font_weight_500 bg-white shadow DarkBlue3_Color MyBtnSize border-0 me-3" to="/basicsettings">Generate</Link> */}
                                    {/* <button className="btn btn-primary font_18 font_weight_500 bg-white shadow DarkBlue3_Color MyBtnSize border-0">Pre-Generate</button> */}
                                </div>
                            </div>
                        </div>
                    </form>
                    <>
                        <div className="modals">
                        <Modal size="lg" show={showModal} onHide={handleModalClose}>
                            <Modal.Header>
                                <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Generate Test</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="TestNameId DarkGrey7_Color font-weight_400 font_16">Test Name</label>
                                                <input type="text" className="form-control" id="TestNameId" />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="font_16 font_weight_500 DarkGrey7_Color">Round</th>
                                                            <th className="font_16 font_weight_500 DarkGrey7_Color">Duration</th>
                                                            <th className="font_16 font_weight_500 DarkGrey7_Color">Total Score</th>
                                                            <th className="font_16 font_weight_500 DarkGrey7_Color">Quaffing Score</th>
                                                            <th className="font_16 font_weight_500 DarkGrey7_Color">Level</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                                    <label className="form-check-label" for="flexCheckChecked">Profiling</label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control" id="TestProfilingDuration" pattern="[0-9]" />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control" id="TestProfilingTotalScore" pattern="[0-9]" />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control" id="TestProfilingQuaffingScore" pattern="[0-9]" />
                                                            </td>
                                                            <td>
                                                                <select className="form-select" aria-label="Criticality select example" id="ProfilingLevelCriticality">
                                                                    <option disabled selected>Criticality</option>
                                                                    <option value="0">Easy</option>
                                                                    <option value="1">Medium</option>
                                                                    <option value="2">Hard</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                                    <label className="form-check-label" for="flexCheckChecked">Behavior</label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control" id="TestBehaviorDuration" pattern="[0-9]" />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control" id="TestBehaviorTotalScore" pattern="[0-9]" />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control" id="TestBehaviorQuaffingScore" pattern="[0-9]" />
                                                            </td>
                                                            <td>
                                                                <select className="form-select" aria-label="Criticality select example" id="BehaviorLevelCriticality">
                                                                    <option disabled selected>Criticality</option>
                                                                    <option value="0">Easy</option>
                                                                    <option value="1">Medium</option>
                                                                    <option value="2">Hard</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                                    <label className="form-check-label" for="flexCheckChecked">Technical</label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control" id="TestTechnicalDuration" pattern="[0-9]" />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control" id="TestTechnicalTotalScore" pattern="[0-9]" />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control" id="TestTechnicalQuaffingScore" pattern="[0-9]" />
                                                            </td>
                                                            <td>
                                                                <select className="form-select" aria-label="Criticality select example" id="TechnicalLevelCriticality">
                                                                    <option disabled selected>Criticality</option>
                                                                    <option value="0">Easy</option>
                                                                    <option value="1">Medium</option>
                                                                    <option value="2">Hard</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                                    <label className="form-check-label" for="flexCheckChecked">HR</label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control" id="TestHRDuration" pattern="[0-9]" />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control" id="TestHRTotalScore" pattern="[0-9]" />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control" id="TestHRQuaffingScore" pattern="[0-9]" />
                                                            </td>
                                                            <td>
                                                                <select className="form-select" aria-label="Criticality select example" id="HRLevelCriticality">
                                                                    <option disabled selected>Criticality</option>
                                                                    <option value="0">Easy</option>
                                                                    <option value="1">Medium</option>
                                                                    <option value="2">Hard</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </form>
                            </Modal.Body>
                            <Modal.Footer className="mx-auto border border-danger">
                                <Button variant="secondary" onClick={handleModalClose} className="border border-danger">
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleModalClose}>
                                    Link
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        </div>
                    </>
                    {/* <div className="row">
                        <div className="col-12">
                            <DataTable columns={columns} data={data} selectableRows/>
                        </div>
                    </div> */}

                </div>
            </Layout>
        </>
    )
}

export default BasicSettings;