import React, {useEffect} from "react";
import Layout from "../Components/Layout";

import FrameBackground from './../Images/Frame.png';
import DashboardImg from './../Images/DashboardImg.png';
import Object1 from './../Images/OBJECTS_1.png';
import Object2 from './../Images/OBJECTS_2.png';
import Object3 from './../Images/OBJECTS_3.png';
import Object4 from './../Images/OBJECTS_4.png';
import Object5 from './../Images/OBJECTS_5.png';
import Object6 from './../Images/OBJECTS_6.png';
import { Link } from "react-router-dom";
import { PAGENUMBERS, PAGESEARCHS, PAGESIZES  } from './../Redux/actions';
import { useSelector, useDispatch } from 'react-redux';
const Dashboard = () => {
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(PAGESEARCHS(""))
        dispatch(PAGENUMBERS(""))
        dispatch(PAGESIZES(""))
    }, [])
    return (
        <>
            <div className="row mx-0 position-relative"  id="DashboardBackground">
                <div className="DarkBackGroupForTopHeader"></div>
                <img src={FrameBackground} alt="Frame Image" className="Frame Frame1" />
                <img src={FrameBackground} alt="Frame Image" className="Frame Frame2" />
                <img src={Object1} alt="Object1 Image" className="ObjectImg Object1" />
                <img src={Object2} alt="Object2 Image" className="ObjectImg Object2" />
                <img src={Object3} alt="Object3 Image" className="ObjectImg Object3" />
                <img src={Object4} alt="Object4 Image" className="ObjectImg Object4" />
                <img src={Object5} alt="Object5 Image" className="ObjectImg Object5" />
                <img src={Object6} alt="Object6 Image" className="ObjectImg Object6" />

                <Layout>
                    <div className="col-12">
                        <div className="row mx-0">
                            <div className="col-12 text-center">
                                <h1 className="font_64 text-white font_weight_600 py-3">Welcome to the <span className="LightGreen_Color">Talent SCAN</span> Portal!</h1>
                            </div>
                            <div className="col-md-5 col-sm-12 col-12 mx-auto">
                                <h4 className="font_22 text-white font_weight_400 text-center">Developing an effective resume parser for talent scanning involves a combination of linguistic analysis, machine learning, and careful consideration of the specific needs of the recruiting process.</h4>
                            </div>
                            <div className="col-12 text-center py-3">
                                <h2 className="font_50 text-white">Test the Parser</h2>
                                <div className="BtnGrouop">
                                    <Link to="/resumeparsing" className="btn btn-success LightGreen_BgColor MyBtnSize me-3 demositemobile">Demo Site</Link>
                                    <button type="button" className="btn btn-primary DarkBlue3_BgColor MyBtnSize">Get API</button>
                                </div>
                            </div>

                            <div className="col-12 text-center pt-4 mobilehide">
                                <img src={DashboardImg} alt="Dashboard Image" className="DashboardImg" />
                            </div>

                            <div className="col-12 text-center pt-3">
                                <button type="button" className="btn btn-transparent text-white border rounded-4 mobileMarging">Documentation</button>
                                <button type="button" className="btn btn-transparent text-white border rounded-4 mx-5 mobileMarging">Customer Agreements</button>
                                <button type="button" className="btn btn-transparent text-white border rounded-4 mobileMarging">Privacy &amp; Security</button>
                            </div>
                        </div>
                    </div>
                
                </Layout>
            </div>
        </>
    )
}

export default Dashboard;