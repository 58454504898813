import React from "react";

const GradingSummary = () => {
    return (
        <>
            <h1>GradingSummary</h1>
        </>
    )
}

export default GradingSummary;