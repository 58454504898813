import React, {useState, useEffect} from "react";
import Layout from "../../Components/Layout";
import { Link } from "react-router-dom";
import TestImg3 from './../../Images/Test_Img_3.png';
import TestIcon6 from './../../Images/Test_Icon_6.png';
import TestIcon7 from './../../Images/Test_Icon_7.png';
import TestIcon8 from './../../Images/Test_Icon_8.png';
import talentscanlogo from './../../Images/talent-scan-logo-final-white.png';
const Instructions = ({data, id, candidate_id}) => {
    const [initialData, setinitialData] = useState({})
    useEffect(()=>{
        setinitialData(data)
    }, [data])
    return (
        <>
            <Layout>
                <div className="row mx-0">
                    <div className="col-12 my-2">
                        <div className="rounded-5 DarkBlue3_BgColor py-3">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    {/* <Link to="/" className="text-decoration-none"> */}
                                    <img src={talentscanlogo} alt="logo" />
                                    {/* </Link> */}
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-md-end text-sm-end text-start">
                                    <h2 className="font_40 font_weight_800 LineHeight29 mb-0 py-3 pe-5"><span className="text-light">Instru</span><span className="LightGreen_Color">ctions</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-5 pt-5 inmobilechange">
                        <div className="row">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="TestImgContainer text-end">
                                    <img src={TestImg3} alt="TestImg3" className="TestImage img-fluid" id="TestImg3" />
                                </div>
                            </div>
                            
                            <div className="offset-xxl-1 offset-xl-1 offset-lg-1 col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-5">
                                <div className="McqVerbalExplanation mt-5">
                                    <div className="card border-0">
                                        <div className="card-body LightGrey10_BgColor rounded-4 p-4">
                                            <div className="QuestionTypeContainer1 d-flex">
                                                <div className="QuestionType DarkBlue4_BgColor ps-3 pe-2 py-2">
                                                    <h4 className="font_weight_600 font_25 LightGreen_Color">Question Type</h4>
                                                </div>
                                                <div className="VerbalExplanation LightBlue3_BgColor ps-3 pe-2 py-2">
                                                    <h4 className="font_weight_600 font_25 DarkBlue4_Color">{initialData.section__name}</h4>
                                                </div>
                                            </div>
                                            <div className="InstructionsBlock d-flex align-items-center pt-3">
                                                <div className="InstrctionIcon pe-2">
                                                    <img src={TestIcon6} alt="TestIcon6" className="Test_Icon" id="TestIcon6" />
                                                </div>
                                                <div className="InstrctionContent font_weight_600 font_22 DarkBlue4_Color">Total No of Questions: <span className="LightGreen_Color">{initialData.no_of_questions}</span></div>
                                            </div>

                                            <div className="InstructionsBlock d-flex align-items-center pt-3">
                                                <div className="InstrctionIcon pe-2">
                                                    <img src={TestIcon7} alt="TestIcon6" className="Test_Icon" id="TestIcon6" />
                                                </div>
                                                <div className="InstrctionContent font_weight_600 font_22 DarkBlue4_Color">Total Time: <span className="LightGreen_Color">{initialData.test_duration} Minute</span></div>
                                            </div>

                                            <div className="InstructionsBlock d-flex align-items-center pt-3">
                                                <div className="InstrctionIcon pe-2">
                                                    <img src={TestIcon8} alt="TestIcon6" className="Test_Icon" id="TestIcon6" />
                                                </div>
                                                <div className="InstrctionContent font_weight_500 font_22 DarkBlue4_Color">{initialData.question_type}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="BtnGroup ParsingResumeBtn mt-4 text-center">
                            <Link to={`/answerthequestion?id=${initialData.id}&attemtID=${initialData.attempt_id}&testID=${id}&sectionName=${initialData.section__name}&candidate_id=${candidate_id}`} className="btn btn-primary DarkBlue3_BgColor MyBtnSize" >Continue</Link>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Instructions;