import {ToastContainer} from 'react-toastify'
const Toaster = () => {
    return (
      <ToastContainer
        position="top-center"
        autoClose={5000} // Close the toast automatically after 3 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="dark"
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    );
};

export default Toaster