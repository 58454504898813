import React, { useState, useEffect, useRef } from "react";
import axios  from "axios";
import Editor  from "./Editor";
import TestImg1 from './../../Images/Test_Img_1.png';
import { useNavigate } from "react-router-dom";
import { increment, decrement } from './../../Redux/actions';
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import Toaster from './../../Toaster'
const CodingAnswerTheQuestion = ({question, callback, totalCount, next, callapi}) => {
    const dispatch = useDispatch();
    const [dataFromOutPut, setdataFromOutPut] = useState('',"")
    const [dataFromInPut, setdataInOutPut] = useState('', '')
    // const [srcDoc, setSrcDoc] = useState('')
    // const [codeIN, setCodeIN] = useState("")
    const [selectedLan, setselectedLan] = useState("Python")
    const [mediaStream, setMediaStream] = useState(null);
    const [recording, setRecording] = useState(false);
    const [attempt_id, setattempt_id] = useState()
    const [id, setid] = useState()
    const [testid, settestid] = useState()
    const [sectionName, setsectionName] = useState("")
    const [loader, setloader] = useState(false)
    const navigate = useNavigate();
    const [candidate_id, setcandidate_id] = useState()
    const [showsubmitbutton, setshowsubmitbutton] = useState(false)
    const [timeoutsec, settimeoutsec] = useState(false)
    const [linkGenShowModal2, setLinkGenShowModal2] = useState(false)
    const videoRef2 = useRef(null);
    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //       setSrcDoc(`
    //         <html>
    //           <body>${selectedLan === "HTML" || selectedLan === "Python" ? dataFromOutPut : ""}</body>
    //           <style>${selectedLan === "CSS" ? dataFromOutPut : ""}</style>
    //           <script>${selectedLan === "Javascript" ? dataFromOutPut : ""}</script>
    //         </html>
    //       `)
    //     }, 250)
    
    //     return () => clearTimeout(timeout)
    // }, [dataFromOutPut, selectedLan])
    useEffect(()=>{
        question.ans = dataFromOutPut
    }, [dataFromOutPut])
    useEffect(()=>{
        setdataInOutPut(question.question_name)
        setdataFromOutPut("")
    }, [question])
    const sendAnswertoApi = () => {
        setshowsubmitbutton(false)
        setloader(false)
        stopRecording()
    }
    const catchforfronterrorlog = (e,data) => {
       
        const formData = new FormData();
        formData.append("candidate_email",`${sessionStorage.getItem("token")}`)
        formData.append("exception", JSON.stringify(e))
        formData.append("post_data_for_answer",data)
        axios.defaults.headers.common = { 'Authorization': `${sessionStorage.getItem("token")}` }
        axios.post(`/apiV1/catch-error-log/`, formData).then((res) => {
             
        }).catch((e) => {
            console.log(e)
        });
    }
    const sectionTimeOutForNextQuestion = () => {
        let testid = sessionStorage.getItem("testid")
        let questioninfo = JSON.parse(sessionStorage.getItem("questioninfo"))

        const formData = new FormData();
        formData.append("last_question_attempted",questioninfo.id)
        formData.append("section_name",questioninfo.category)
        formData.append("remarks",'Section time out')
        axios.defaults.headers.common = { 'Authorization': `${sessionStorage.getItem("token")}` }
        axios.put(`/apiV1/section-timed-out/${testid}/`, formData).then((res) => {
             
        }).catch((e) => {
            catchforfronterrorlog(e, 'section time out');
            console.log(e)
        });
    }
    useEffect(()=>{
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search.slice(1));
        let obj = {};
        for(let pair of params.entries()) {
            obj[pair[0]] =pair[1];
        }
        setattempt_id(obj.attemtID)
        setid(obj.id)
        settestid(obj.testID)
        setsectionName(obj.sectionName)
        setcandidate_id(obj.candidate_id)

        let time = sessionStorage.getItem("timer")
        if(time){
            let totaltime = time * 60 * 1000
            let percent = (totaltime*80)/100
            const timer20per = setTimeout(() => {
                toast('You have only 20% time left in this section please check remaining question',)
            
            }, percent);
                
            const timer = setTimeout(() => {
                setLinkGenShowModal2(true)
                sectionTimeOutForNextQuestion()
            }, totaltime);
            return () => {
                clearTimeout(timer);
                clearTimeout(timer20per);
            }
        }

        document.addEventListener("visibilitychange", (event) => {

        });   
        // document.addEventListener("visibilitychange", (event) => {
        //     let tabCount = sessionStorage.getItem("tabCount");
                
        //     if (document.visibilityState == "visible") {
        //         if(tabCount >= 6){
        //             navigate(`/errorbrowser?id=${obj.testID}&attemtID=${obj.attemtID}&refresh=1`);
        //         }
        //     } else {
               
        //         if(tabCount){
        //             alert("Your not allow to change tab")
        //             let tabCounts = parseInt(tabCount) + 1
        //             sessionStorage.removeItem("tabCount")
        //             sessionStorage.setItem("tabCount", tabCounts)
        //         }else{
        //             sessionStorage.setItem("tabCount", 1)
        //         }
                
              
        //     }
        // });

        window.onblur = () => {
            if (document.visibilityState == "visible") {
                let tabCount = sessionStorage.getItem("tabCount");
                if(tabCount >= 6){
                    stopRecording();
                    navigate(`/errorbrowser?id=${obj.testID}&attemtID=${obj.attemtID}&refresh=1`);
                }else{
                    if(tabCount){
                        alert("Your not allow to change tab")
                        let tabCounts = parseInt(tabCount) + 1
                        sessionStorage.removeItem("tabCount")
                        sessionStorage.setItem("tabCount", tabCounts)
                    }else{
                        sessionStorage.setItem("tabCount", 1)
                    }
                }
            }
            
        };
        window.addEventListener('popstate', function () {
            this.alert('Your not allow to back')
            navigate(`/answerthequestion?id=${obj.id}&attemtID=${obj.attempt_id}&testID=${obj.testID}&sectionName=${obj.sectionName}&candidate_id=${obj.candidate_id}`)
            
        });
    }, [])
    useEffect(()=>{
        startRecording()
        setloader(false)
        setTimeout(() => {
            setshowsubmitbutton(true)
        }, 10000);
    }, [question])

    
    const codingQuestionAnser = (blob) => {
        dispatch(increment())
        setloader(true)
        setTimeout(() => {
            if(timeoutsec == false){
                if(next){
                    callback(id, next, candidate_id)
                }else{
                    navigate(`/instructions2?id=${testid}&attemtID=${attempt_id}&refresh=1`);
                }
            }
        }, 2000);
        const recordedBlob = new Blob(blob, { type: 'video/webm' });
        const recordedUrl = URL.createObjectURL(recordedBlob);
        console.log(recordedUrl)
        const formData = new FormData();
        formData.append("question_id",question.id )
        formData.append("question_name",question.question_name )
        formData.append("section_name", sectionName)
        formData.append("question_type",question.question_type  )
        formData.append("video_file", recordedBlob )
        formData.append("answer",question.ans)
        formData.append("choices", "" )
        formData.append("attempt_id",attempt_id )
        var object = {};
        formData.forEach((value, key) => object[key] = value);
        var json = JSON.stringify(object);
        const config = {
            headers: { Authorization: `${sessionStorage.getItem("token")}`, 'content-type': 'multipart/form-data'}
        }
        axios.post(`/apiV1/post-answer/`,formData, config).then((res) => {
            if(res.data){
                dispatch(decrement())
                if(res.data.id){
                    if(callapi){
                        navigate(`/instructions2?id=${testid}&attemtID=${attempt_id}&refresh=1`);
                    }
                    
                    console.log(res.data)
                }else{
                    catchforfronterrorlog(res.data, json);
                }
            }
        }).catch((e) => {
            catchforfronterrorlog(e, json);
            console.log(e)
        });
       
    }
    
    const startRecording = async (dataFromOutPut) => {
        const constraints = {
            audio: {
                sampleSize: 1,
                channelCount: 1,
                echoCancellation: true,
                noiseSuppression: true,
                autoGainControl: true,
              
            },
            //video: true,
            video : {
                width: { ideal: 128 }, // Set the desired width
                height: { ideal: 128 }, // Set the desired height
                frameRate: { ideal: 10 }, // Set the desired frame rate
                facingMode: "user" // Use the user-facing camera
                // Add more constraints as needed
            },
          }
        try {

            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            
            console.log('Video input devices:', videoDevices);
            
            if (videoDevices.length === 0) {
                console.error('No video input devices available.');
                // Handle the case where no cameras are available
                return;
            }
            const defaultVideoDevice = videoDevices[0];
    
            // Set the default video device as the preferred one
            constraints.video.deviceId = { exact: defaultVideoDevice.deviceId };
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            console.log(stream)
            videoRef2.current.srcObject = stream;
            setMediaStream(stream);
            const recorder = new MediaRecorder(stream);
            recorder.start();
            var blobContainer = []
            recorder.ondataavailable = (event) => {
                blobContainer.push(event.data)
            };
            recorder.onstop = () => {
                codingQuestionAnser(blobContainer)
            };
            setRecording(true);
        } catch (error) {
            console.error('Error accessing camera:', error);
        }
    };
    const stopRecording = () => {
        if (mediaStream) {
            mediaStream.getTracks().forEach((track) => track.stop());
            setMediaStream(null);
            setRecording(false);
        }
    };
    const yourtimeiscompleted = () => {
        setLinkGenShowModal2(false)
        stopRecording();

        settimeoutsec(true)
        setTimeout(() => {
            navigate(`/instructions2?id=${testid}&attemtID=${attempt_id}&candidate_id=${candidate_id}&refresh=1`);
        }, 1000);
    }
    return (
        
        <>
             {!loader ? <div className="row">
            <div className="col-md-12 text-center mb-3">
                <div className="card  d-inline-block ">
                    <div className="card-body">
                        <h5 className="font_weight_400 font_22">Total Questions {question.question_no}/{totalCount}</h5>
                        <small>Question Type : {sectionName}</small>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="text-left text-start">
                            <label for="exampleFormControlInput1" class="form-label">Select Language</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e)=>setselectedLan(e.target.value)}>
                                <option >Select Language</option>
                                <option value="HTML">HTML</option>
                                <option value="CSS">CSS</option>
                                <option value="Javascript">Javascript</option>
                                <option value="Python">Python</option>
                                <option value="Java">Java</option>
                                <option value="Cpp">Cpp</option>
                                <option value="Javascript">Other</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-5">
                 
                <Editor  language={selectedLan === "HTML" ? "xml" : selectedLan === "CSS" ? "css" : selectedLan === "Javascript" ? "javascript" : "Javascript"}  onChange={()=>setdataInOutPut(dataFromInPut)}  displayName={"Question"} value={dataFromInPut}  /> :
                
              
            </div>
            <div className="col-md-2">
                <video ref={videoRef2}   autoPlay muted poster={TestImg1} width={"100%"} height={400}/>
            </div>
            <div className="col-md-5">
            {selectedLan !== "Python" && selectedLan !== "Java" && selectedLan !== "Cpp" ? <Editor language={selectedLan === "HTML" ? "xml" : selectedLan === "CSS" ? "css" : selectedLan === "Javascript" ? "javascript" : "Javascript"}  displayName={"Answer"}  value={dataFromOutPut} onChange={setdataFromOutPut} /> :
            <Editor language={selectedLan === "Python" ? "x-python" : selectedLan === "Java" ? "x-csrc" : selectedLan === "Cpp" ? "x-csrc" : "x-python"}  displayName={"Answer"}  value={dataFromOutPut} onChange={setdataFromOutPut} />}
            </div>
            
            <div className="col-md-12 text-center mt-5">
                <button type="button" onClick={()=>sendAnswertoApi()} disabled={!showsubmitbutton}  className="btn btn-primary DarkBlue3_BgColor MyBtnSize font_weight_500 font_20">Submit </button>
            </div> 
            
            </div>
          : <div class="container2">
          <div class="spinner-container">
              <div class="spinner-border" role="status">
                  <span class="sr-only"></span>
              </div>
          </div>
        </div>}

        <Modal size="md" show={linkGenShowModal2} onHide={()=>setLinkGenShowModal2(false)} backdrop="static"  keyboard={false}>
            <Modal.Header>
                <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className='text-center'>This section time is completed</h4>
                <div className='text-center m-3'>
                    <button className='btn btn-primary DarkBlue3_BgColor font_20 font_weight_500' id="ScoreBoard_Assign_UpdateOkayBtn" onClick={()=>yourtimeiscompleted()}>Ok</button>
                    {/* <Link to={`/instructions2?id=${testid}&attemtID=${attempt_id}&candidate_id=${candidate_id}&refresh=1`} 
                        >   OK
                    </Link> */}
                </div>
            </Modal.Body>
        </Modal>
        </> 
    )
}

export default CodingAnswerTheQuestion;