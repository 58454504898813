import React from "react";
import Layout from "../../Components/Layout";

const ProductDocumentationView = () => {
    return (
        <>
            <Layout>
                <div className="row">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-sm-12 col-12 mx-auto pt-5">
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="row shadow rounded-1">
                                    <div className="col-12 LightGrey_BgColor text-center  py-5">
                                        <h1>Product Documentation View in Developement Mode</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ProductDocumentationView;