import React, { useEffect} from "react";
import { Navigate } from "react-router-dom";
const  PrivateRouter2 =( {children} ) => {
    const token = sessionStorage.getItem("token")
    const testLogin = sessionStorage.getItem("testLogin")
    const getCookie = () => {
        return sessionStorage.getItem("token")
    }
    useEffect(()=>{
        getCookie();
    })
    return (
        <>
            <div id="Wrapper" className="toggled">
                {token && testLogin ? 
                <> {children} </> :
                    <><Navigate to="/logintest" /></> 
                    
                }
            </div>
        </>
    )
}

export default PrivateRouter2;