import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Frame_DemoSiteParsing from './../../Images/Frame_DemoSiteParsing.png';
import ActionIconEye from './../../Images/ActionEyeIcon.png';
import axios from "axios";
import moment from "moment";
import DataTable from 'react-data-table-component';
import ResumeParsingUploads from "./ResumeParsingUploads";
import { useSelector, useDispatch } from 'react-redux';
import { PAGENUMBERS, PAGESEARCHS, PAGESIZES } from "../../Redux/actions";


const ResumeParsing = () => {

    const dispatch = useDispatch()
    const columns = [
        { 
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Date</label>,
            selector: (row)=>row.created_at , cell: row => <> {moment(row.created_at).format("DD-MM-YYYY")} </>,
        },
        { 
            name: <label className="DarkBlue4_Color font_weight_500 font_14">User</label>,
           selector: (row)=>row.email , cell: row => <> {row.email} </>,
        },
        { 
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Document(s)</label>,
           selector: (row)=>row.documents_count, cell: row => <> {row.documents_count} </>,
        },
        { 
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Actions</label>,
            selector: (row)=>row.id, cell: row => <> 
                <div className="DarkBlue4_Color font_weight_300 font_14">
                    <Link to={`/signleresumeview?id=${row.id}`}>
                        <img src={ActionIconEye} alt="ActionIconEye" className="ActionIcon ActionIconEye img-fluid" />
                    </Link>
                    {/* <a  href={ExamplePdf} download="Example-PDF-document" target="_blank" rel="noreferrer" > */}
                        {/* <button type="button" className="btn btn-transparent" onClick={handleMyClick}>
                            <img src={ActionIconEye} alt={item.id} className="ActionIcon ActionIconEye img-fluid" />
                        </button> */}
                    {/* </a> */}
    
                    {/* <a href="" download className=" ms-2">
                         
                        <button type="button" className="btn btn-transparent">
                            <img src={ActionIconDownload} alt="ActionIconDownload" className="ActionIcon ActionIconDownload img-fluid" />
                        </button>
                    </a> */}
                </div>
            </>,
        },
    ];
   
    const [loader, setLoader] = useState(true);
    const [resumeHistory, setResumeHistory] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };

    const handlePageChange = page => {
        getResumeHistory(page);
        setCurrentPage(page);

    };

    const handlePerRowsChange = async (newPerPage, page = perPage) => {
        getResumeHistory(page, newPerPage);
        setPerPage(newPerPage);
    };

  
    
    const getResumeHistory = async (page1, size = perPage) => {
        let token = sessionStorage.getItem('Admintoken');
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
        }
        
        let offcet = page1 -1 
        axios.get(`/apiV1/parsing-history/?limit=${size}&offset=${offcet*size}`,config).then((res) => {
            setLoader(false)
        // axios.get('/apiV1/parsing-history/',config).then((res) => {
            res.data.results.map((item, i) => {
                    item.Num = i + 1;
                    return item;
            })
            setResumeHistory(res.data.results)
            setTotalRows(res.data.count)
             
            
        }).catch((e) => {
            setLoader(false)
             
        })
    }
    useEffect(() => {
        if (sessionStorage.getItem('Admintoken') !== "" && sessionStorage.getItem('Admintoken') !== null) {
            
        }
        getResumeHistory(1);
        dispatch(PAGESEARCHS(""))
        dispatch(PAGENUMBERS(""))
        dispatch(PAGESIZES(""))
    },[])

    // const getResumeHistory = async (page, size = perPage) => {
    
    return (
        <>
            {/* <div className="row mx-0">
                <div className="col-12 py-5">
                    <div className="text-center">
                        <Link to="/demosite" className="btn btn-primary font_18 font_weight_500 DarkBlue3_BgColor  MyBtnSize border-0">Resume Parsing</Link>
                        <Link to="/biometricscoring" className="btn btn-primary font_18 font_weight_500 LightGrey3_BgColor DarkBlue3_Color MyBtnSize ms-3 border-0">Biometric Scoring</Link>
                    </div>
                </div>
            </div> */}
            <ResumeParsingUploads />
            {/* <div className="row">
                <div className="col-xxl-7 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 mx-auto">
                    <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="BannerContainer pb-5">
                                <h2 className="font_44 DarkBlue4_Color font_weight_500">Sample Resume Parsing UI*</h2>
                                <div className="row">
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <p className="font_20 font_weight_300">Experience the power of our parsing API. upload up to 50 resumes.</p>
                                    </div>
                                </div>
                               
                                
                                <div className="BtnGroup ParsingResumeBtn">
                                    <Link to="/resumeparsinguploads" className="btn btn-primary DarkBlue3_BgColor MyBtnSize">Parsing Resume</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="ImgContainer">
                                <img src={Frame_DemoSiteParsing} alt="Parsing Resume Img" className="img-fluid ParsingResumeImage" />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="row LightGrey3_BgColor px-0">
                <div className="col-xxl-7 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 mx-auto">
                    <div className="py-5">
                        {!loader ? 
                        <DataTable 
                            columns={columns} 
                            data={resumeHistory.length > 0 ? resumeHistory : [] } 
                            fixedHeader
                            persistTableHead
                            pagination 
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}  
                            onChangePage={handlePageChange}
                            fixedHeaderScrollHeight='550px'
                            paginationComponentOptions={paginationComponentOptions}
                        /> : <div className='text-center'>
                        <div className='spinner-border ' role='status'>
                          <span className='sr-only'></span>
                        </div>
                      </div>}
                        {/* {
                            resumeHistory.map((item, index) => {
                                return (
                                    <>
                                        <div className="ResumeParsingBlock mb-3" key={index}>
                                            <div className="card">
                                                <div className="card-boday  rounded-2">
                                                    <div className="ResumeParsingHead py-2">
                                                        <div className="row text-center d-flex justify-content-center align-items-center">
                                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                                <div className="DarkBlue4_Color font_weight_300 font_14">{(item.created_at) ? moment(item.created_at).format("DD-MM-YYYY") : ""}</div>
                                                            </div>
                                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                                <div className="DarkBlue4_Color font_weight_300 font_14">{item.email}</div>
                                                            </div>
                                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                                <div className="DarkBlue4_Color font_weight_300 font_14">
                                                                    <button type="button" className="btn LightBlue2_BgColor text-white btn-sm font_12">{item.documents_count} document</button>
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                                <div className="DarkBlue4_Color font_weight_300 font_14">
                                                                    <Link to={`/signleresumeview?id=${item.id}`}>
                                                                        <img src={ActionIconEye} alt="ActionIconEye" className="ActionIcon ActionIconEye img-fluid" />
                                                                    </Link>
                                                                    <a href="/files/my-tax.pdf" download className=" ms-2">
                                                                        <button type="button" className="btn btn-transparent" onClick={DownloadFiles} >
                                                                            <img src={ActionIconDownload} alt="ActionIconDownload" className="ActionIcon ActionIconDownload img-fluid" />
                                                                        </button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        } */}
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResumeParsing;