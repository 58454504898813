import React, { useState, useEffect } from "react";
import LoginBg from './../../Images/LoginBg.webp';
import Layout from "../../Components/Layout";
import { Link, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";

import axios from "axios";
import talentscanlogo from './../../Images/talent-scan-logo-final-white.png';
const CodeForEmailVerification = () => {
    const navigate = useNavigate();
    const [signInCodeEmailerror, setSignInCodeEmailerror] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [signUpValidationErrors, setSignUpValidationErrors] = useState({})
    const [resendmessagesuccess, setresendmessagesuccess] = useState("")
    const { register, handleSubmit, formState: { errors }, trigger, setValue, getValues } = useForm({});
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    let obj = {};
    for(let pair of params.entries()) {
        obj[pair[0]] =pair[1];
    }

    const mobile =  obj["mobile"] ? obj["mobile"] : 0;
    const user_id =  obj["user_id"] ? obj["user_id"] : 0;
    const codeEmailVerficationAction = (data) => {
        setSignInCodeEmailerror("");
        let req = {
            otp: data.codeForEmail,
        }
        
        axios.post(`/apiV1/time-based/${mobile}/`,req).then(
            (rep) => {
                setIsSubmitting(false)
                if(rep.data.detail === "otp verified succesfully"){
                    navigate(`/forgotpassword?id=${user_id}`);
                }else{
                    setSignInCodeEmailerror(rep.data.detail)
                }
                
            }
        ).catch((e) => {
            if(e.response.data.errors != undefined) {
                setSignUpValidationErrors(e.response.data.errors)
            }
            if(e.response.data.detail) {
                setSignInCodeEmailerror(e.response.data.detail)
            }
        })
    }
    const resendtheCodetoMobileorEmail = () => {
        setSignInCodeEmailerror("");
        axios.get(`/apiV1/time-based/${mobile}/`).then(
            (rep) => {
                setIsSubmitting(false)
                setresendmessagesuccess("OTP Successfully Sent")
            }
        ).catch((e) => {
             
            if(e.response.data.detail) {
                setSignInCodeEmailerror(e.response.data.detail)
            }
        })
    }
    // useEffect(()=>{
    //     resendtheCodetoMobileorEmail()
    // }, [])
    return (
        <>
            <Layout>
                <div className="row mx-0">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-sm-12 col-12 mx-auto pt-5">
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="row shadow rounded-1">
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-4 col-12  LightGrey_BgColor">
                                        <div className="LoginBg d-flex justify-content-center align-items-center">
                                            <div className="d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none px-5">
                                                <img src={LoginBg} alt="LoginBg" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-8 col-12 px-5 pt-4">
                                        <div className="text-center pt-5 pb-4 mt-5">
                                        <img src={talentscanlogo} />
                                        </div>
                                        <div className="text-center">
                                            <h3 className="font_24 font_weight_600 DarkBlue_Color">Code for Email Verification</h3>
                                        </div>
                                        <div className="text-center pt-3">
                                            <p className="font_18 font_weight_400 DarkBlue2_Color">Please enter Generated OTP in below</p>
                                        </div>
                                        
                                        <form id="EmailcodeValidationForm" className="EmailcodeValidationForm" onSubmit={handleSubmit(codeEmailVerficationAction)}>
                                            {/* <div className="FormGroup">
                                                <label htmlFor="LoginForgotPassEmailIdInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >Enter your Email  <span className="font_14 text-danger">*</span></label>
                                                <input type="email" className="form-control FormInput" id="LoginForgotPassEmailIdInput" disabled />
                                                <small id="LoginForgotPassEmailIdInputErr" className="font_12 text-danger invisible">Code Error</small>
                                            </div> */}

                                            <div className="FormGroup mb-2">
                                                <label htmlFor="LoginForgotPassEmailIdCodeInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >Enter your Code  <span className="font_14 text-danger">*</span></label>
                                                <input type="text" className="form-control FormInput" id="LoginForgotPassEmailIdCodeInput" 
                                                {...register("codeForEmail", {
                                                    required: "This field is required",
                                                    pattern: {
                                                      value: /^[0-9]{6}$/,
                                                      message:
                                                        "Please enter a valid code",
                                                    },
                                                    // minLength: {
                                                    //   value: 6,
                                                    //   message: "Please enter min 6 numbers ",
                                                    // },
                                                    // maxLength: {
                                                    //   value: 6,
                                                    //   message: "Please enter max 6 numbers ",
                                                    // },
                                                  })}
                                                  onKeyUp={(e) => {
                                                    trigger("codeForEmail");
                                                    // setmobileNumberError(false)
                                                    // checkMobileNumberValid(e.target.value)
                                                  }}
                                                />
                                                {errors.codeForEmail && (
                                                    <small className='text-danger font-bold'>
                                                        {errors.codeForEmail.message}
                                                    </small>
                                                )}
                                                <small id="LoginForgotPassEmailIdCodeInputErr" className="font_12 text-danger ">{signInCodeEmailerror}</small>
                                            </div>

                                            {/* <div className="FormGroup">
                                                <button type="button"  className="btn DarkBlue2_Color font_14 font_weight_400 px-0" onClick={()=>resendtheCodetoMobileorEmail()}>Check here to resend the code.</button>
                                                
                                            </div> */}
                                            <small id="LoginForgotPassEmailIdCodeInputErr" className="font_12 text-success ">{resendmessagesuccess}</small>
                                            <div className="BtnGroup d-grid LineHeight22 ">
                                                {/* <Link to="/forgotpassword"  className="btn btn-primary BlugGradBtn font_18 font_weight_600" id="VerifyCodeForEmailIdBtn"> Continue</Link> */}
                                                <button type="submit" className="btn btn-primary BlugGradBtn font_18 font_weight_600" id="VerifyCodeForEmailIdBtn"  >Continue</button>
                                            </div>

                                            <div className="BtnGroup text-center pt-3 font_18">
                                                <label className="DarkBlue2_Color">Back to  </label> <Link to="/" className="DarkBlue2_Color font_weight_500">Login</Link>
                                            </div>
                                            <div className="py-5 my-3"></div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default CodeForEmailVerification;