import React from "react";
import Layout from "../../Components/Layout";
import { Link } from "react-router-dom";
import VideoTestImg1 from './../../Images/Test_Img_1.png';
const LiveInterview = () => {
    return (
        <>
            <Layout>
                <div className="row mx-0">
                    <div className="col-12 my-2">
                        <div className="rounded-5 DarkBlue3_BgColor py-3">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    {/* <Link to="/" className="text-decoration-none"> */}
                                    <h2 className="font_40 font_weight_800 LineHeight29 mb-0 py-3 ps-5"><span className="text-light">Talent </span> <span className="LightGreen_Color">SCAN</span></h2>
                                    {/* </Link> */}
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-md-end text-sm-end text-start">
                                    <h2 className="font_40 font_weight_800 LineHeight29 mb-0 py-3 pe-5"><span className="text-light">Live </span><span className="LightGreen_Color">Interview</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 mx-auto mt-5 pt-5">
                        <form className="LiveInterviewForm" id="LiveInterviewForm" 
                        // onSubmit={handleSubmit()}
                        >
                            <div className="row">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="card border-0">
                                        <div className="card-body">
                                            <div className="VideoContainer">
                                                <img src={VideoTestImg1} alt="VideoTestImg1" className="TestImg img-fluid" id="VideoTestImg1" />
                                            </div>
                                            <div className="BtnGroup text-center mt-5">
                                                <Link to='/questiontype'className="btn btn-primary DarkBlue3_BgColor MyBtnSize" title='start timer'>Next Question</Link>
                                                {/* <button type="button" className="btn btn-primary DarkBlue3_BgColor MyBtnSize" title='start timer'>Next Question</button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center">
                                    <div className="card border-0">
                                        <div className="card-body">
                                            <div className="TimerContainer">
                                                <CountdownCircleTimer
                                                    isPlaying
                                                    duration={59}
                                                    colors={['#22B61F']}
                                                    colorsTime={[59]}
                                                >
                                                    {RenderTime}
                                                </CountdownCircleTimer>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="card border-0">
                                        <div className="card-body">
                                            <div className="QuestionContainer LightGrey10_BgColor p-5 rounded-4">
                                                <h4 className="QuestionIs text-white DarkBlue4_BgColor ps-3 py-2 mb-4 rounded">
                                                    1.  How is the weather in Kolkata now?
                                                </h4>

                                                <div className=" font_weight_500 font_22 bg-white rounded AnswerOption py-2 mb-2 ps-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="QuestionRadios" id="CloudlyRadioOption"  />
                                                        <label className="form-check-label" htmlFor="CloudlyRadioOption">Cloudly</label>
                                                    </div>
                                                </div>

                                                <div className=" font_weight_500 font_22 bg-white rounded AnswerOption py-2 mb-2 ps-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="QuestionRadios" id="RainyRadioOption" />
                                                        <label className="form-check-label" htmlFor="RainyRadioOption">Rainy</label>
                                                    </div>
                                                </div>

                                                <div className=" font_weight_500 font_22 bg-white rounded AnswerOption py-2 mb-2 ps-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="QuestionRadios" id="SunnyRadioOption" />
                                                        <label className="form-check-label" htmlFor="SunnyRadioOption">Sunny</label>
                                                    </div>
                                                </div>

                                                <div className=" font_weight_500 font_22 bg-white rounded AnswerOption py-2 mb-2 ps-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="QuestionRadios" id="PortlyCloudlyRadioOption" />
                                                        <label className="form-check-label" htmlFor="PortlyCloudlyRadioOption">Portly Cloudly</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default LiveInterview;