import React, { useState, useEffect } from "react";
import Layout from "../../Components/Layout";
import LoginBg from './../../Images/LoginBg.webp';
import { Link, useNavigate } from "react-router-dom";


import { useForm } from "react-hook-form";
import axios from "axios";
import talentscanlogo from './../../Images/talent-scan-logo-final.png';
const ForgotPassword = () => {
    const navigate = useNavigate();
    const [signUpValidationErrors, setSignUpValidationErrors] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {register, handleSubmit, formState : {errors}, trigger, setValue, getValues} = useForm({});
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    let obj = {};
    for(let pair of params.entries()) {
        obj[pair[0]] =pair[1];
    }

    const id =  obj["id"] ? obj["id"] : 0;
    const changePasswordAction = (data) => {
        if(data.ChangePassword != data.ChangeConfirmPassword){
            seterrorforPasswordmatch("Your password not match with above one")
            return false
        }
        let req = {
            password: data.ChangePassword,
            password2: data.ChangeConfirmPassword
        }

        axios.put(`apiV1/change-passwordv2/${id}/`, req).then(
            (rep) => {
                setIsSubmitting(false)
                navigate("/changedpasswordsuccess");
            }
        ).catch((e) => {
            if(e.response.data.errors != undefined) {
                setSignUpValidationErrors(e.response.data.errors)
            }
            if(e.response.data.password){
                setSignUpValidationErrors(e.response.data.password[0])
            }
        })
    }
    const [errorforPasswordmatch, seterrorforPasswordmatch] = useState("")
    const checkpasswordtwo = (value) => {
       let pasword =  getValues("ChangePassword");
        if(value.length >= pasword.length - 1){
            if(getValues("ChangePassword") == value){
                seterrorforPasswordmatch("")
                
            }else{
                seterrorforPasswordmatch("Your password not match with above one")
             
            }
        }
    }
    return (
        <>
            <Layout>
                <div className="row mx-0">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-sm-12 col-12 mx-auto pt-5">
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="row shadow rounded-1">
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-4 col-12  LightGrey_BgColor">
                                        <div className="LoginBg d-flex justify-content-center align-items-center">
                                            <div className="d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none px-5">
                                                <img src={LoginBg} alt="forgotPasswordBg" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-8 col-12 px-5 pt-4">
                                        <div className="text-center pt-5 pb-4 mt-5">
                                        <img src={talentscanlogo} />
                                        </div>
                                        {/* <div className="text-center">
                                            <h3 className="font_24 font_weight_600 DarkBlue_Color">Code for Email Verification</h3>
                                        </div>
                                        <div className="text-center pt-3">
                                            <p className="font_18 font_weight_400 DarkBlue2_Color">Please enter Generated OTP in below</p>
                                        </div> */}
                                        <form id="ForgotPasswordValidateForm" className="ForgotPasswordValidateForm" onSubmit={handleSubmit(changePasswordAction)}>
                                            <div className="FormGroup">
                                                <label htmlFor="LoginForgotPasswordInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >Enter Password <small className="text-danger">*</small></label>
                                                <input type="password" className="form-control FormInput" id="LoginForgotPasswordInput" name="password" placeholder="Password" 
                                                {...register("ChangePassword", {
                                                    required: "This field is required",
                                                    pattern: {
                                                      value:
                                                        /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                      //  (?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])at least one special chareacter
                                                      message:
                                                        "Password should contain at least one digit & one lower case & one upper case & length will be minimum 8 characters ",
                                                    },
                                                  })}
                                                  onKeyUp={() => {
                                                    trigger("ChangePassword");
                                                    setSignUpValidationErrors("")
                                                   
                                                  }}
                                                />
                                                {errors.ChangePassword && (
                                                    <small className='text-danger font-bold'>
                                                        {errors.ChangePassword.message}
                                                    </small>
                                                )}
                                                {/* <small id="LoginForgotPasswordInputErr" className="font_12 text-danger invisible">Password Error</small> */}
                                            </div>

                                            <div className="FormGroup mb-2">
                                                <label htmlFor="LoginForgotConfirmPasswordInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >Enter Confirm Password <small className="text-danger">*</small></label>
                                                <input type="password" className="form-control FormInput" id="LoginForgotConfirmPasswordInput" name="password" placeholder="Password" 
                                                {...register("ChangeConfirmPassword", {
                                                    required: "This field is required",
                                                    pattern: {
                                                      value:
                                                        /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                      //  (?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])at least one special chareacter
                                                      message:
                                                        "Password should contain at least one digit & one lower case & one upper case & length will be minimum 8 characters ",
                                                    },
                                                  })}
                                                  onKeyUp={(e) => {
                                                    trigger("ChangeConfirmPassword");
                                                    setSignUpValidationErrors("")
                                                    checkpasswordtwo(e.target.value)
                                                  }}
                                                />
                                                {errors.ChangeConfirmPassword && (
                                                    <small className='text-danger font-bold'>
                                                        {errors.ChangeConfirmPassword.message}
                                                    </small>
                                                )}
                                                <small className='text-danger font-bold'>{signUpValidationErrors}</small>
                                                <small id="SignUpConfirmPasswordInputErr" className="font_12 text-danger">{errorforPasswordmatch}</small>
                                                {/* <small id="LoginForgotConfirmPasswordInputErr" className="font_12 text-danger invisible">Confirm Password Error</small> */}
                                            </div>

                                            <div className="BtnGroup d-grid LineHeight22 ">
                                                {/* <Link to="/changedpasswordsuccess"  className="btn btn-primary BlugGradBtn font_18 font_weight_600" id="ChangePasswordBtn"> Change Password</Link> */}
                                                <button type="submit" className="btn btn-primary BlugGradBtn font_18 font_weight_600" id="ChangePasswordBtn"  >Change Password</button>
                                            </div>

                                            <div className="BtnGroup text-center pt-3 font_18">
                                                <label className="DarkBlue2_Color">Back to  </label> 
                                                <Link to="/" className="DarkBlue2_Color font_weight_500 text-decoration-none"> Sign In</Link>
                                            </div>
                                            <div className="py-5 my-3"></div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ForgotPassword;