import React, { useState, useEffect } from "react";
import Layout from "../../Components/Layout";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import DemoSiteNavTabs from "../../Components/DemoSiteNavTabs";

import UploadFilesIcon from './../../Images/uploadFilesIcon.png';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { RESUMEPARSINGERRS  } from '../../Redux/actions';

const ResumeParsingUploads = (props) => {
    const resumeparsingerror = useSelector((state) => state.resumeparsingerror);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [validationErrors, setValidationErrors] = useState();
    const [uploadFileerror, setUploadFileerror] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [linkGenShowModal2, setLinkGenShowModal2] = useState(false);
    const { register, handleSubmit, formState: { errors }, trigger, setValue, getValues, setError } = useForm({});
    const [employeeRequirement, setemployeeRequirement] = useState([])
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);

    useEffect(() => {
        if (sessionStorage.getItem('Admintoken') != "" && sessionStorage.getItem('Admintoken') != null) {
            // navigate("/dashboard");
            console.log("token empty")
        }
        console.log(sessionStorage.getItem('Admintoken'))
    }, [])
    const [resumeparse, setresumeparse ]  = useState([])
    const handleUploadResumesAction = (data) => {
        dispatch(RESUMEPARSINGERRS([]));
        setValidationErrors("")
        setIsSubmitting(true);
        setIsBtnDisabled(true);
        let token = sessionStorage.getItem('Admintoken');
        const formData = new FormData();
        for (let i = 0 ; i < employeeRequirement.length ; i++) {
            formData.append("resume", employeeRequirement[i]);
        }
       
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
        }
        console.log(formData)
        axios.post('/apiV1/parse-resume/', formData, config).then((res) => {
            if(res.data){
                setIsSubmitting(false);
                if(res.data.results.length !== 0){
                    dispatch(RESUMEPARSINGERRS(res.data.erros));
                    navigate(`/signleresumeview?id=${res.data.results[0].uploaded_by}`)
                }else{
                    setresumeparse(res.data.erros)
                }
                
            }
                
            }
        ).catch((e) => {
            setIsSubmitting(false);
            setIsBtnDisabled(false);
            if (e.response.data.errors != undefined) {
                setValidationErrors(e.response.data.errors);
            }
            if (e.response.data.error != undefined) {
                setValidationErrors(e.response.data.error);
            }
            if(e.response.data.detail){
                setUploadFileerror(e.response.data.detail)
            }
            setValidationErrors("Please select valid file");
        });
        // var a  = getValues("employee_file")
        // alert(a,'formData')
        // console.log(a);
        // axios.post("http://resume.vitelglobal.com:84/apiV1/parse-resume/", a)
        //   .then((response) => {
        //     // handle the response
        //     console.log(response,'response');
        //   })
        //   .catch((error) => {
        //     // handle errors
        //     alert('')
        //     console.log(error);
        //   });
        // console.log("Test")
        // console.log()
    }

    const handleRemoveFile = (index) => {
        const newFiles = [...employeeRequirement];
        newFiles.splice(index, 1);
        setemployeeRequirement(newFiles);
    };

    const resumeparsinguploadfiles = (e) => {
        let totalresume = Array.from(e.target.files)
        let count = 0
        totalresume.map((item)=>{
            const name = item.name;
            const lastDot = name.lastIndexOf('.');
            const ext = name.substring(lastDot + 1);
            if (ext === "pdf" || ext === "doc" || ext === "docx") {
            } else {
                count += count + 1
            }
        })
        if(count == 0){
            if(totalresume.length < 100){
                setemployeeRequirement(Array.from(e.target.files));
                setError('employee_file', { type: 'custom', message: "" }); 
                setValidationErrors("")
            }else{
                setLinkGenShowModal2(true)
                // alert('Your not allow more then 50 resumes')
                setemployeeRequirement([]);
                setError('employee_file', { type: 'custom', message: "" }); 
                setValidationErrors("")
                setValue('employee_file', '')
            }
        }else{
            setemployeeRequirement([]);
            setError('employee_file', { type: 'custom', message: "Please select valid file" }); 
            setValidationErrors("")
            setValue('employee_file', '')
        }
        
    }
    return (
        <>
            <Layout>
                <div className="row mx-0">
                    <div className="col-12">
                        {/* <h1>Resume Parsing Upload</h1> */}
                        <div>
                            <h2 className="font_44 text-center pt-5 pb-4"> <span className=" DarkBlue4_Color">Upload up to</span> <span className="LightGreen_Color font_weight_700">100 resumes</span></h2>
                        </div>
                        <div>
                            {/* <div className="font_20 font_weight_300 text-center mt-0 text-muted">Use it or lose it!! For Security purposes, demo Sessions are only available for review for 30 days.</div> */}
                        </div>
                        
                    </div>

                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-10 col-12 mx-auto">
                        <form id="UploadResumesForm" className="UploadResumesForm" onSubmit={handleSubmit(handleUploadResumesAction)}>
                            <div className="LightGrey4_BgColor">
                                <div className="FormGroup text-center position-relative">
                                    <div className="FileUploadContainer1">
                                        <label htmlFor="uploadPdfFiles" className="DarkBlue_Color font_weight_500 FileUploadLable1 d-block">
                                            <input type="file" id="uploadPdfFiles" multiple disabled={isBtnDisabled}
                                                {...register("employee_file", { required: "Please select  resume..!", })}
                                                accept=".doc, .docx, .pdf"
                                                onChange={(e)=>{resumeparsinguploadfiles(e)}}
                                            />
                                            <div className="ImgUploadIcon"><img src={UploadFilesIcon} alt="upload Img" className="Upload_Image_Icon" /></div>
                                            <div className="font_20 font_weight_400"><i>Upload</i></div>
                                            <div className="font_12 font_weight_300 text-dark">
                                                <small>Drop PDF or Word files here or click to upload</small>
                                                 
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                {errors.employee_file && (<small className='text-danger font-bold'>{errors.employee_file.message}  </small>)}
                                {employeeRequirement.length !== 0 ? 
                                employeeRequirement.map((files, i)=>{
                                    return <div>{files.name} <span className="text-danger " role="button" onClick={()=>handleRemoveFile(i, files)} > X </span> </div>
                                }) : ""}
                                <small className="text-danger">{validationErrors}</small>
                            </div>

                            {/* <div className="TextContainer mt-2">
                                <p className="font_20 font_weight_300 text-center mb-1"><i>Our Demo is in complience with European Union Privacy regulations, including the GDPR.</i></p>
                                <p className="font_20 font_weight_300 text-center"><i>All documents uploaded during this demo will be retained for 30 days for quality assurance purposes.</i></p>
                            </div> */}
                            {isBtnDisabled ? <div className='text-center'>
                        <div className='spinner-border ' role='status'>
                          <span className='sr-only'></span>
                        </div>
                      </div> :""}
                            <div className="BtnGroup text-center pt-4 mb-5">
                                {/* <Link to='/signleresumeview' className="btn btn-primary DarkBlue3_BgColor font_20 font_weight_500">View Parse Results</Link> */}
                                <button type="submit" className="btn btn-primary DarkBlue3_BgColor font_20 font_weight_500" disabled={isBtnDisabled}>View Parse Results</button>
                                {/* <button type="submit" className="btn btn-primary DarkBlue3_BgColor font_20 font_weight_500">View Parse Results</button> */}
                            </div>
                        </form>

                        <ul>
                            {resumeparse.map((item,i)=>{
                                return (
                                    <li className="text-danger">{item}</li>
                                )
                            })}
                            
                        </ul>
                    </div>
                </div>
            </Layout>
            <Modal size="md" show={linkGenShowModal2} onHide={()=>setLinkGenShowModal2(false)} backdrop="static"  keyboard={false}>
                <Modal.Header>
                    <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Upload</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className='text-center'>Your not allow more then 100 resumes</h4>
                    <div className='text-center m-3'>
                        <button className='btn btn-primary DarkBlue3_BgColor font_20 font_weight_500' id="ScoreBoard_Assign_UpdateOkayBtn"
                        onClick={ ()=>setLinkGenShowModal2(false) }>   OK
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ResumeParsingUploads;