import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import axios from "axios";
import Layout from "../Components/Layout";
import { Link  } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useSelector, useDispatch } from 'react-redux';
import { PAGESIZES, PAGESEARCHS, PAGENUMBERS, startdates, endates } from './../Redux/actions';
const DashboardTest = () => {
    const dispatch = useDispatch();
    const selectedpages = useSelector((state) => state.pagesize);
    const searchvalue = useSelector((state) => state.pagesearch);
    const startdate = useSelector((state) => state.startdate);
    const enddate = useSelector((state) => state.endate);
    const pagenumner = useSelector((state) => state.pagenumner);
    const { register, handleSubmit, trigger, getValues, setValue } = useForm({ mode: "all" });
    const [maxdate, setmaxdate] = useState(moment().format('YYYY-MM-DD'))
    document.title = "Talent Scan - Dashboard"
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const columns = [
    //    {
    //     name: <label className="DarkBlue4_Color font_weight_500 font_14">S. No</label>, 
    //     sortable: true,
    //     selector: row => row.Num,
    //     cell: row => 
    //     <>
    //         <div className="DarkBlue4_Color font_weight_300 font_14 align-middle align-middle">
    //             <span>{row.Num}</span>
    //         </div>
    //     </>,    
    //     width:"5%"     
    //    },
       { name: <label className="DarkBlue4_Color font_weight_500 font_14">Create Date</label>, sortactive:true, sortable: true,   selector: row => row.created_at, cell: row => 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_14 align-middle align-middle">
                    <span>{moment(row.created_at).format("DD-MM-YYYY")}</span>
                </div>
            </>,  
            width:"10%"           
        },
        
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Test Name</label>, sortable: true,   selector: row => row.test_name, cell: row => 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_14 align-middle align-middle">
                    <span>{row.test_name}</span>
                </div>
            </>,   
            width:"13%"             
        },
        
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Job Description</label>,  sortable: true,    selector: row => row.test_designation, cell: row => 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_14 align-middle align-middle">
                    <span>{row.test_designation}</span>
                </div>
            </>, 
             width:"12%"            
        },
        {
        name: <label className="DarkBlue4_Color font_weight_500 font_14">Test Attempted/ Total Test</label>, 
        sortable: true,
        selector: row => row.attempted_count,
        cell: row => 
        <>
            <div className="DarkBlue4_Color font_weight_300 font_14 align-middle align-middle">
                <span>{row.attempted_count + "/" +row.total_emp_count}</span>
            </div>
        </>,    
        width:"10%"     
       },
       {
        name: <label className="DarkBlue4_Color font_weight_500 font_14">Test Criticality</label>, 
        sortable: true,
        selector: row => row.criticality == 'easy' ? "Beginner" : row.criticality == 'medium' ? "Intermediate" : row.criticality == 'hard' ? "Advanced" : "",
        width:"10%"     
       },
        
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Test skills</label>, sortable: false,   selector:row=>row.id, cell: row=> 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                    
                    {row.test_skills.map((skill,i)=>{return  <small key={i} className="badge bg-info  mx-1">{skill}</small>  })}
                </div>
            </>,
             width:"20%"    
        },
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">AI Tests Assigned</label>, sortable: false,  selector:row=>row.id, cell: row=> 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_14 align-middle">
                    {row.categories.map((item, i)=>{return item.section__name === "Profiling" ? <small className="badge bg-dark mx-1 " key={i} >{item.section__name}</small> : item.section__name === "Coding" ? <small className="badge bg-warning  mx-1 " key={i} >{item.section__name}</small> : item.section__name === "English" ? <small className="badge bg-primary  mx-1" key={i} >{item.section__name}</small> : item.section__name === "Behavioural" ? <small className="badge bg-secondary  mx-1" key={i} >{item.section__name}</small> : item.section__name === "Technical" ? <small className="badge bg-success  mx-1" key={i} >{item.section__name}</small> : ''})}
                    {/* <span className="btn LightBlue2_BgColor text-white btn-sm font_12">{row.experience  ? row.experience : 0 }</span> */}
                </div>
            </>,
             width:"15%"    
        },
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Action</label>, sortable: false,   selector:row=>row.id , cell: row=> 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_14 align-middle">
                    <Link className="btn btn-primary DarkBlue3_BgColor " to={`/attemptedtestlist2?id=${row.id}`} >View Result</Link>
                </div>
            </>
        },
        
    ];
    const handlePageChange = page => {
        GetTestDashboardList(page);
        setCurrentPage(page);
        dispatch(PAGENUMBERS(page));
        
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        dispatch(PAGESIZES(newPerPage));
        GetTestDashboardList(page, newPerPage);
        setPerPage(newPerPage);
    };
    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const paginationComponentOptions = {

        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const GetTestDashboardList = async (page1, size = perPage, url) => {
        setLoader(true)
        let token = sessionStorage.getItem("Admintoken");
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
        }
        let offcet = parseInt(page1)-1 
        let urls = `apiV1/test-dashboard/?limit=${size}&offset=${offcet*size}${getValues('searchValue') && `&search=${getValues('searchValue')}`}${getValues('startdate') && `&from_date=${getValues('startdate')}`}${getValues('enddate') && `&to_date=${getValues('enddate')}`}`
        if(url){
            urls = url
        }
        axios.get(urls,config).then((res) => {
            if(res.data) {
                res.data.results.map((item, i)=>{
                    item.categories.map((cat, i)=>{
                        item.criticality = cat.criticality
                    })
                    item.Num = i + 1
                    return item
                })
                setTotalRows(res.data.count)
                setLoader(false)
                setData(res.data.results)
                setPerPage(size)
                setCurrentPage(page1)

            }
        }).catch((e) => {
            setLoader(false)
        })
    }
    const [SearchTrue, setSearchTrue] = useState(false)
    const [searchErrorMsg, setSearchErrorMsg] = useState('');
    
    const haveValuesInserch = (data) => {
        if (data) {
          setSearchTrue(true)
        } else {
          setSearchTrue(false)
        }
    }
    const onSubmits = async (data) => {
        dispatch(PAGESEARCHS(getValues('searchValue')));
        let offcet = parseInt(currentPage)-1 
        let url = `apiV1/test-dashboard/?limit=${perPage}&offset=${offcet}&search=${data.searchValue}${getValues('startdate') && `&from_date=${getValues('startdate')}`}${getValues('enddate') && `&to_date=${getValues('enddate')}`}`
        GetTestDashboardList(currentPage, perPage, url)
    }

    const onSubmits2 = async (data) => {
        dispatch(PAGESEARCHS(data));
        setLoader(true)
        let offcet = parseInt(currentPage)-1 
        let url = `apiV1/test-dashboard/?limit=${perPage}&offset=${offcet}&search=${data}${getValues('startdate') && `&from_date=${getValues('startdate')}`}${getValues('enddate') && `&to_date=${getValues('enddate')}`}`
        GetTestDashboardList(currentPage, perPage, url)
    }
    const searchwithdates = (enddate) => {
        if(getValues('startdate') && enddate){
            dispatch(startdates(getValues('startdate')));
            dispatch(endates(enddate));
            let offcet = parseInt(currentPage)-1 
            let url = `apiV1/test-dashboard/?limit=${perPage}&offset=${offcet}${getValues('searchValue') && `&search=${getValues('searchValue')}`}${getValues('startdate') && `&from_date=${getValues('startdate')}`}${enddate && `&to_date=${enddate}`}`
            GetTestDashboardList(currentPage, perPage, url)
        }
    }
    const resetfilters = () =>{
        setValue('startdate', '')
        setValue('enddate', '')
        setValue('searchValue', '')
        dispatch(startdates(''));
        dispatch(PAGESEARCHS(''));
        dispatch(endates(''));
        setSearchTrue(false);
        let offcet = parseInt(currentPage)-1
        let url = `apiV1/test-dashboard/?limit=${perPage}&offset=${offcet}`
        GetTestDashboardList(currentPage, perPage, url)
    }
    useEffect(()=>{
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search.slice(1));
        
        let obj = {};
        for(let pair of params.entries()) {
            obj[pair[0]] =pair[1];
        }
        let selectedpage  =  selectedpages ? selectedpages : 10
        let pagenumner2 = pagenumner ? pagenumner : 1
        if(searchvalue){
            setValue("searchValue", searchvalue)
        }

        if(startdate){
            setValue("startdate", startdate)
        }

        if(enddate){
            setValue("enddate", enddate)
        }
        GetTestDashboardList(pagenumner2, selectedpage);

    }, [])
    return (
        <>
            <Layout>
            <div className="row mx-5">
                <div className="col-md-12">
                    <div className="row">
                    <div className="col-md-6">
                        <div className="pt-3 pb-3" style={{fontSize:"30px"}}>
                            Dashboard
                        </div>
                    </div>
                    <div className="col-md-6 text-end">
                        <form className='AddNewDept_form d-inline-block pt-3 pb-3' id='AddNewDept_form' name='AddNewDept_form' onSubmit={handleSubmit(onSubmits)}>
                           
                            <div className="SearchBoxContainer ms-3 d-flex " style={{ height:"40px" }}>
                                <input {...register("startdate")} type="date" autoComplete="off" className="form-control border ms-3" max={maxdate} placeholder="Search" aria-label="Search" aria-describedby="SearchIconBtn"  />
                                <input {...register("enddate")} type="date" autoComplete="off" className="form-control border  mx-3 " max={maxdate}  placeholder="Search" aria-label="Search" aria-describedby="SearchIconBtn"  onChange={(e)=>searchwithdates(e.target.value)} />
                                <div className="input-group mb-3 SearchBoxContent">
                                    <input {...register("searchValue")} type="text" autoComplete="off" className="form-control border SearchBoxOne" placeholder="Search" aria-label="Search" aria-describedby="SearchIconBtn" onKeyUp={(e) => { onSubmits2(e.target.value); trigger("searchValue"); setSearchErrorMsg(''); haveValuesInserch(e.target.value) }} />
                                    <button type="button" className={SearchTrue ? "d-none SearchCloseBtn " : "btn SearchCloseBtn d-none"} id="SearchCloseBtn" onClick={() => { setValue('searchValue', ''); setSearchErrorMsg(''); dispatch(PAGESEARCHS(""));  setSearchTrue(false);GetTestDashboardList(1); }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16"><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/></svg></button>
                                    <button className="d-none border" type="submit" id="SearchIconBtn" ><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/></svg></button>
                                </div>
                                <div className="ms-3">
                                    <button type="button" className="btn btn-primary DarkBlue3_BgColor " onClick={()=>resetfilters()}  >Reset</button>
                                </div>
                            <small className='text-danger font-bold'>{searchErrorMsg}</small>
                            </div>
                        </form>
                    </div>
                    </div>
                {!loader ? 
                <DataTable 
                    columns={columns} 
                    data={ data} 
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    paginationServer
                    fixedHeaderScrollHeight='600px'
                    fixedHeader
                    persistTableHead
                    paginationTotalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationPerPage={perPage}
                     
                />: 
                <div className='text-center'>
                    <div className='spinner-border ' role='status'>
                        <span className='sr-only'></span>
                    </div>
                </div>
            }

                </div>

            </div>
            </Layout>
            
        </>
    )
}

export default DashboardTest