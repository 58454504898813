import React from "react";
import { FaBars, FaPlay } from "react-icons/fa";

const TopBar = () => {
    return (
        <>
            <div className="row mx-4 py-3">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 ">
                    <div className="d-flex align-items-center">
                        <div className="DarkBlue5_Color NavbarControl pe-2 font_18">
                            <FaBars />
                        </div>
                        <h3 className="DarkBlue5_Color font_36 font_weight_600">Test configuration</h3>
                    </div>
                    
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="text-end">
                        <button className="LightGreen_BgColor text-white border-0 font_22 px-4 py-2 rounded-pill">
                            <div className="d-flex align-items-center">
                                <FaPlay />
                                <span className="ps-3">Activation Test</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopBar;