import React, { useState, useEffect } from "react";
import Layout from "../../Components/Layout";
import moment from "moment/moment";
import Accordion_Icon_1 from './../../Images/Icon_1.png';
import Accordion_Icon_2 from './../../Images/Icon_2.png';
import Accordion_Icon_3 from './../../Images/Icon_3.png';
import Accordion_Icon_4 from './../../Images/Icon_4.png';
import Accordion_Icon_5 from './../../Images/Icon_5.png';
import Accordion_Icon_6 from './../../Images/Icon_6.png';
import Accordion_Icon_7 from './../../Images/Icon_7.png';
import PropTypes from "prop-types";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Typography } from "@mui/material";
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
     
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}


const SignleResumeView2 = ({data, backpage, completedata}) => {
    // console.log(`my data might be ${data}`)
    // const navigate = useNavigate();

    const [parseHistoryData, setParseHistoryData] = useState({});
    const [parseHistoryDatacertifications, setParseHistoryDatacertifications] = useState();
    const [parseHistoryDataskills, setParseHistoryDataskills] = useState([]);
    const [parseHistoryDataEmpHistory, setParseHistoryDataEmpHistory] = useState([]);
    const [parseHistoryDataEduHistory, setParseHistoryDataEduHistory] = useState([]);
    const [value, setValue] = useState(0);

    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    let obj = {};
    for (let pair of params.entries()) {
        obj[pair[0]] = pair[1]
    }
    const [id, setId] = useState(obj["id"] ? obj["id"] : 0)
    // console.log(`Id is here + ${obj['id']}`)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const movetoBackPage = () => {
        backpage(true)
    }
    // const GetResumeData = async () => {
    //     let token = sessionStorage.getItem('Admintoken');
    //     const config = {
    //         headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
    //     }
    //     // console.log("data is loading")

    //     axios.get(`apiV1/upload-detail/${id}/`,config).then((res) => {
    //         const EmpHistResult = [];
    //         const EduHistResult = [];
    //         if(res && res.data) {
    //             console.log("my axios get accessed1")
    //             console.log(res.data.results[0])
    //             setParseHistoryData(res.data.results[0])

    //             if(res.data.results[0].employment_history.length !== 0){
    //                 res.data.results[0].employment_history.map((item, i) => {
    //                     EmpHistResult.push(item)
    //                 })
    //                 setParseHistoryDataEmpHistory(EmpHistResult)
    //             }
    //             if(res.data.results[0].education_history.length !== 0) {
    //                 res.data.results[0].education_history.map((item, i) => {
    //                     EduHistResult.push(item)
    //                 })
    //                 setParseHistoryDataEduHistory(EduHistResult)
    //                 // setParseHistoryDataEduHistory(res.data.results[0].education_history[0])
    //                 console.log(EduHistResult)
    //             }
                
    //             console.log("my axios get accessed2")
    //         } 
    //             // console.log(res.data.results[0].employment_history[0].employer_name,"hdbvbyfvbfbvfdbb")
    //             // console.log(parseHistoryData.employment_history[0].id)
    //         }
    //     ).catch((e) => {
    //         setIsSubmitting(false);
    //         if (e.response.data.errors !== undefined) {
    //             setValidationErrors(e.response.data.errors);
    //             console.log("errors response");
    //         }
    //         if (e.response.data.error !== undefined) {
    //             setValidationErrors(e.response.data.error);
    //             console.log("error response");
    //         }
    //         if(e.response.data.detail){
    //             setUploadFileerror(e.response.data.detail)
    //             console.log("error details");
    //         }
    //     });
    // }
    useEffect(() => {
        const EmpHistResult = [];
        const EduHistResult = [];
        setParseHistoryData(data.contact_info)
        setParseHistoryDatacertifications(data.extras.certifications)
        setParseHistoryDataskills(data.skills)

        for (const [key, value] of Object.entries(data.employment_history)) {
            console.log(data.employment_history[key])
            EmpHistResult.push(data.employment_history[key])
        }
        for (const [key, value] of Object.entries(data.education_history)) {
            EduHistResult.push(data.education_history[key])
        }
        setTimeout(() => {
            setParseHistoryDataEmpHistory(EmpHistResult)
        setParseHistoryDataEduHistory(EduHistResult)
        }, 1000);
        
        // if(data.employment_history.length !== 0){
        //     data.employment_history.map((item, i) => {
        //         EmpHistResult.push(item)
        //     })
        //     setParseHistoryDataEmpHistory(EmpHistResult)
        // }
        // if(data.education_history.length !== 0) {
        //     data.education_history.map((item, i) => {
        //         EduHistResult.push(item)
        //     })
        //     setParseHistoryDataEduHistory(EduHistResult)
        // }

       console.log(completedata)
    },[data])
    return (
        <>
            <Layout>
                <div className="row LightGrey3_BgColor py-5 mx-0">
                    <div className="col-12">
                        <Box sx={{ width: '100%' }}>
                            <Box className="text-center">
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" id="SingleResumeViewTabs">
                                    <Tab label="Over View" {...a11yProps(0)} id="OverViewTab" className="rounded-pill me-3" />
                                   
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                              
                                <>
                                    <div className="row">
                                        <div className="col-12">
                                            
                                            <div className="FormGroup py-4">
                                                <h2 className="font_24 font_weight_500 DarkBlue4_Color text-center">{parseHistoryData.first_name ? parseHistoryData.first_name + " " : "" } {parseHistoryData.middle_name ? parseHistoryData.middle_name + " " : ""} {parseHistoryData.last_name ? parseHistoryData.last_name + " ": ""} </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-10 col-sm-12 col-12 mx-auto">
                                            <div className="accordion" id="OverViewSignleViewAccordion">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="CandidatenalysisAccordion">
                                                        <button className="accordion-button DarkBlue4_Color font_weight_400 font_20" type="button" data-bs-toggle="collapse" data-bs-target="#CollapseOverViewAccordion" aria-expanded="true" aria-controls="CollapseOverViewAccordion">
                                                            <img src={Accordion_Icon_1} alt="Icon 1" className="Accordion_Icon me-3" />
                                                            Talent Scan Candidate Analysis
                                                        </button>
                                                    </h2>
                                                    <div id="CollapseOverViewAccordion" className="accordion-collapse collapse show" aria-labelledby="CandidatenalysisAccordion" >
                                                        <div className="accordion-body">
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">First Name</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="  col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.first_name ? parseHistoryData.first_name : ""}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {parseHistoryData.middle_name ? 
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Middle Name</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="  col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.middle_name ? parseHistoryData.middle_name : "" }</label>
                                                                    </div>
                                                                </div>
                                                            </div> : ""}
                                                            {parseHistoryData.last_name ? 
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Last Name</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className=" col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.last_name ? parseHistoryData.last_name : ""  }</label>
                                                                    </div>
                                                                </div>
                                                            </div> : ""}
                                                            {parseHistoryData.city ? 
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">City</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className=" col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.city ? parseHistoryData.city : ""  }</label>
                                                                    </div>
                                                                </div>
                                                            </div> : ""}
                                                            {parseHistoryData.postal_code ? 
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Postal Code</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className=" col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.postal_code ? parseHistoryData.postal_code : ""  }</label>
                                                                    </div>
                                                                </div>
                                                            </div> : ""}
                                                            {parseHistoryData.country ?
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Country</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className=" col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.country ? parseHistoryData.country : ""  }</label>
                                                                    </div>
                                                                </div>
                                                            </div> : ""}
                                                            {parseHistoryData.total_experience ? 
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Total Experience</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="  col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.total_experience ? parseHistoryData.total_experience.join('.')  : ""  }</label>
                                                                    </div>
                                                                </div>
                                                            </div> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="ContactInfoAccordion">
                                                        <button className="accordion-button collapsed DarkBlue4_Color font_weight_400 font_20" type="button" data-bs-toggle="collapse" data-bs-target="#ContactInfoCollapseAccordion" aria-expanded="false" aria-controls="ContactInfoCollapseAccordion">
                                                            <img src={Accordion_Icon_2} alt="Icon 1" className="Accordion_Icon me-3" />
                                                            Contact Info
                                                        </button>
                                                    </h2>
                                                    <div id="ContactInfoCollapseAccordion" className="accordion-collapse collapse show" aria-labelledby="ContactInfoAccordion"  >
                                                        <div className="accordion-body">
                                                            {parseHistoryData.email ? 
                                                        <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Email</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="  col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue text-break">{parseHistoryData.email ? parseHistoryData.email : ""  }</label>
                                                                    </div>
                                                                </div>
                                                            </div> : ""}
                                                            {parseHistoryData.phone ? 
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Phone</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryData.phone ? parseHistoryData.phone : ""}</label>
                                                                    </div>
                                                                </div>
                                                            </div> : ""}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="ScoreEvaluationAccordion">
                                                        <button className="accordion-button collapsed DarkBlue4_Color font_weight_400 font_20" type="button" data-bs-toggle="collapse" data-bs-target="#ScoreEvaluationCollapseAccordion" aria-expanded="false" aria-controls="ScoreEvaluationCollapseAccordion">
                                                            <img src={Accordion_Icon_3} alt="Icon 1" className="Accordion_Icon me-3" />
                                                            Score Evaluation
                                                        </button>
                                                    </h2>
                                                    <div id="ScoreEvaluationCollapseAccordion" className="accordion-collapse collapse show" aria-labelledby="ScoreEvaluationAccordion" >
                                                        <div className="accordion-body">
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Experience Score</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="  col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{completedata.experience_score}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Designation Score</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="  col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{completedata.designation_score}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Entire Score</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="  col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{completedata.entire_score}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Skills Score</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="  col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{completedata.skills_score}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="accordion-item">
                                                    <h2 className="accordion-header" id="JobObjectiveAccordion">
                                                        <button className="accordion-button collapsed DarkBlue4_Color font_weight_400 font_20" type="button" data-bs-toggle="collapse" data-bs-target="#JobObjectiveCollapseAccordion" aria-expanded="false" aria-controls="JobObjectiveCollapseAccordion">
                                                            <img src={Accordion_Icon_3} alt="Icon 1" className="Accordion_Icon me-3" />
                                                            Job Objective
                                                        </button>
                                                    </h2>
                                                    <div id="JobObjectiveCollapseAccordion" className="accordion-collapse collapse" aria-labelledby="JobObjectiveAccordion" data-bs-parent="#OverViewSignleViewAccordion">
                                                        <div className="accordion-body">
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Job Objective</label>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="col-sm-8 col-xl-8 col-6">
                                                                        <label className="font_16 font_weight_600 AccordionValue"></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {parseHistoryDataEmpHistory.length !== 0 ? 
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="EmploymentHistoryAccordion">
                                                        <button className="accordion-button collapsed DarkBlue4_Color font_weight_400 font_20" type="button" data-bs-toggle="collapse" data-bs-target="#EmploymentHistoryCollapseAccordion" aria-expanded="false" aria-controls="EmploymentHistoryCollapseAccordion">
                                                            <img src={Accordion_Icon_4} alt="Icon 1" className="Accordion_Icon me-3" />
                                                            Employment History
                                                        </button>
                                                    </h2>
                                                    <div id="EmploymentHistoryCollapseAccordion" className="accordion-collapse collapse show" aria-labelledby="EmploymentHistoryAccordion" >
                                                        <div className="accordion-body">
                                                            <div className="table-responsive">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Employer Name</th>
                                                                            <th>Position</th>
                                                                            <th>Start Date</th>
                                                                            <th>End Date</th>
                                                                            
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            
                                                                            parseHistoryDataEmpHistory.map((item,index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{item.employer_name}</td>
                                                                                        <td>{item.position}</td>
                                                                                        <td>{item.start_date}</td>
                                                                                        <td>{item.end_date}</td>
                                                                                        {/* <td>{moment(item.start_date).format("DD-MM-YYYY") == "Invalid date" ? "" :moment(item.start_date).format("DD-MM-YYYY")  }</td> */}
                                                                                        {/* <td>{item.end_date == "current" ? "current" : moment(item.end_date).format("DD-MM-YYYY") ==  "Invalid date" ? "" : moment(item.end_date).format("DD-MM-YYYY")}</td> */}
                                                                                         
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div> : ""}
                                                {parseHistoryDataEduHistory.length !== 0 ? 
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="EducationHistoryAccordion">
                                                        <button className="accordion-button collapsed DarkBlue4_Color font_weight_400 font_20" type="button" data-bs-toggle="collapse" data-bs-target="#EducationHistoryCollapseAccordion" aria-expanded="false" aria-controls="EducationHistoryCollapseAccordion">
                                                            <img src={Accordion_Icon_5} alt="Icon 1" className="Accordion_Icon me-3" />
                                                            Education History
                                                        </button>
                                                    </h2>
                                                    <div id="EducationHistoryCollapseAccordion" className="accordion-collapse collapse show" aria-labelledby="EducationHistoryAccordion">
                                                        <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                            <div className="table-responsive">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>School Name</th>
                                                                            <th>Degree Name</th>
                                                                            <th>Passed Out</th>
                                                                            <th>Major Subjects</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            parseHistoryDataEduHistory.map((item,index) => {
                                                                                return(
                                                                                    <tr key={index}>
                                                                                        <td>{item.school_name}</td>
                                                                                        <td>{item.degree_name}</td>
                                                                                        <td>{item.last_education_date}</td>
                                                                                        <td>{item.major}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            
                                                        </div>

                                                       
                                                    </div>
                                                </div> : ""}
                                                {parseHistoryDataskills.length !== 0 ? 
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="SkillsAccordion">
                                                        <button className="accordion-button collapsed DarkBlue4_Color font_weight_400 font_20" type="button" data-bs-toggle="collapse" data-bs-target="#SkillsCollapseAccordion" aria-expanded="false" aria-controls="SkillsCollapseAccordion">
                                                            <img src={Accordion_Icon_6} alt="Icon 1" className="Accordion_Icon me-3" />
                                                            Skills
                                                        </button>
                                                    </h2>
                                                    <div id="SkillsCollapseAccordion" className="accordion-collapse collapse show" aria-labelledby="SkillsAccordion" >
                                                        <div className="accordion-body">
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Skills</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className="  col-xl-8 col-6 text-break">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryDataskills.map((skills,i)=>{ return skills +", "})}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : ""}
                                                {parseHistoryDatacertifications ? 
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="CertificationsAccordion">
                                                        <button className="accordion-button collapsed DarkBlue4_Color font_weight_400 font_20" type="button" data-bs-toggle="collapse" data-bs-target="#CertificationsCollapseAccordion" aria-expanded="false" aria-controls="CertificationsCollapseAccordion">
                                                            <img src={Accordion_Icon_7} alt="Icon 1" className="Accordion_Icon me-3" />
                                                            Certifications
                                                        </button>
                                                    </h2>
                                                    <div id="CertificationsCollapseAccordion" className="accordion-collapse collapse show" aria-labelledby="CertificationsAccordion" >
                                                        <div className="accordion-body">
                                                            <div className="AccordionBody ms-xxl-4 ms-xl-4 ms-lg-4 ms-md-0 ms-sm-0 ms-0 ps-3">
                                                                <div className="row">
                                                                    <div className="col-4 col-xl-3">
                                                                        <label className="font_16 font_weight_300  AccordionKey">Certifications</label>
                                                                    </div>
                                                                    <div className="col-2 col-xl-1">
                                                                        <strong> : </strong>
                                                                    </div>
                                                                    <div className=" col-xl-8 col-6 text-break">
                                                                        <label className="font_16 font_weight_600 AccordionValue">{parseHistoryDatacertifications}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : ""}
                                            </div>
                                            <div className="text-end">
                                                <button className="btn btn-primary DarkBlue3_BgColor MyBtnSize font_weight_500 font_20" onClick={()=>movetoBackPage()} >Back</button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </>
                               
                            </CustomTabPanel>
                        </Box>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default SignleResumeView2;