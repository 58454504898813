import React from 'react'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import 'codemirror/mode/xml/xml'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/python/python'
import 'codemirror/mode/sql/sql'

import 'codemirror/mode/css/css'
import { Controlled as ControlledEditor } from 'react-codemirror2'

export default function Editor(props) {
  const { language, displayName, value,  onChange } = props
  function handleChange(editor, data, value) {
    console.log(value)
    onChange(value)
  }

  return (
    <div className={`editor-container `}>
      <h4 className="editor-title">  {displayName} </h4>
      <ControlledEditor
        onBeforeChange={handleChange}
        value={value}
        className="code-mirror-wrapper"
        options={{
          lineWrapping: true,
          lint: true,
          mode: language,
          theme: 'material',
          //lineNumbers: true
        }}
      />
    </div>
  )
}