import React from "react";

const QuestionsManager = () => {
    return (
        <>
            <h1>QuestionsManager</h1>
        </>
    )
}

export default QuestionsManager;