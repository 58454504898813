import React from "react";

import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        sessionStorage.setItem('Admintoken', "");
        navigate("/");
    }
    return (
        <>
            <button type="button" className="btn btn-default" onClick={handleLogout}>Logout</button>
        </>
    )
}

export default Logout;