import React from "react";
import Layout from "../../Components/Layout";
import { Link } from "react-router-dom";
 
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
const renderTime = ({ remainingTime }) => {
    
    if (remainingTime === 0) {
        return <div className="timer">Time Out...</div>
    }
  
    return (
      <div className="timer text-center">
            <div className="text">Remaining</div>
            <div className="value">{remainingTime}</div>
            <div className="text">seconds</div>
      </div>
    );
};

const QuestionTiming = () => {
    
    return (
        <>
            <Layout>
                <div className="row mx-0">
                    <div className="col-12 my-2">
                        <div className="rounded-5 DarkBlue3_BgColor py-3">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <Link to="/" className="text-decoration-none">
                                        <h2 className="font_40 font_weight_800 LineHeight29 mb-0 py-3 ps-5"><span className="text-light">Talent </span> <span className="LightGreen_Color">SCAN</span></h2>
                                    </Link>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-md-end text-sm-end text-start">
                                    <h2 className="font_40 font_weight_800 LineHeight29 mb-0 py-3 pe-5"><span className="text-light">Live </span><span className="LightGreen_Color">Interview</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-9 col-sm-10 col-12 mx-auto text-center mt-5 pt-5">
                        <div className="QuestionTimerContainer3 d-inline-block">
                            <CountdownCircleTimer
                                isPlaying
                                duration={30}
                                colors={['#22B61F']}
                                // colors={['#22B61F', '#22B60F', '#00FF00', '#FF0000']}
                                colorsTime={[59]}
                                // colorsTime={[59, 45, 30, 0,]}
                            >
                                {/* {({ remainingTime }) => remainingTime} */}
                                {renderTime}
                            </CountdownCircleTimer>
                        </div>

                        <div className="RemainingTimeLeft py-3">
                            <h5 className="font_20 font_weight_400">Time left to read the Question</h5>
                        </div>

                        <div className="QuestionContainer LightGrey8_BgColor py-5 rounded-3">
                            <h5 className="DarkGrey2_Color font_24 font_weight_500">Please Read Question / Prepare Answer</h5>
                            <h3 className="font_weight_500 font_35 DarkBlue4_Color">Q1. How are you feeling today?</h3>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default QuestionTiming;