import React, { useEffect, useState, useRef } from "react";
import DataTable from 'react-data-table-component';
import axios from "axios";
import Layout from "../Components/Layout";
import { Link, NavLink } from "react-router-dom";
import { FaShareAlt } from "react-icons/fa";
import NoVideo from './../Images/VideoNotAvaliable.png'
import talentscanlogo from './../Images/talent-scan-logo-final-white.png';
const AnswerVideoListShow = () => {
    document.title = "Talent Scan - Question and Answer"
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState({candidate_answer_data:{}})
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [next, setnext] = useState("")
    const [prev, setprev] = useState("")
    const [totalCount, settotalCount] = useState(0)
    const videoRef2 = useRef(null);
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    let obj = {};
    for(let pair of params.entries()) {
        obj[pair[0]] =pair[1];
    }

    const id =  obj["id"] ? obj["id"] : 0;
    const attemtid =  obj["attemtid"] ? obj["attemtid"] : 0;
    const sections =  obj["section"] ? obj["section"] : 0;
    const name =  obj["name"] ? obj["name"] : 0;
    const phone =  obj["phone"] ? obj["phone"] : 0;
    const email =  obj["email"] ? obj["email"] : 0;
    const is_qualified =  obj["is_qualified"] ? obj["is_qualified"] : 0;
     
    const [sectionsfromurl, setsectionsfromurl] = useState([])
    const [sectionwiseList, setsectionwiseList] = useState([])
    const [selectedQuection, setselectedQuection] = useState([])
    const [score_info, setscore_info] = useState({})
    const [videourlsfirst, setvideourlsfirst] = useState()
    const [videourlsfirstname, setvideourlsfirstname] = useState("")
    const [videourls, setvideourls] = useState({})
    const [user_data, setuser_data] = useState({})
     
    useEffect(()=>{
        setvideourlsfirst(videourlsfirst)
    }, [videourlsfirst])
    const GetTestDashboardList = async (url) => {
        setLoader(false)
         
        let token = sessionStorage.getItem("Admintoken");
       
       let completeurl = `apiV1/attempted-test-videos/${id}/?attempt_id=${attemtid}`;
        if(url){
            completeurl = url
        }
        axios.get(completeurl).then((res) => {
            setLoader(true)
            if(res.data) {

                Object.entries(res.data.s3_urls).map((item, i) => {
                    if(i == 0){
                        setvideourlsfirst(item[1])
                        console.log(item[1])
                        setvideourlsfirstname(item[0])
                    }
                })
                setuser_data(res.data.user_data)
                setvideourls(res.data.s3_urls)
            }
        }).catch((e) => {
            setLoader(true)
        })
    }

    const nextSectionvalue = async (value) => {

    }
    const changequestion = (selected, name) => {
        setvideourlsfirstname(name)
        setvideourlsfirst(selected)
      //  setselectedQuection(selected)
    }

    useEffect(()=>{
        GetTestDashboardList();
    }, [])
    return (
        <>
            <Layout>
                <nav className="navbar navbar-expand-lg" id="TopHeaderNavBar1">
                    <div className="container-fluid mx-5 rounded-4  DarkBlue3_BgColor">
                        
                        <div className="navbar-brand ps-4" >
                            <img src={talentscanlogo}/>
                        </div>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation ">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavDropdown">
                            <ul className="navbar-nav mx-auto">
                                <li className="nav-item">
                                    <NavLink className="nav-link font_20 font_weight_300" activeclassname="active" aria-current="page" to="/resumeparsing">Resume Parsing</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link font_20 font_weight_300" activeclassname="active" aria-current="page" to="/biometricscoring">Biometric Scoring</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink  className={ "nav-link font_20 font_weight_300"}  activeclassname="active" to="/testdashboard" >Dashboard</NavLink>
                                </li>
                                
                            </ul>
                            <ul className="navbar-nav float-end">
                                <li className="nav-item dropdown  pe-4">
                                    <a className="nav-link dropdown-toggle  font_20 font_weight_300" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa fa-user-circle-o" aria-hidden="true"></i> Profile
                                    </a>
                                    <ul className="dropdown-menu" id="ProfileDropdown">
                                        
                                        {/* <li><NavLink className="dropdown-item"  to='/changepassword'>Change Password</NavLink></li> */}
                                        {/* <li><Logout /></li> */}
                                        {/* <li>
                                            <Link></Link>
                                            <a className="dropdown-item" href="./index.html">Logout</a>
                                        </li> */}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                

                <div className="row mx-5 mt-3">
                    {/* Candidate Information and All Test Information Starting */}
                    {loader ? 
                    <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12 py-3">
                         
                        <div className="CandidateInformationHeading py-2">
                            <h3 className="font_18 px-2">Candidate Information</h3>
                        </div>
                        <div className="CandidateInformation py-1 px-2 shadow mb-2">
                            {user_data.name ? 
                            <div className="formData pb-2 align-middle">
                                <label className="FormKeyLabel font_14 font_weight_400">Name : </label>
                                <label className="FormValueLabel font_weight_600 font_15 ps-2"> { user_data.name }</label>
                            </div> : ""}
                            {user_data.email ?
                            <div className="formData pb-2 align-middle">
                                <label className="FormKeyLabel font_14 font_weight_400">Email : </label>
                                <label className="FormValueLabel font_weight_600 font_15 ps-2"> {user_data.email}</label>
                            </div> : ""}
                            {user_data.phone ?
                            <div className="formData pb-2 align-middle">
                                <label className="FormKeyLabel font_14 font_weight_400">Phone : </label>
                                <label className="FormValueLabel font_weight_600 font_15 ps-2"> {user_data.phone}</label>
                            </div> : ""}
                            <div className="formData pb-2 align-middle">
                                <label className="FormKeyLabel font_14 font_weight_400" title="Over all Qualified Yes/No">Qualified  : </label>
                                <label className="FormValueLabel font_weight_600 font_14 ps-2" title="Yes/No"> {is_qualified == "true" ? "Yes" : "No"}</label>
                            </div>
                            <div className="formData pb-2 align-middle">
                                <label className="FormKeyLabel font_14 font_weight_400">Attempt : </label>
                                <label className="FormValueLabel font_weight_600 font_15 ps-2">{"Yes"}</label>
                            </div>
                             {/* <div className="formData pb-2 align-middle d-flex justify-content-between">
                                <button className="btn btn-default text-decoration-underline font_weight_500 font_12 ps-0">Download Resume</button>
                                <button className="btn btn-default text-decoration-underline font_weight_500 font_12 ps-0" title="Share Result with" data-bs-toggle="modal" data-bs-target="#shareWithRelatedHRsModal"><FaShareAlt /></button>
                                
                            </div> 
                             <div className="formData pb-2 align-middle">
                                <label className="FormKeyLabel font_18 font_weight_400 DarkBlue2_Color">Name : </label>
                                <label className="FormValueLabel DarkBlue2_Color font_weight_600 font_18 ">Test</label>
                            </div>  */}
                        </div>

                        {/* <div className="CandidateAllTestInformation mt-4">
                            <ul className="CandidateAllTestList">
                                <li className="">
                                    <div className="CandidateTestItem activeOne">
                                        <div className="row px-2">
                                            <div className="col testName">{selectedQuection.category}</div>
                                            <div className="col testIsActive text-end">Active</div>
                                        </div>
                                    </div>
                                    <div className="px-2 shadow rounded">
                                        <div className="formData px-2 pb-2 d-flex justify-content-between align-middle">
                                            <label className="FormKeyLabel font_14 font_weight_400 DarkBlue2_Color">Total Score : </label>
                                            <label className="FormValueLabel DarkBlue2_Color font_weight_600 font_14 ps-2 text-end"> {score_info.total_score}</label>       
                                        </div>
                                        <div className="formData px-2 pb-2 d-flex justify-content-between align-middle">
                                            <label className="FormKeyLabel font_14 font_weight_400 DarkBlue2_Color">Cutoff  Score : </label>
                                            <label className="FormValueLabel DarkBlue2_Color font_weight_600 font_14 ps-2 text-end"> {score_info.cutoff_score}</label>
                                        </div>
                                        <div className="formData px-2 pb-2 d-flex justify-content-between align-middle">
                                            <label className="FormKeyLabel font_14 font_weight_400 DarkBlue2_Color">Candidate Score : </label>
                                            <label className="FormValueLabel DarkBlue2_Color font_weight_600 font_14 ps-2 text-end">{score_info.score_secured}</label>
                                        </div>
                                        <div className="formData px-2 pb-2 d-flex justify-content-between align-middle">
                                            <label className="FormKeyLabel font_14 font_weight_400 DarkBlue2_Color">Behavioural Score : </label>
                                            <label className="FormValueLabel DarkBlue2_Color font_weight_600 font_14 ps-2 text-end">{score_info.behavioural_score}</label>
                                        </div>
                                       
                                        <div className="formData px-2 pb-2 d-flex justify-content-between align-middle">
                                            <label className="FormKeyLabel font_14 font_weight_400 DarkBlue2_Color">Qualified : </label>
                                            <label className="FormValueLabel font_14 ps-2 DarkBlue2_Color font_weight_600 font_14  text-end">{score_info.is_qualified ? "Yes" : "No"}</label>
                                        </div>
                                    </div>
                                    
                                </li>
                                {sectionsfromurl.map((section, i)=>{
                                    return (
                                        section !== selectedQuection.category ? 
                                        <li className="CandidateTestItem" onClick={()=>nextSectionvalue(section)}>
                                            <div className="row px-2">
                                                <div className="col testName" >{section}</div>
                                                <div className="col testIsActive text-end"></div>
                                            </div>
                                        </li> : ""
                                    )
                                })}
                                
                               
                            </ul>
                        </div> */}
                    </div> : ""}
                    {/* Candidate Information and All Test Information Ending */}

                    {/* Caandidate Current Video and  Question and Answer Ending */}
                    <div className="col-xxl-8 col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12 py-3">
                        {/* Question and Answer Starting */}
                        {loader ? 
                        <div className="QuestionAndAnswer">
                            <div className="videofile shadow-lg bg-dark rounded">
                                {videourlsfirst ? 
                                <video className="" ref={videoRef2}  width={"100%"} height="500" controls autoPlay  src={videourlsfirst} >
                                </video>  : <img src={NoVideo} alt="NoVideo" className="noVideo" width="100%" />}

                              
                            </div>
                            <h4>{videourlsfirstname}</h4>
                        </div> : <div className='text-center'>
                    <div className='spinner-border ' role='status'>
                        <span className='sr-only'></span>
                    </div>
                </div>}
                        
                    </div>
                     
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 py-3">
                        
                        <ul className="VideoFileList">
                            
                            {Object.entries(videourls).map((item, i) => (
                                <li className="VideoFileItem mb-2  " onClick={()=>changequestion(item[1], item[0])}>
                                    <div className="videofile row shadow">
                                        <div className={item[0] == videourlsfirstname ? "col-6 p-1 rounded bg-dark activeVideo" : "col-6 p-1 rounded bg-dark"}>
                                            {item[1] ?  <video className="rounded" ref={videoRef2}  width={"100%"} height="80"   src={item[1]} > </video>  : <img src={NoVideo} alt="NoVideo" className="noVideo" width="100%" />}
                                        </div>
                                        <div className= {item[0] == videourlsfirstname ? "col-6 p-1 rounded bg-light activeVideo" : "col-6 p-1 rounded bg-light"}>
                                            <div className="TestQuestionVideoTitle font_weight_500 font_14 lh-sm pt-1 px-1" title={item[0]}>{item[0]}</div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    
                    
                </div>
                
            </Layout>
            
        </>
    )
}

export default AnswerVideoListShow;