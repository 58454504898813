import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import axios from "axios";
import Layout from "../Components/Layout";
import { Link } from "react-router-dom";
const Attemptedtestlist = () => {
    const [loader, setLoader] = useState("")
    const [data, setData] = useState({candidate_info:{}, score_info:[], test_info:{}    })
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    let obj = {};
    for(let pair of params.entries()) {
        obj[pair[0]] =pair[1];
    }

    const id =  obj["id"] ? obj["id"] : 0;
    
  
    const GetTestDashboardList = async () => {
        let token = sessionStorage.getItem("Admintoken");
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
        }
       
        
        axios.get(`apiV1/attempted-test-list/${id}/`,config).then((res) => {
            if(res.data) {
                
                setLoader(false)
                if(res.data.results.length !== 0){
                    setData(res.data.results[0])
                }
                
            }
        }).catch((e) => {
            setLoader(false)
        })
    }
    useEffect(()=>{
        GetTestDashboardList(1);
    }, [])
    return (
        <>
            <Layout>
            <div className="row mx-5">
                <Link to="/testdashboard" >Back</Link>
                <div className="col-md-12">
                    <div>Email: {data.test_email}</div>
                    <div>link: {data.link}</div>
                    <div>name: {data.candidate_info.name}</div>
                    <div>Email: {data.candidate_info.email}</div>
                    <div>phone: {data.candidate_info.phone}</div>
                    <div>score: {data.candidate_info.score}</div>
                    <div>experience: {data.candidate_info.experience}</div>
                    <div>industry: {data.candidate_info.industry}</div>
                    <div>name: {data.test_info.name}</div>
                    <div>test_designation: {data.test_info.test_designation}</div>
                    <div>description: {data.test_info.description}</div>
                    <div>activation_code: {data.activation_code}</div>
                    {data.attempt_id ? 
                    <div><Link to={`/questionresult?id=${id}&attemtid=${data.attempt_id}`}>Check Questions</Link></div> : ""}
                    <hr/>
                    {data.score_info.map((score, i)=>{
                        return (
                            <>
                            <div>section__name:{score.section__name}  </div>
                            <div>question_type:{score.question_type}  </div>
                            <div>no_of_questions:{score.no_of_questions}  </div>
                            <div>score_secured:{score.score_secured}  </div>
                            <div>total_score:{score.total_score}  </div>
                            <hr/>
                            </>
                        )
                    })}
                </div>
            </div>
            </Layout>
            
        </>
    )
}

export default Attemptedtestlist