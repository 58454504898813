import React from "react";
import Layout from "../../Components/Layout";
import { Link } from "react-router-dom";

const QuestionType = () => {
    return (
        <>
            <Layout>
                <div className="row mx-0">
                    <div className="col-12 my-2">
                        <div className="rounded-5 DarkBlue3_BgColor py-3">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <Link to="/" className="text-decoration-none">
                                        <h2 className="font_40 font_weight_800 LineHeight29 mb-0 py-3 ps-5"><span className="text-light">Talent </span> <span className="LightGreen_Color">SCAN</span></h2>
                                    </Link>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-md-end text-sm-end text-start">
                                    <h2 className="font_40 font_weight_800 LineHeight29 mb-0 py-3 pe-5"><span className="text-light">Instru</span><span className="LightGreen_Color">ctions</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-7 col-sm-8 col-12 mx-auto position-relative QuestionTypeContainer2">
                        <div className="QuestionType2">
                            <div className="LightGrey10_BgColor text-center py-2 mb-2 rounded">
                                <h4 className="DarkGrey2_Color font_weight_500 font_25">Question Type : Verbal</h4>
                            </div>

                            <div className="LightGrey10_BgColor text-center py-2 mb-2 rounded">
                                <h4 className="DarkGrey2_Color font_weight_500 font_25">Total Number of Questions : 01 </h4>
                            </div>

                            <div className="LightGrey10_BgColor text-center py-2 mb-2 rounded">
                                <h4 className="DarkGrey2_Color font_weight_500 font_25">Total Time : 00:01:00</h4>
                            </div>
                            <div className="BtnGroup text-center mt-4">
                                <Link to='/questiontiming'className="btn btn-primary DarkBlue3_BgColor MyBtnSize font_weight_500 font_20">Continue</Link>
                                {/* <button type="button" className="btn btn-primary DarkBlue3_BgColor MyBtnSize" title='start timer'>Continue</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default QuestionType;