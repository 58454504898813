import React, { useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../Components/Layout";
import LoginBg from './../../Images/LoginBg.webp';
import { useForm } from "react-hook-form";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import axios from "axios";
import talentscanlogo from './../../Images/talent-scan-logo-final.png';
const SignUp = () => {
    document.title = "Talent Scan - Sign up"
    const navigate = useNavigate();
    const [type, setType] = useState('password');
    const [passwordIcon, setPasswordIcon] = useState(<FaEyeSlash />)
    const [signUpValidationErrors, setSignUpValidationErrors] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { register, handleSubmit, formState: { errors, },setError, trigger, getValues } = useForm({});

    const signUpAction = (data) => {
        setSignUpValidationErrors({})
        seterrorforPasswordmatch("")
        if(data.enterPassword != data.confirmPassword){
            seterrorforPasswordmatch("Your password not match with above one")
            return false
        }
        setIsSubmitting(true)
        let req = {
            name: data.yourName,
            email: data.emailAddress,
            mobile: data.mobileNumber,
            company_name:data.CompanyName,
            job_title: data.JobTitle,
            password: data.enterPassword,
            confirm_password: data.confirmPassword
        }
        
        axios.post('apiV1/register/',req).then( (rep) => {
                setIsSubmitting(false)
                navigate("/signupsuccess");
            }
        ).catch((e) => {
            setIsSubmitting(false)
            if(e.response.data) {

                setSignUpValidationErrors(e.response.data)
            }
        })
    }
    const [errorforPasswordmatch, seterrorforPasswordmatch] = useState("")
    const checkpasswordtwo = (value) => {
       let pasword =  getValues("enterPassword");
        if(value.length >= pasword.length - 1){
            if(getValues("enterPassword") == value){
                seterrorforPasswordmatch("")
                //setError("confirmPassword", { type: 'custom', message: 'Match custom message' })
            }else{
                seterrorforPasswordmatch("Password & Confirm Password does not match")
            // setError('confirmPassword', { type: 'custom', message: 'Not custom message'  });
             setError("confirmPassword", { type: 'custom', message: 'Not custom message' })
            }
        }
    }
    const ShowPassHandler = () => {
        if(type == 'password') {
            setPasswordIcon(<FaEye />);
            setType('text');
        } else {
            setPasswordIcon(<FaEyeSlash />);
            setType('password');
        }
    }
    return (
        <>
            <Layout>
                <div className="row mx-0">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-sm-12 col-12 mx-auto pt-5">
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="row shadow rounded-1 mb-5">
                                    <div className="col-xxl-6 col-xl-6 col-lg-4 col-md-4 col-sm-4 col-12  LightGrey_BgColor">
                                        <div className="LoginBg d-flex justify-content-center align-items-center">
                                            <div className="d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none px-5">
                                                <img src={LoginBg} alt="SignUpBg" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-8 col-sm-8 col-12 px-5 pt-4">
                                        <div className="text-center">
                                            <h2 className="font_24 DarkBlue_Color font_weight_600 LineHeight29 mb-0">Create your Account</h2>
                                            <img src={talentscanlogo} alt="logo"/>
                                        </div>
                                        <div className="pt-3">
                                            <h3 className="font_20 font_weight_500 text-dark LineHeight24">Sign Up</h3>
                                        </div>
                                        <form id="SignUpForm" className="SignUpForm" onSubmit={handleSubmit(signUpAction)}>
                                        <div className="FormGroup">
                                                <label htmlFor="SignUpNameInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color">Full Name  <span className="font_14 text-danger">*</span></label>
                                                <input type="text" className="form-control FormInput" id="SignUpNameInput" name="username" placeholder="Full Name" 
                                                {...register("yourName", {
                                                    required: "This field is required",
                                                    pattern: {
                                                      value: /^[A-Za-z][A-Za-z-\s]+$/,
                                                      message:
                                                        "This field allow only alphabets and spaces but not accepted space at 1st character",
                                                    },
                                                    minLength: {
                                                      value: 3,
                                                      message: "Please enter min 3 characters ",
                                                    },
                                                  })}
                                                onKeyUp={() => {
                                                    trigger("yourName");
                                                    setSignUpValidationErrors({})
                                                }}
                                                />
                                                {errors.yourName && (<small className='text-danger font-bold'>{errors.yourName.message}  </small>)}
                                            </div>

                                            <div className="FormGroup">
                                                <label htmlFor="SignUpCorporateEmailInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color">Email Address  <span className="font_14 text-danger">*</span></label>
                                                <input type="email" className="form-control FormInput" id="SignUpCorporateEmailInput" name="email" placeholder="Email Address"
                                                {...register("emailAddress", {
                                                    required: "This field is required",
                                                    pattern: {
                                                      value:
                                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]{2,}\.+[A-Z]{2,5}$/i,
                                                      message: "Please enter a valid email address.",
                                                    },
                                                  })}
                                                  onKeyUp={(e) => {
                                                    trigger("emailAddress");
                                                    setSignUpValidationErrors({})
                                                    //mailVerification(e)
                                                  }}
                                                />
                                                {" "}
                                                {errors.emailAddress && (
                                                    <small className='text-danger font-bold'>
                                                        {errors.emailAddress.message}
                                                    </small>
                                                )}
                                                {/* <small id="SignUpCorporateEmailInputErr" className="font_12 text-danger invisible">Corporate Email Error</small> */}
                                            </div>

                                            <div className="FormGroup">
                                                <label htmlFor="SignUpPhoneNumberInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >Phone Number  <span className="font_14 text-danger">*</span></label>
                                                <input type="text" className="form-control FormInput" id="SignUpPhoneNumberInput" placeholder="Phone Number" 
                                                // value={signUpPhoneNumber} onChange={(e) => {setSignUpPhoneNumber(e.target.value)}}
                                                {...register("mobileNumber", {
                                                    required: "This field is required",
                                                    pattern: {
                                                      value: /^[1-9]{1}[0-9]{9}$/,
                                                      message:
                                                        "Please enter a valid mobile number",
                                                    },
                                                    minLength: {
                                                      value: 10,
                                                      message: "Please enter min 10 numbers ",
                                                    },
                                                    maxLength: {
                                                      value: 10,
                                                      message: "Please enter max 10 numbers ",
                                                    },
                                                  })}
                                                  onKeyUp={(e) => {
                                                    trigger("mobileNumber");
                                                    setSignUpValidationErrors({})
                                                    // setmobileNumberError(false)
                                                    // checkMobileNumberValid(e.target.value)
                                                  }}
                                                  minLength={10}
                                                  maxLength={10}
                                                />
                                                {/* {mobileNumberError  && (< small className='text-danger font-bold'>Please enter valid mobile number </small>) } */}
                                                {errors.mobileNumber && (
                                                    <small className='text-danger font-bold'>
                                                        {errors.mobileNumber.message}
                                                    </small>
                                                )}
                                                {/* <small id="SignUpPhoneNumberInputErr" className="font_12 text-danger invisible">Phone Number Error</small> */}
                                            </div>

                                            <div className="FormGroup">
                                                <label htmlFor="SignUpCompanyNameInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >Company Name  <span className="font_14 text-danger">*</span></label>
                                                <input type="text" className="form-control FormInput" id="SignUpCompanyNameInput" placeholder="Company Name"
                                                {...register("CompanyName", {
                                                    required: "This field is required",
                                                    pattern: {
                                                        value: /^[A-Za-z0-9][A-Za-z0-9&-\s]+$/,
                                                        message:
                                                          "This field allow only alphabets and spaces and Space not accepted at 1st character",
                                                      },
                                                })}
                                                onKeyUp={() => {
                                                    trigger("CompanyName");
                                                    setSignUpValidationErrors({})
                                                }}
                                               
                                                />
                                                {errors.CompanyName && (
                                                    <small className='text-danger font-bold'>
                                                        {errors.CompanyName.message}
                                                    </small>
                                                )}
                                                {/* <small id="SignUpCompanyNameInputErr" className="font_12 text-danger invisible">Company Name Error</small> */}
                                            </div>

                                            <div className="FormGroup">
                                                <label htmlFor="SignUpYourJobTitleInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >Your Job Title </label>
                                                <input type="text" className="form-control FormInput" id="SignUpYourJobTitleInput" placeholder="Your Job Title" 
                                                // value={signUpJobTitle} onChange={(e) => {setSignUpJobTitle(e.target.value)}} autoComplete="false" 
                                                {...register("JobTitle")}
                                                onKeyUp={() => {
                                                    trigger("JobTitle");
                                                    setSignUpValidationErrors({})
                                                }}
                                                minLength={5}
                                                maxLength={70}
                                                />
                                                {errors.JobTitle && (
                                                    <small className='text-danger font-bold'>
                                                        {errors.JobTitle.message}
                                                    </small>
                                                )}
                                                
                                                {/* <small id="SignUpYourJobTitleInputErr" className="font_12 text-danger invisible">Your Job Title Error</small> */}
                                            </div>

                                            <div className="FormGroup  position-relative">
                                                <label htmlFor="SignUpPasswordInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >Password  <span className="font_14 text-danger">*</span></label>
                                                <input type={type} className="form-control FormInput" id="SignUpPasswordInput" name="password" placeholder="Password" 
                                                // value={signUpPassword} onChange={(e) => {setSignUpPassword(e.target.value)}} autoComplete="false" 
                                                {...register("enterPassword", {
                                                    required: "This field is required",
                                                    pattern: {
                                                      value:
                                                        /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                      //  (?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])at least one special chareacter
                                                      message:
                                                        "Password should contain at least one digit & one lower case & one upper case & length will be minimum 8 characters ",
                                                    },
                                                  })}
                                                  onKeyUp={() => {
                                                    trigger("enterPassword");
                                                    setSignUpValidationErrors({})
                                                  }}
                                                />
                                                <button type="button" className="btn btn-sm ShowPassBtn position-absolute" onClick={ShowPassHandler}>{passwordIcon}</button>
                                                {/* <small id="SignUpPasswordInputErr" className="font_12 text-danger invisible">Password Error</small> */}
                                                {errors.enterPassword && (
                                                    <small className='text-danger font-bold'>
                                                        {errors.enterPassword.message}
                                                    </small>
                                                )}
                                            </div>

                                            <div className="FormGroup  position-relative">
                                                <label htmlFor="SignUpConfirmPasswordInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >Confirm Password  <span className="font_14 text-danger">*</span></label>
                                                <input type={type} className="form-control FormInput" id="SignUpConfirmPasswordInput" name="confirmpassword"  placeholder="Confirm Password" 
                                                {...register("confirmPassword", {
                                                    required: "This field is required",
                                                    pattern: {
                                                      value:
                                                        /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                                      //  (?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])at least one special chareacter
                                                      message:
                                                        "Password should contain at least one digit & one lower case & one upper case & length will be minimum 8 characters ",
                                                    },
                                                  })}
                                                  onKeyUp={(e) => {
                                                    trigger("confirmPassword");
                                                    checkpasswordtwo(e.target.value)
                                                    setSignUpValidationErrors({})
                                                  }}
                                                // value={signUpConfPassword} onChange={(e) => setSignUpConfPassword(e.target.value)} 
                                                />
                                                {errors.confirmPassword && (
                                                    <small className='text-danger font-bold'>
                                                        {errors.confirmPassword.message}
                                                    </small>
                                                )}
                                                <small id="SignUpConfirmPasswordInputErr" className="font_12 text-danger">{errorforPasswordmatch}</small>
                                            </div>

                                            <div className="FormGroup pb-4">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="">
                                                        {Object.entries(signUpValidationErrors).map(c => (
                                                           <div className="text-danger"><b>{c[0]}</b> : {c[1] }  </div>
                                                            ))}
                                                            
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="TermsCondidtionsCheckBox" onKeyUp={(e) => {
                                                    trigger("RememberMeCheck");
                                                     
                                                  }} {...register("RememberMeCheck", { required: "This field is required",})}  />
                                                            <label className="form-check-label FormLabel DarkBlue2_Color font_14 font_weight_400" htmlFor="RememberMeCheck">
                                                            By signing up I agree to <Link to="/termsandconditions" >Terms & Condidtions </Link>
                                                            </label>
                                                            
                                                        </div>
                                                        {errors.RememberMeCheck && (
                                                                <small className='text-danger font-bold'>
                                                                    {errors.RememberMeCheck.message}
                                                                </small>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="BtnGroup d-grid LineHeight22">
                                            {!isSubmitting ? (
                                                ""
                                                ) : (
                                                <div className='text-center'>
                                                    <div className='spinner-border ' role='status'>
                                                    <span className='sr-only'></span>
                                                    </div>
                                                </div>
                                                )}
                                                {/* <Link to="/signupsuccess" className="btn btn-primary BlugGradBtn font_18 font_weight_600"> Create an Account</Link> */}
                                                <button type="submit" disabled={isSubmitting} className="btn btn-primary BlugGradBtn font_18 font_weight_600">Sign Up</button>
                                                {/* <button type="button" className="btn btn-primary BlugGradBtn font_18 font_weight_600" onclick="window.location.href='./SignUpSuccessPage.html'">Create an Account</button> */}
                                            </div>

                                            <div className="BtnGroup text-center pt-3 font_18 pb-5">
                                                <label className="DarkBlue2_Color" >Already have an Account? </label>
                                                <Link to="/" className="DarkBlue2_Color font_weight_500  text-decoration-none"> Sign In</Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default SignUp;