 
import { SELECTED_COMPANYS, PAGESIZE, PAGESEARCH, QUESTIONINFO, PAGENUMBER, INCREMENT, DECREMENT, PAGESEARCHTEST, PAGENUMBERTEST, PAGESIZETEST, STARTDATE, ENDDATE, RESUMEPARSINGERR, BIOPARSINGERR } from './actions';
const initialState = {
    data: [],
    pagesize:0,
    pagenumner:1,
    pagesizetest:0,
    pagenumnertest:1,
    pagesearch:"",
    pagesearchtest:"",
    questioninfo:{},
    answercount: 0,
    startdate:"",
    endate:"",
    biometricparseerror:[],
    resumeparsingerror:[]

};
const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECTED_COMPANYS:
            return {
                ...state,
                data: action.payload,
            };
        case PAGESIZE:
            return {
                ...state,
                pagesize: action.payload,
            };
        case PAGENUMBER:
            return {
                ...state,
                pagenumner: action.payload,
            };
        case PAGESIZETEST:
            return {
                ...state,
                pagesizetest: action.payload,
            };
        case PAGENUMBERTEST:
            return {
                ...state,
                pagenumnertest: action.payload,
            };
        case PAGESEARCH:
            return {
                ...state,
                pagesearch: action.payload,
            };
        case PAGESEARCHTEST:
            return {
                ...state,
                pagesearchtest: action.payload,
            };
        case QUESTIONINFO:
            return {
                ...state,
                questioninfo: action.payload,
            };
        case INCREMENT:
            return {
                ...state,
                answercount: state.answercount + 1,
            };
        case DECREMENT:
            return {
                ...state,
                answercount: state.answercount - 1,
            };
        case STARTDATE:
            return {
                ...state,
                startdate: action.payload,
            };
        case ENDDATE:
            return {
                ...state,
                endate: action.payload,
            };
        case RESUMEPARSINGERR:
            return {
                ...state,
                resumeparsingerror: action.payload,
            };
        case BIOPARSINGERR:
            return {
                ...state,
                biometricparseerror: action.payload,
            };
        default:
            return state;
    }
};
export default rootReducer;