import React from "react";

const CertificateTemplate = () => {
    return (
        <>
            <h1>CertificateTemplate</h1>
        </>
    )
}

export default CertificateTemplate;