import React from 'react';
 
import {Link} from "react-router-dom";
import talentscanlogo from './../Images/talent-scan-logo-final.png';
function PrivacyPolicy() {
	 document.title = "Talent Scan | Terms-and-Conditions";
  	return (
		<>
			<div className='text-center'>
				<div className='topbar pt-4'>
					<Link to='/'>
					<div className="d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none px-5">
						<img src={talentscanlogo} alt="LoginBg" className="img-fluid" />
					</div>
					</Link>
				</div>
			</div>
			<div className="container mt-4" >
			<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 icon-boxes d-flex flex-column align-items-stretch justify-content-center " data-aos="fade-left">
              <h3 class=" ">Privacy Policy Template for Talent Scan</h3>
              <p>Welcome to Talent Scan. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Talent Scan platform.</p>
              <h4 class="privacy-head">Information We Collect</h4>
              <p>We may collect personal identification information, including names, email addresses, and demographic information. We also collect data related to your use of our service, such as test responses and behavioral analysis results.</p>

              <h4 class="privacy-head">Use of Your Information</h4>
              <p>Your information may be used to administer tests, provide analysis, improve our service, and communicate with you. We may also use information for research and development purposes.</p>

              <h4 class="privacy-head">Disclosure of Your Information</h4>
              <p>We may share information with third parties for business purposes, comply with legal obligations, or protect our rights. We ensure all third parties respect the security of your personal data.</p>

              <h4 class="privacy-head">Data Security</h4>
              <p>We implement various security measures to maintain the safety of your personal information. However, no electronic transmission over the Internet can be guaranteed to be 100% secure.</p> 


              <h4 class="privacy-head">Your Rights</h4>
              <p>You have the right to access, correct, or delete your personal data. Please contact us to exercise these rights.</p> 


              <h4 class="privacy-head">Policy Changes</h4>
              <p>We reserve the right to make changes to this policy. Any changes will be posted on this page.</p> 

              <h4 class="privacy-head">Contact Us</h4>
              <p>If you have any questions about this Privacy Policy, please contact us at [enquiry@pranathiss.com].</p> 

              <p>Thank you for trusting us with your information!</p>

              <p>Product has been developed by <strong>Pranathi Software Services Private Limited.</strong></p>
             
            </div>
			</div>

		</>
	
  	);
}

export default PrivacyPolicy;
