import React, {useState, useRef, useEffect} from "react";
import Layout from "../../Components/Layout";
import TestIcon1 from './../../Images/Test_Icon_1.png';
import TestIcon2 from './../../Images/Test_Icon_2.png';
import TestIcon3 from './../../Images/Test_Icon_3.png';
import TestIcon4 from './../../Images/Test_Icon_4.png';
import TestIcon5 from './../../Images/Test_Icon_5.png';
import TestImg1 from './../../Images/Test_Img_1.png';
import TestImg2 from './../../Images/Test_Img_2.png';
import Modal from 'react-bootstrap/Modal';

import axios from "axios";
import Instructions from "./Instructions";
import talentscanlogo from './../../Images/talent-scan-logo-final-white.png';
const StartTest2 = () => {
    const [videoAccess, setvideoAccess] = useState(false)
    const [InterNetAccess, setInterNetAccess] = useState(false)
    const [AudioAccess, setAudioAccess] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [attemptedTre, setattemptedTre] = useState(false)
    const [startDisabled, setstartDisabled] = useState(false)
    const [initialData, setinitialData] = useState({})
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [micActive, setMicActive] = useState(false);
    const [analyser, setAnalyser] = useState(null);
    const [micStream, setMicStream] = useState(null);
    const [micLevel, setMicLevel] = useState(0);
    const [errorforsafari, seterrorforsafari] = useState('');

    const requestFullScreen = () => {
        const element = document.documentElement;

        if (element.requestFullscreen) {
            element.requestFullscreen();
          } else if (element.mozRequestFullScreen) { // Firefox
            element.mozRequestFullScreen();
          } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
            if (navigator.userAgent.includes('Safari')) {
              element.webkitRequestFullscreen(); // Safari
            } else {
              element.webkitRequestFullscreen(); // Chrome and Opera
            }
          } else if (element.msRequestFullscreen) { // IE/Edge
            element.msRequestFullscreen();
          }
         
      };
     
      useEffect(()=>{
        const handleFullScreenChange = () => {
          setIsFullScreen(!!document.fullscreenElement);
        };
        document.addEventListener('fullscreenchange', handleFullScreenChange);
          document.addEventListener('mozfullscreenchange', handleFullScreenChange);
          document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
          document.addEventListener('msfullscreenchange', handleFullScreenChange);
      
          return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
            document.removeEventListener('msfullscreenchange', handleFullScreenChange);
          };
      }, [])      
    const webCamRef = useRef(null)
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    let obj = {};
    for(let pair of params.entries()) {
        obj[pair[0]] =pair[1];
    }

    const id = obj["id"] ? obj["id"] : 0;
    const candidate_id = obj["candidate_id"] ? obj["candidate_id"] : 0;

    const handleModalClose = () => setShowModal(false);
    const handleModalClose2 = () => setShowModal2(false);
    const checkVideoAccess = () => {
        // navigator.mediaDevices.getUserMedia({video:true, audio:false}).then((stream) => {
        //     console.log(stream)
        //     if(stream){
        //         setvideoAccess(true)
        //         setAudioAccess(true)
        //     }
        // })
        // .catch((error) => {
        //     setvideoAccess(false)
        //     setAudioAccess(false)
        // });
    }
    const constraints = {
        audio: true,
        video :   {
            width: { ideal: 128 },  
            height: { ideal: 128 },  
            frameRate: { ideal: 10 },  
            facingMode: "user"  
        },
    }
    
    const startRecording = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind == 'videoinput');
        if (videoDevices.length == 0) {
            console.error('No video input devices available.');
            return;
        }
        const defaultVideoDevice = videoDevices[0];

        if(defaultVideoDevice.deviceId){
            constraints.video.deviceId = { exact: defaultVideoDevice.deviceId };
        }
        
 
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            if(stream){
                setvideoAccess(true)
                setAudioAccess(true)
            }

        } catch (error) {
            setvideoAccess(false)
            setAudioAccess(false)
            console.error('Error accessing camera:', error);
        }   
    };
    const startRecording2 = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        
        console.log('Video input devices:', videoDevices);
        
        if (videoDevices.length === 0) {
            console.error('No video input devices available.');
            // Handle the case where no cameras are available
            return;
        }
        const defaultVideoDevice = videoDevices[0];
        if(defaultVideoDevice.deviceId){
            constraints.video.deviceId = { exact: defaultVideoDevice.deviceId };
        }
        // Set the default video device as the preferred one
       // constraints.video.deviceId = { exact: defaultVideoDevice.deviceId };
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            if(stream){
                webCamRef.current.srcObject = stream;

                const audioContext = new (window.AudioContext ||
                    window.webkitAudioContext)();
                  const microphone = audioContext.createMediaStreamSource(stream);
                  const analyser = audioContext.createAnalyser();
            
                  microphone.connect(analyser);
                  analyser.fftSize = 256;
                  const bufferLength = analyser.frequencyBinCount;
                  const dataArray = new Uint8Array(bufferLength);
            
                  setMicActive(true);
                  setAnalyser(analyser);
                  setMicStream(stream);
            
                  const updateMicLevel = () => {
                    analyser.getByteFrequencyData(dataArray);
                    const sum = dataArray.reduce((acc, value) => acc + value, 0);
                    const average = sum / dataArray.length;
                    setMicLevel(average);
                    requestAnimationFrame(updateMicLevel);
                  };
            
                  updateMicLevel();
            }

        } catch (error) {
            console.error('Error accessing camera:', error);
        }   
    };


    function captureFrameAsBase64() {
        const canvas = document.createElement('canvas');
        canvas.width = 300;
        canvas.height = 300;
        const context = canvas.getContext('2d');
        context.drawImage(webCamRef.current, 0, 0, canvas.width, canvas.height);
        const base64String = canvas.toDataURL('image/jpeg');
        return base64String;
      }
    const toStartInterview = () => {
        seterrorforsafari("")
        const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if(isSafariBrowser == false){
            requestFullScreen();
           
        }else{
            seterrorforsafari("This test not able to perform in this browser please try another browser ...")
            setstartDisabled(false)
            return false
        }
        
        setstartDisabled(true)
        const imageSrc = captureFrameAsBase64();

        
        const formData = new FormData();
        formData.append("candidate_initial_image",imageSrc )
        axios.defaults.headers.common = { 'Authorization': `${sessionStorage.getItem("token")}` }
        axios.put(`/apiV1/start-test/${id}/`,formData).then((res) => {
                if(res.data){
                    setstartDisabled(false)
                    setShowModal(false)
                    setattemptedTre(true)
                    setinitialData(res.data[0])
                    console.log(res.data[0].test_duration)
                    sessionStorage.setItem("timer",  res.data[0].test_duration)
                }
            }
        ).catch((e) => {
           console.log(e)
           setstartDisabled(false)
        });
    }
    const checkInternetAccess = () => {
        setInterNetAccess(window.navigator.onLine)
    }
    useEffect(()=>{
        startRecording()
        checkVideoAccess();
        checkInternetAccess()
    }, [])
    const checkinternetandaudivideo = () => {
        if(videoAccess && AudioAccess && InterNetAccess){
            setShowModal(true)
            startRecording2()
        }else{
            setShowModal2(true)
           
        }

        
    }
    return (
        <>
            {!attemptedTre ? 
            <Layout className="position-relative">
                <img src={TestIcon1} alt="TestIcon1" className="TestIcons" id="TestIcon1" />
                <img src={TestIcon2} alt="TestIcon2" className="TestIcons" id="TestIcon2" />
                <img src={TestIcon1} alt="TestIcon3" className="TestIcons" id="TestIcon3" />
                <img src={TestIcon3} alt="TestIcon4" className="TestIcons" id="TestIcon4" />
                <img src={TestIcon4} alt="TestIcon5" className="TestIcons" id="TestIcon5" />
                <img src={TestIcon5} alt="TestIcon6" className="TestIcons" id="TestIcon6" />
                <img src={TestIcon5} alt="TestIcon7" className="TestIcons" id="TestIcon7" />
                <img src={TestIcon4} alt="TestIcon8" className="TestIcons" id="TestIcon8" />
                <div className="row mx-0">
                    <div className="col-12 my-2">
                        <div className="rounded-5 DarkBlue3_BgColor py-3">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    {/* <Link to="/" className="text-decoration-none"> */}
                                    <img src={talentscanlogo} alt="Talent scan logo" />
                                    {/* </Link> */}
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-md-end text-sm-end text-start">
                                    <h2 className="font_40 font_weight_800 LineHeight29 mb-0 py-3 pe-5"><span className="text-light">Pre</span><span className="LightGreen_Color">view</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-5">
                        <div className="row">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="TestContentContainer">
                                    <h3 className="font_weight_700 font_60 text-break">Please turn on the <span className="LightGreen_Color">Video</span> and <span className="LightGreen_Color">Mic</span> before Starting the Interview.</h3>
                                    <div className="BtnGroup ParsingResumeBtn mt-4">
                                        <button type="button" className="btn btn-primary DarkBlue3_BgColor MyBtnSize" onClick={()=>checkinternetandaudivideo()}>Start Interview</button>
                                        {/*
                                            <button className="btn btn-primary DarkBlue3_BgColor MyBtnSize">Start Interview</button>
                                        */}
                                    </div>
                                    <div className="VoiceContent mt-4">
                                        <div className="VoiceContainer LightGreen2_BgColor rounded-2">
                                            <img src={TestImg2} alt="Voice Img" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="TestImgContainer">

                                    <img src={TestImg1} alt="TestImg1" className="TestImg img-fluid" id="TestImg1" />
                                    {/* <MyVideoRecorder /> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-8 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 mx-auto mt-3">
                        <div className="card border-0">
                            <div className="card-body LightGrey8_BgColor rounded px-4">
                                <div className="TestObjectContainer d-flex justify-content-between align-items-center flex-wrap">
                                    <div className="bg-white p-2 rounded">
                                        <div className="form-check TestObject font_weight_400 font_20  DarkBlue4_Color">
                                            <input className="form-check-input rounded-pill" type="checkbox" defaultChecked  checked={AudioAccess} id="AudioAccessCheck" />
                                            <label className="form-check-label" htmlFor="AudioAccessCheck">Audio Access</label>
                                        </div>
                                    </div>

                                    <div className="bg-white p-2 rounded">
                                        <div className="form-check TestObject font_weight_400 font_20  DarkBlue4_Color">
                                            <input className="form-check-input rounded-pill" type="checkbox" defaultChecked checked={videoAccess}  id="VideoAccessCheck" />
                                            <label className="form-check-label" htmlFor="VideoAccessCheck">Video Access</label>
                                        </div>
                                    </div>

                                    <div className="bg-white p-2 rounded">
                                        <div className="form-check TestObject font_weight_400 font_20  DarkBlue4_Color">
                                            <input className="form-check-input rounded-pill" type="checkbox" defaultChecked checked={true} value="" id="LightingConditionsCheck" />
                                            <label className="form-check-label" htmlFor="LightingConditionsCheck">Lighting Conditions</label>
                                        </div>
                                    </div>

                                    <div className="bg-white p-2 rounded">
                                        <div className="form-check TestObject font_weight_400 font_20  DarkBlue4_Color">
                                            <input className="form-check-input rounded-pill" type="checkbox" defaultChecked checked={InterNetAccess} id="InternetSpeedCheck" />
                                            <label className="form-check-label" htmlFor="InternetSpeedCheck">Internet Speed</label>
                                        </div>
                                    </div>

                                    <div className="bg-white p-2 rounded">
                                        <div className="form-check TestObject font_weight_400 font_20  DarkBlue4_Color">
                                            <input className="form-check-input rounded-pill" type="checkbox" checked={true}   id="ScreenAccessCheck" />
                                            <label className="form-check-label" htmlFor="ScreenAccessCheck">Screen Access</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout> :

            <Instructions data={initialData} id={id} candidate_id={candidate_id} /> }



            <Modal size="md" show={showModal} onHide={handleModalClose} backdrop="static"  keyboard={false} >
                <Modal.Header>
                    <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Capture Photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <form id="SetTestSettingsData" className="SetTestSettingsData" onSubmit={handleSubmit(SetTestSettingsAction)}> */}
                        <div className="row">
                            <div className="col-12">
                                <video ref={webCamRef} width={"100%"} muted height={300} autoPlay  />
                                <div className="mic">
                                    <div  className="volumebar" >
                                        <div className="miccontainer">
                                        {Array.from({ length: 20 }).map((_, i) => (
                                            <div
                                                className="bar"
                                                key={i}
                                                style={{
                                                backgroundColor:
                                                    Math.floor(micLevel % 20) > i ? "#A0A0A0" : undefined
                                                }}
                                            />
                                        ))}
                                    </div>
                                    </div>
                                    <div className="button">
                                        <button className="btn btn-primary DarkBlue3_BgColor" id="start" onClick={startRecording2} >Mic Test</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* </form> */}

                    {!startDisabled ? ( "" ) : (
                        <div className='text-center'>
                            <div className='spinner-border ' role='status'>
                            <span className='sr-only'></span>
                            </div>
                        </div>
                        )}
                     <div className="row">
                        <div className="col-12">
                            <div className="text-danger">
                                {errorforsafari}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="mx-auto border-0" >

                    <button type="submit" variant="secondary" onClick={handleModalClose} disabled={startDisabled} className="btn btn-primary DarkBlue3_BgColor MyBtnSize">
                        Close
                    </button>
                    <button variant="primary" className="btn btn-primary DarkBlue3_BgColor MyBtnSize" disabled={startDisabled} onClick={()=>toStartInterview()} >
                        Start
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showModal2} onHide={handleModalClose2} backdrop="static"  keyboard={false}>
                <Modal.Header>
                    <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Error</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-left">
                    <h2>Please turn on the Video and Mic before Starting the Interview</h2>
                </Modal.Body>
                <Modal.Footer className="mx-auto border-0">
                    <button type="submit" variant="secondary" onClick={handleModalClose2} className="btn btn-primary DarkBlue3_BgColor MyBtnSize">
                        Close
                    </button>
                     
                </Modal.Footer>
            </Modal>
        </>

    )
}

export default StartTest2;