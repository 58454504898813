import React from "react";
import LoginBg from './../../Images/LoginBg.webp';
import { Link } from "react-router-dom";
import Layout from "../../Components/Layout";
import talentscanlogo from './../../Images/talent-scan-logo-final.png';
const ChangedPasswordSuccess = () => {
    return (
        <>
            <Layout>
                <div className="row m-0">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-sm-12 col-12 mx-auto pt-5">
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="row shadow rounded-1">
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-4 col-12  LightGrey_BgColor">
                                        <div className="LoginBg d-flex justify-content-center align-items-center">
                                            <div className="d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none px-5">
                                                <img src={LoginBg} alt="LoginBg" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-8 col-12 px-5 pt-4">
                                        <div className="text-center pt-5 pb-4 mt-5">
                                        <img src={talentscanlogo} />
                                        </div>
                                        <div className="text-center">
                                            <h3 className="font_24 font_weight_600 DarkBlue_Color">Successfully Changed Password</h3>
                                        </div>
                                        
                                        <div className="BtnGroup text-center font_18">
                                            <label className="DarkBlue2_Color">Back to  </label> 
                                            <Link to="/" className="DarkBlue2_Color font_weight_500 text-decoration-none"> Sign In</Link>
                                            {/* <a href="./index.html" className="DarkBlue2_Color font_weight_500">Login</a> */}
                                        </div>
                                        <div className="py-5 my-3"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ChangedPasswordSuccess;