import React, {useEffect, useState} from "react";
import Layout from "../../Components/Layout";
import talentscanlogo from './../../Images/talent-scan-logo-final-white.png';
import successright from './../../Images/errorlive.png';
import axios from "axios";
import { useSelector} from 'react-redux';
const ErrorPage = () => {
    const questioninfo = useSelector((state) => state.questioninfo);
    const [loading, setloading] = useState(true)
   useEffect(()=>{
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    let obj = {};
    for(let pair of params.entries()) {
        obj[pair[0]] =pair[1];
    }
    if(obj.refresh == 1){
        sendMailtoUser(obj.attemtID, obj.id)
        sendMailtoUser2(obj.id, obj.questionid, obj.sectionName)
        window.addEventListener('popstate', function () {
            this.alert('Your not allow to back')
        });

        sessionStorage.removeItem('tabCount')
    }else{
        setloading(false)
    }
    
    
   },[])
    const sendMailtoUser = (attemtID, testid) => {
        setloading(true)
        axios.defaults.headers.common = { 'Authorization': `${sessionStorage.getItem("token")}` }
        axios.put(`/apiV1/submit-test/${attemtID}/`).then((res) => {
           // setloading(false)
            document.removeEventListener("visibilitychange", (event) => {})
            //sendMailtoUser2(attemtID)
            sessionStorage.setItem("tabCount", -99)
           // navigate("/errorcrossbrowser")
            window.location.href = `${window.location.origin}/errorbrowser?id=${testid}&attemtID=${attemtID}`
        }).catch((e) => {
        console.log(e)
        });
    }

    const sendMailtoUser2 = (attemtID, questionid, sectionName) => {
        const formData = new FormData();
        formData.append("last_question_attempted",questioninfo.id)
        formData.append("section_name",questioninfo.category)
        formData.append("remarks",'Tab change issues closed test')

        axios.defaults.headers.common = { 'Authorization': `${sessionStorage.getItem("token")}` }
        axios.put(`/apiV1/candidate-tab-change/${attemtID}/`, formData).then((res) => {
            document.removeEventListener("visibilitychange", (event) => {})
            }
        ).catch((e) => {
        console.log(e)
        });
    }
    return (
        <>
            <Layout>
                {!loading ? <div className="row mx-0">
                    <div className="col-12 my-2">
                        <div className="rounded-5 DarkBlue3_BgColor py-3">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    {/* <Link to="/" className="text-decoration-none"> */}
                                    <img src={talentscanlogo} alt="logo"/>
                                    {/* </Link> */}
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-md-end text-sm-end text-start">
                                    <h2 className="font_40 font_weight_800 LineHeight29 mb-0 py-3 pe-5"><span className="text-light">Instru</span><span className="LightGreen_Color">ctions</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-5 pt-5 text-center">
                        <div className="mb-5">
                            <img src={successright} alt="success" width={209} height={192} />
                        </div>
                        <h1>Oops! Something went wrong.</h1>
                        <p>Your test was auto submited due to more tabs are changed</p>
                        <p>Please contact support if the problem persists.</p>
                       
                    </div>
                </div> : <div class="container2">
                    <div class="spinner-container">
                        <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                  </div> }
            </Layout>
        </>
    )
}

export default ErrorPage;