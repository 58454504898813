import React, { useEffect, useState, useRef } from "react";
import Layout from "../../Components/Layout";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import TestImg1 from './../../Images/Test_Img_1.png';
import CodingAnswerTheQuestion from "./CodingAnswerTheQuestion";
import talentscanlogo from './../../Images/talent-scan-logo-final-white.png';
import $ from 'jquery';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import Toaster from './../../Toaster'
import { QUESTIONINFOS, increment, decrement } from './../../Redux/actions';
import { useDispatch } from 'react-redux';
const AnswerTheQuestion = () => {
    const dispatch = useDispatch();
    const [mediaStream, setMediaStream] = useState(null);
    const [recording, setRecording] = useState(false);
    const [listOfTests, setlistOfTests] = useState({})
    const [totalCount, settotalCount] = useState(0)
    const [attempt_id, setattempt_id] = useState()
    const [id, setid] = useState()
    const [testid, settestid] = useState()
    const [next, setNext] = useState("")
    const [sectionName, setsectionName] = useState("")
    const [codingquestion, setcodingquestion] = useState(true)
    const [linkGenShowModal2, setLinkGenShowModal2] = useState(false)
    const [loader, setloader] = useState(false)
    const [showsubmitbutton, setshowsubmitbutton] = useState(true)
    const navigate = useNavigate();
    const [candidate_id, setcandidate_id] = useState()
    const fetchQuestionsfromprofile = (id,url, candidate_id) => {
        
        //clearTimeout(timeoutsession);
        setshowsubmitbutton(true)
        setloader(false)
        axios.defaults.headers.common = { 'Authorization': `${sessionStorage.getItem("token")}` }
        let tempUrl = ""
        if(url){
            tempUrl = url

        }else{
            tempUrl = `/apiV1/start-test-category/${id}/?candidate_id=${candidate_id}`;
        }
        axios.get(tempUrl).then((res) => {
            setloader(true)
            setTimeout(() => {
                setshowsubmitbutton(false)
            }, 10000);
            if(res.data.results.length !== 0 ){
                $(".form-check-input").prop('checked', false)
                dispatch(QUESTIONINFOS(res.data.results[0]));
                sessionStorage.setItem('questioninfo', JSON.stringify(res.data.results[0]))
                setlistOfTests(res.data.results[0])
                settotalCount(res.data.count)
            }
            if(res.data.next){
                setNext(res.data.next)
            }else{
                setNext(null)
            }
            }
        ).catch((e) => {
            catchforfronterrorlog(e, "no");
            setTimeout(() => {
                setshowsubmitbutton(false)
            }, 10000);
            setloader(true)
        })
    }
    const catchforfronterrorlog = (e, data) => {
       
        const formData = new FormData();
        formData.append("candidate_email",`${sessionStorage.getItem("token")}`)
        formData.append("exception", JSON.stringify(e))
        formData.append("post_data_for_answer",data)
        axios.defaults.headers.common = { 'Authorization': `${sessionStorage.getItem("token")}` }
        axios.post(`/apiV1/catch-error-log/`, formData).then((res) => {
             
        }).catch((e) => {
            console.log(e)
        });
    }
    const sectionTimeOutForNextQuestion = () => {
        let testid = sessionStorage.getItem("testid")
        let questioninfo = JSON.parse(sessionStorage.getItem("questioninfo"))

        const formData = new FormData();
        formData.append("last_question_attempted",questioninfo.id)
        formData.append("section_name",questioninfo.category)
        formData.append("remarks",'Section time out')
        axios.defaults.headers.common = { 'Authorization': `${sessionStorage.getItem("token")}` }
        axios.put(`/apiV1/section-timed-out/${testid}/`, formData).then((res) => {
             
        }).catch((e) => {
            catchforfronterrorlog(e, 'section time out');
            console.log(e)
        });
    }
    useEffect(() => {
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search.slice(1));
        let obj = {};
        for(let pair of params.entries()) {
            obj[pair[0]] =pair[1];
        }
        if(obj.sectionName !== "Coding"){
            // Set a timer for 5 seconds (5000 milliseconds)
            let time = sessionStorage.getItem("timer")
            if(time){
                let totaltime = time * 60 * 1000
                let percent = (totaltime*80)/100
                const timer20per = setTimeout(() => {
                    toast('You have only 20% time left in this section please check remaining question',)
                
                }, percent);
                    
                const timer = setTimeout(() => {
                    setLinkGenShowModal2(true)
                    sectionTimeOutForNextQuestion()
                }, totaltime);
                return () => {
                    clearTimeout(timer);
                    clearTimeout(timer20per);
                }
            }
        }
    }, []); // The empty dependency array ensures that this effect runs once on mount
    
    useEffect(()=>{
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search.slice(1));
        let obj = {};
        for(let pair of params.entries()) {
            obj[pair[0]] =pair[1];
        }

        setattempt_id(obj.attemtID)
        setcandidate_id(obj.candidate_id)
        sessionStorage.setItem("teststartTime", new Date())
        sessionStorage.setItem("testid", obj.testID)
        fetchQuestionsfromprofile(obj.id,null, obj.candidate_id)
        setid(obj.id)
        settestid(obj.testID)
        setsectionName(obj.sectionName)
        if(obj.sectionName == "Coding"){
            window.onblur = null;
            document.removeEventListener('visibilitychange', (event)=>{})
            setcodingquestion(false)
        }
        
        document.addEventListener("visibilitychange", (event) => {
            let tabCount = sessionStorage.getItem("tabCount");
            if (document.visibilityState == "visible") {
                if(tabCount >= 6){

                    stopRecording();
                    navigate(`/errorbrowser?id=${obj.testID}&attemtID=${obj.attemtID}&refresh=1`);
                }
            } else {
               if(obj.sectionName == "Coding"){

               }else{
                    if(tabCount){
                        alert("Your not allow to change tab")
                        let tabCounts = parseInt(tabCount) + 1
                        sessionStorage.removeItem("tabCount")
                        sessionStorage.setItem("tabCount", tabCounts)
                    }else{
                        sessionStorage.setItem("tabCount", 1)
                    }
               }
                
                
              
            }
        });
        
        window.onblur = () => {
           
            if (document.visibilityState == "visible") {
                // alert('on blur')
                let tabCount = sessionStorage.getItem("tabCount");
                if(tabCount >= 6){
                    stopRecording();
                    navigate(`/errorbrowser?id=${obj.testID}&attemtID=${obj.attemtID}&refresh=1`);
                }else{
                    if(tabCount){
                        alert("Your not allow to change tab")
                        let tabCounts = parseInt(tabCount) + 1
                        sessionStorage.removeItem("tabCount")
                        sessionStorage.setItem("tabCount", tabCounts)
                        window.onblur = null
                    }else{
                        sessionStorage.setItem("tabCount", 1)
                    }
                }
            }
            
        };
        if(obj.sectionName !== "Coding"){
            window.addEventListener('popstate', function () {
                
                this.alert('Your not allow to back')
                navigate(`/answerthequestion?id=${obj.id}&attemtID=${obj.attemtID}&testID=${obj.testID}&sectionName=${obj.sectionName}&candidate_id=${obj.candidate_id}`)
            });
        }

        if(obj.sectionName == "Coding"){
            window.onblur = null;

            document.removeEventListener('visibilitychange', (event)=>{})
            setcodingquestion(false)
        }
        
    }, [])
    const  [count, setcount] = useState(0)
    const submitYourAnswer = (data) => {
        
        dispatch(increment())
        //clearTimeout(timeoutsession);
        setloader(false)
        setTimeout(() => {
            if(next){
                fetchQuestionsfromprofile(id, next, candidate_id)
                
            }else{

                navigate(`/instructions2?id=${testid}&attemtID=${attempt_id}&candidate_id=${candidate_id}&refresh=1`);
            }
        }, 2000);
        const recordedBlob = new Blob(data, { type: 'video/webm' });
        const recordedUrl = URL.createObjectURL(recordedBlob);
        console.log(recordedUrl)
        const formData = new FormData();
        formData.append("question_id",listOfTests.id )
        formData.append("question_name",listOfTests.question_name )
        formData.append("section_name", sectionName)
        formData.append("question_type",listOfTests.question_type  )
        formData.append("video_file",  recordedBlob)
        formData.append("choices",listOfTests.choices.length !== 0 ? JSON.stringify(listOfTests.choices) : "" )
        formData.append("attempt_id",attempt_id )
        if(listOfTests.choices.length !== 0){
            listOfTests.choices.map((choic, i)=>{
                if(choic.selected){
                    formData.append("answer",choic.text)
                    formData.append("answer_id",choic.id)
                }
                return choic
            })
            
        }else{
            formData.append("answer", "")
            formData.append("answer_id","")
        }
        var object = {};
        formData.forEach((value, key) => object[key] = value);
        var json = JSON.stringify(object);
 
        const config = {
            headers: { Authorization: `${sessionStorage.getItem("token")}`, 'content-type': 'multipart/form-data'}
        }
        axios.post(`/apiV1/post-answer/`,formData, config).then((res) => {
            if(res.data){
                
                dispatch(decrement())
                if(res.data.id){
                    console.log(res.data)
                }else{
                    catchforfronterrorlog(res.data, json);
                }
                
            }
        }).catch((e) => {
            catchforfronterrorlog(e, json);
            console.log(e)
        });
    }
    useEffect(()=>{
        startRecording()
    }, [listOfTests])
    const videoRef2 = useRef(null);
    const startRecording = async (listOfTests) => {
        const constraints = {
            audio: {
                sampleSize: 1,
                channelCount: 1,
                echoCancellation: true,
                noiseSuppression: true,
                autoGainControl: true,
              
            },
             
            video : {
                width: { ideal: 128 },  
                height: { ideal: 128 },  
                frameRate: { ideal: 10 },  
                facingMode: "user"  
            },
        }
        try {

            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            
            console.log('Video input devices:', videoDevices);
            
            if (videoDevices.length === 0) {
                console.error('No video input devices available.');
                // Handle the case where no cameras are available
                return;
            }
            const defaultVideoDevice = videoDevices[0];
           
            // Set the default video device as the preferred one
            constraints.video.deviceId = { exact: defaultVideoDevice.deviceId };
            //navigator.getUserMedia = ( navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.mediaDevices.getUserMedia);
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            console.log(stream)
            videoRef2.current.srcObject = stream;
            setMediaStream(stream);
            const recorder = new MediaRecorder(stream);
            recorder.start();
            var blobContainer = []
            recorder.ondataavailable = (event) => {
                
                blobContainer.push(event.data)
            };
            
            recorder.onstop = () => {
                submitYourAnswer(blobContainer)
                
            };
            setRecording(true);
        } catch (error) {
            console.error('Error accessing camera:', error);
        }
    };
    const stopRecording = () => {
        if (mediaStream) {
            
            mediaStream.getTracks().forEach((track) => track.stop());
            setMediaStream(null);
            setRecording(false);
            //mediaStream.current.stop();
        }
    };
    
    const yourtimeiscompleted = () => {
        setLinkGenShowModal2(false)
        stopRecording();
        setTimeout(() => {
            navigate(`/instructions2?id=${testid}&attemtID=${attempt_id}&candidate_id=${candidate_id}&refresh=1`);
        }, 1000);
    }
    const onchangefunctionchoice = (data, i) => {
        let alldata = listOfTests

        alldata.choices.map((value, l)=>{
            if(i == l){
                value.selected = true
            }else{
                value.selected = false
            }
            return value
        })
        setshowsubmitbutton(false)
        setlistOfTests(alldata)
    }
    return (
        <>
            <Toaster />
            <Layout>
                <div className="row mx-0">
                    <div className="col-12 my-2">
                        <div className="rounded-5 DarkBlue3_BgColor py-3">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    {/* <Link to="/" className="text-decoration-none"> */}
                                    <img src={talentscanlogo}  alt="logo"/>
                                    {/* </Link> */}
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-md-end text-sm-end text-start">
                                    <h2 className="font_40 font_weight_800 LineHeight29 mb-0 py-3 pe-5"><span className="text-light">Live </span><span className="LightGreen_Color">Interview</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <form className="AnswerVideoForm" id="AnswerVideoForm"> */}
                {codingquestion ?
                    <div>
                        {loader ? 
                        <div className="row mx-0 mt-5">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="TotalQuestionCountInfo">
                                    <div className="row">
                                        <div className="col-xxl-6 col-xl-8 col-lg-12 col-md-12 col-sm-8 col-12 mx-auto text-center">
                                            <div>
                                                <div className="card  d-inline-block ">
                                                    <div className="card-body">
                                                        <h5 className="font_weight_400 font_22">Total Questions {listOfTests.question_no}/{totalCount}</h5>
                                                        <small>Question Type : {sectionName}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        {listOfTests.question_type === "DESC" ? 
                                        <div className="col-xxl-9 col-xl-11 col-lg-12 col-md-12 col-sm-11 col-12 mx-auto">
                                            <div className="LightGrey8_BgColor rounded-2 py-3 text-center">
                                                <h5 className="font_weight_500 font_24 DarkGrey2_Color">Please Answer the Question</h5>
                                                <h4 className="font_weight_500 font_30 DarkBlue4_Color">Q{listOfTests.question_no}. {listOfTests.question_name}</h4>
                                            </div>
                                        </div> : listOfTests.question_type === "MCQ" ? 

                                        <div className="card border-0">
                                            <div className="card-body">
                                                <div className="QuestionContainer LightGrey10_BgColor p-5 rounded-4">
                                                    <h4 className="QuestionIs text-white DarkBlue4_BgColor ps-3 py-2 mb-4 rounded text-break"> {listOfTests.question_no}.  {listOfTests.question_name} </h4>
                                                    {listOfTests.choices.map((choice, i)=>{
                                                        return (
                                                            <div key={i} className=" font_weight_500 font_22 bg-white rounded AnswerOption py-2 mb-2 ps-3">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" onChange={(e)=>{onchangefunctionchoice(choice,i)}} name="QuestionRadios" value={i} id={"CloudlyRadioOption"+choice.id}  />
                                                                    <label className="form-check-label" htmlFor={"CloudlyRadioOption"+choice.id}>{choice.text}</label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div> :""}

                                        <div className="BtnGroup text-center mt-4">
                                            {/* {listOfTests.is_submitted ? <Link to='/codingtypequestion'className="btn btn-primary DarkBlue3_BgColor MyBtnSize font_weight_500 font_20">Next Question</Link> : ""} */}
                                            
                                            {listOfTests.is_submitted  && next ? <button type="button" className="btn btn-primary DarkBlue3_BgColor MyBtnSize font_weight_500 font_20" disabled={!loader}  onClick={()=>fetchQuestionsfromprofile(id, next, candidate_id)}>Next Question</button> : ""} 
                                            {listOfTests.is_submitted && next == null  ? <Link to={`/instructions2?id=${testid}&attemtID=${attempt_id}&candidate_id=${candidate_id}`} className="btn btn-primary DarkBlue3_BgColor MyBtnSize font_weight_500 font_20"  >Next Question</Link> : ""} 
                                        </div>
                                    </div>
                                        
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="AnswerVideoContainer">
                                    <video ref={videoRef2} width={"100%"} muted height={500} autoPlay poster={TestImg1} />
                                </div>
                                 
                                <div className="BtnGroup text-center mt-3">
                                    {listOfTests.is_submitted == false ? <button type="button" disabled={showsubmitbutton} onClick={()=>stopRecording()} className="btn btn-primary DarkBlue3_BgColor MyBtnSize font_weight_500 font_20">Submit </button> : ""}
                                </div>  
                            </div>
                        </div> :<div class="container2">
                    <div class="spinner-container">
                        <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                  </div>}
                    </div> : 

                    <div className="row mx-2 mt-5">
                        <CodingAnswerTheQuestion question={listOfTests} callback={(id, next, candidate_id)=>{fetchQuestionsfromprofile(id, next, candidate_id);}} totalCount={totalCount} next={next}/>
                    </div>}
                {/* </form> */}

                <Modal size="md" show={linkGenShowModal2} onHide={()=>setLinkGenShowModal2(false)} backdrop="static"  keyboard={false}>
                    <Modal.Header>
                        <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Warning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4 className='text-center'>This section time is completed</h4>
                        <div className='text-center m-3'>
                            <button className='btn btn-primary DarkBlue3_BgColor font_20 font_weight_500' id="ScoreBoard_Assign_UpdateOkayBtn" onClick={()=>yourtimeiscompleted()}>Ok</button>
                            {/* <Link to={`/instructions2?id=${testid}&attemtID=${attempt_id}&candidate_id=${candidate_id}&refresh=1`} 
                             >   OK
                            </Link> */}
                        </div>
                    </Modal.Body>
                </Modal>
            </Layout>
        </>
    )
}

export default AnswerTheQuestion;