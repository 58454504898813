import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import { Provider } from 'react-redux';
import store from './Redux/store';
const root = ReactDOM.createRoot(document.getElementById('root'));
if (window.location.host.includes("app.talentscan.ai")){
  axios.defaults.baseURL = "https://engine1.talentscan.ai/"; // Prod
}else if(window.location.host.includes("qa.talentscan.ai")){
  axios.defaults.baseURL = "https://qaengine.talentscan.ai/"; // qa
}else if(window.location.host.includes("dev.talentscan.ai")){
  axios.defaults.baseURL = "https://devengine.talentscan.ai/"; // Dev
}else{
  axios.defaults.baseURL = "https://devengine.talentscan.ai/"; // Dev
}
if(sessionStorage.getItem("Admintoken")){
  axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("Admintoken")}` }
}

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
    
  // </React.StrictMode>
);
reportWebVitals();
