import React, { useState, useEffect } from "react";
import Layout from "../../Components/Layout";
import LoginBg from './../../Images/LoginBg.webp';
import { Link, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";

import axios from "axios";
import talentscanlogo from './../../Images/talent-scan-logo-final.png';
const VerifyEmailId = () => {
    const navigate = useNavigate();

    const [signUpEmailerror, setsignUpEmailerror] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [signUpValidationErrors, setSignUpValidationErrors] = useState({})

    const { register, handleSubmit, formState: { errors }, trigger, setValue, getValues } = useForm({});

    const verifyEmailAction = (data) => {
        setIsSubmitting(true)
        setsignUpEmailerror("");
        let req = {
            email: data.emailAddress,
        }
        
        axios.post('apiV1/forgot-password/',req).then(
            (rep) => {
                setIsSubmitting(false)
                navigate(`/codeforemailverification?mobile=${rep.data.phone}&user_id=${rep.data.user_id}`);
            }
        ).catch((e) => {
            setIsSubmitting(false)
            if(e.response.data.errors != undefined) {
                setSignUpValidationErrors(e.response.data.errors)
            }
            if(e.response.data.message) {
                setsignUpEmailerror(e.response.data.message)
            }
        })
    }

    return (
        <>
            <Layout>
                <div className="row mx-0">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-10 col-sm-12 col-12 mx-auto pt-5">
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="row shadow rounded-1">
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 col-sm-4 col-12  LightGrey_BgColor">
                                        <div className="LoginBg d-flex justify-content-center align-items-center">
                                            <div className="d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none px-5">
                                                <img src={LoginBg} alt="LoginBg" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-8 col-12 px-5 pt-4">
                                        <div className="text-center pt-5 pb-4 mt-5">
                                        <img src={talentscanlogo} />
                                        </div>
                                        <div className="text-center">
                                            <h3 className="font_24 font_weight_600 DarkBlue_Color">Verify Email Id</h3>
                                        </div>
                                        <div className="text-center pt-3">
                                            <p className="font_18 font_weight_400 DarkBlue2_Color">A code has been emailed to you. Please enter it below to Generate OTP for Forgot Password</p>
                                        </div>
                                        <form id="EmailValidationForm" className="EmailValidationForm" onSubmit={handleSubmit(verifyEmailAction)}>
                                            <div className="FormGroup mb-2">
                                                <label htmlFor="LoginForgotPassEmailIdInput" className="FormLabel font_18 font_weight_400 DarkBlue2_Color" >Enter your Email  <span className="font_14 text-danger">*</span></label>
                                                <input type="email" className="form-control FormInput" id="LoginForgotPassEmailIdInput" name="email" placeholder="Email" 
                                                // value={signUpEmail} onChange={(e) => {setSignUpEmail(e.target.value)}} autoComplete="false" 
                                                {...register("emailAddress", {
                                                    required: "This field is required",
                                                    pattern: {
                                                      value:
                                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]{2,}\.+[A-Z]{2,5}$/i,
                                                      message: "Please enter a valid email address.",
                                                    },
                                                  })}
                                                  onKeyUp={(e) => {
                                                    trigger("emailAddress");
                                                    //mailVerification(e)
                                                    setsignUpEmailerror("");
                                                  }}
                                                />
                                                {" "}
                                                {errors.emailAddress && (
                                                    <small className='text-danger font-bold'>
                                                        {errors.emailAddress.message}
                                                    </small>
                                                )}
                                                {/* <small id="LoginForgotPassEmailIdInputErr" className="font_12 text-danger invisible">Code Error</small> */}
                                            </div>

                                            <small className='text-danger font-bold'>{signUpEmailerror}</small>

                                            <div className="BtnGroup d-grid LineHeight22 ">
                                                {/* <Link to="/codeforemailverification"  className="btn btn-primary BlugGradBtn font_18 font_weight_600" > Continue</Link> */}
                                                <button type="submit" disabled={isSubmitting} className="btn btn-primary BlugGradBtn font_18 font_weight_600" id="VerifyEmailIdBtn">Continue</button>
                                                {/* <button type="button" className="btn btn-primary BlugGradBtn font_18 font_weight_600" id="VerifyEmailIdBtn" onclick="VerifyEmailIdCall()">Continue</button> */}
                                            </div>

                                            <div className="BtnGroup text-center pt-3 font_18">
                                                <label className="DarkBlue2_Color">Back to  </label> 
                                                {/* <a href="./index.html" className="DarkBlue2_Color font_weight_500">Login</a> */}
                                                <Link to="/" className="DarkBlue2_Color font_weight_500 text-decoration-none"> Sign In</Link>
                                            </div>
                                            <div className="py-5 my-3"></div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default VerifyEmailId;