import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import axios from "axios";
import Layout from "../Components/Layout";
import { Link, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";
import { useForm } from "react-hook-form";
import { PAGESIZES, PAGESEARCHTESTS, PAGENUMBERS, PAGENUMBERTESTS, PAGESIZETESTS } from './../Redux/actions';
import { Dropdown } from "react-bootstrap";
const Attemptedtestlist2 = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedCandidate = useSelector((state) => state.data);
    const selectedpages = useSelector((state) => state.pagesizetest);
    const searchvalue = useSelector((state) => state.pagesearchtest);
    const pagenumner = useSelector((state) => state.pagenumnertest);
    document.title = "Talent Scan - Attempt Test List"
    const { register, handleSubmit, trigger, getValues, setValue } = useForm({ mode: "all" });
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState([{candidate_info:{}, test_info:{}, score_info:[]}])
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [disabledpdf, setdisabledpdf] = useState(false);
    const [linkGenShowModal, setLinkGenShowModal] = useState(false);
    const [linkGenShowModal2, setLinkGenShowModal2] = useState(false);
    const [deleteconfim, setdeleteconfim] = useState(false);
    const [deleteconfim2, setdeleteconfim2] = useState(false);
    const [deactivesuccess, setdeactivesuccess] = useState(false);
    const [deactivesuccess2, setdeactivesuccess2] = useState(false);
    const [deleteid, setdeleteid] = useState();
    const handleModalLinkGenClose = () => setLinkGenShowModal(false);
    const [toemail, settoemail] = useState("")
    const [SearchTrue, setSearchTrue] = useState(false)
    const [searchErrorMsg, setSearchErrorMsg] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    
    let obj = {};
    for(let pair of params.entries()) {
        obj[pair[0]] =pair[1];
    }

    const id =  obj["id"] ? obj["id"] : 0;
    const pagesize =  obj["pagesize"] ? obj["pagesize"] : 0;
    const columns = [
       
        
       
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Name</label>, sortable: true, sortField: 'industry', cell: row => 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_14 align-middle align-middle">
                    <span>{row.candidate_info.name}</span>
                </div>
            </>,         
        },
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Email</label>, sortable: true, sortField: 'current_last_positon',  cell: row=> 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                    {row.candidate_info.email}
                </div>
            </>,
        },
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Phone</label>, sortable: true, sortField: 'current_last_positon',  cell: row=> 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                    {row.candidate_info.phone}
                </div>
            </>,
        },
        // { name: <label className="DarkBlue4_Color font_weight_500 font_14">Test Name</label>, sortable: true, sortField: 'current_last_positon',  cell: row=> 
        //     <>
        //         <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
        //             {row.test_info.name}
        //         </div>
        //     </>,
        // },
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Profiling Score</label>, sortable: true,   selector:row =>row.score_info_dict?.Profiling, cell: row=> 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                    {row.score_info_dict?.Profiling ? row.score_info_dict?.Profiling :  row.score_info_dict?.Profiling == 0 ? 0 :  "--"}
                </div>
            </>,
        },
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Behavioral Score</label>, sortable: true,  selector:row =>row.score_info_dict?.Behavioural,  cell: row=> 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                    {row.score_info_dict?.Behavioural ? row.score_info_dict?.Behavioural :  row.score_info_dict?.Behavioural == 0 ? 0 :  "--"}
                </div>
            </>,
        },
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Technical Score</label>, sortable: true, selector:row =>row.score_info_dict?.Technical,  cell: row=> 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                    {row.score_info_dict?.Technical ? row.score_info_dict?.Technical :  row.score_info_dict?.Technical == 0 ? 0 : "--"}
                </div>
            </>,
        },
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Coding score</label>, sortable: true, selector:row =>row.score_info_dict?.Coding,  cell: row=> 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                {row.score_info_dict?.Coding ? row.score_info_dict?.Coding :  row.score_info_dict?.Coding == 0 ? 0 :  "--"}
                </div>
            </>,
        },
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">English Score</label>, sortable: true, selector:row =>row.score_info_dict?.English,  cell: row=> 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                {row.score_info_dict?.English ? row.score_info_dict?.English :  row.score_info_dict?.English == 0 ? 0 :  "--"}
                </div>
            </>,
        },
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Total Percentage Score %</label>, sortable: true, selector:row =>row.candidate_percentage_score, cell: row=> 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                    {row.candidate_percentage_score}%
                </div>
            </>,
        },
         
        
        
        // { name: <label className="DarkBlue4_Color font_weight_500 font_14">Behavioural Score</label>, sortable: true, sortField: 'current_last_positon', cell: row=> 
        //     <>
        //         <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
        //             {row.behavioural_scorefront}
        //         </div>
        //     </>,
        // },
       
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Submitted At</label>, sortable: true,  selector:row =>row.submitted_at, sortField: 'current_last_positon',  cell: row=> 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                    {row.submitted_at ? moment(row.submitted_at).format("DD-MM-YYYY HH:mm") : "Not Submited"}
                </div>
            </>,
        },
         
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Check test</label>, sortable: true, sortField: 'current_last_positon',  cell: row=> 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                     
                    {row.is_attempted ? <div><Link to={`/questionresult?id=${id}&attemtid=${row.attempt_id}&section=${row.sectionslist.join(',')}&candidate_id=${row.candidate_info.candidate_id}`}>Check test</Link></div> : ""}
                </div>
            </>,
        },

        // { name: <label className="DarkBlue4_Color font_weight_500 font_14">Share</label>, sortable: true, sortField: 'current_last_positon',  cell: row=> 
        //     <>
        //         <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
        //         {row.attempt_id ? <button className="btn btn-primary" onClick={()=>{setLinkGenShowModal2 (true); settoemail(""); setselectedRow(row)}}>
        //                 Share
        //              </button> : ""}
        //         </div>
        //     </>,
        // },
        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Download PDF</label>, sortable: true, sortField: 'current_last_positon',  cell: row=> 
            <>
                <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                {row.is_attempted ? <button className="btn btn-primary DarkBlue3_BgColor " disabled={disabledpdf} onClick={()=>{ downloadpdffrombackend(row); setdisabledpdf(true); setselectedRow2(row)}}>
                        PDF
                     </button> : ""}
                </div>
            </>,
        },

        { name: <label className="DarkBlue4_Color font_weight_500 font_14">Actions</label>, sortable: true, sortField: 'current_last_positon', width:'8%',  cell: row=> 
            <>
                <div>
                    <select  className="form-select" onChange={(e)=>ActionsForfomwithdropdown(e.target.value, row)}>
                        <option value=""  > Action </option>
                        {row.is_attempted == true ? <option value="ReEvaluate">Re Evaluate</option> : ""}
                        {row.is_attempted == false && row.link_deactivated == false  ?
                        <option value="Deactivate">Deactivate</option> : ""}
                    </select>  
                </div>
            </>,
        },
        
        
    ];
    const [attempt_id, setattempt_id] = useState()
    const ActionsForfomwithdropdown = (data, row) => {
        if(data == "ReEvaluate"){
            setattempt_id(row.attempt_id)
            setdeleteconfim2(true); 
        }else if(data == "Deactivate"){
            setdeleteconfim(true); 
            setdeleteid(row.id)
        }else{

        }
        
    }
    const deleteUser = () => {
        let token = sessionStorage.getItem("Admintoken");
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        }
        axios.delete(`apiV1/attempted-test-list/${deleteid}/`,config).then((res) => {
            if(res.data) {
                setdeleteconfim(false)
                setdeactivesuccess(true)
                console.log(res.data)
                GetTestDashboardList(1)
            }
        }).catch((e) => {
            setLoader(false)
        })
    }
    const haveValuesInserch = (data) => {
        if (data) {
            
          setSearchTrue(true)
        } else {
          setSearchTrue(false)
        }
    
    }
    const downloadpdffrombackend = (selectedRow2) => {
        setdisabledpdf(true)
        const config = {
            responseType: 'blob',
        }
        axios.get(`apiV1/test-result-download/${id}/?attempt_id=${selectedRow2.attempt_id}&candidate_id=${selectedRow2.candidate_info?.candidate_id}`,config).then((res) => {
            setdisabledpdf(false)
            var datass = new Blob([res.data], { type: 'text/pdf' });
            var csvURL = window.URL.createObjectURL(datass);
            const link = document.createElement("a");
            link.download = `${selectedRow2.candidate_info?.name}_${selectedRow2.test_info?.name}.pdf`;
            link.href = csvURL;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            console.log(res.data)
             
        }).catch((e) => {
            setdisabledpdf(false)
            setLoader(false)
        })
    }
    const [selectedRow, setselectedRow] = useState({})
    const [selectedRow2, setselectedRow2] = useState({})
    const [errormsg, seterrormsg] = useState("")
    const mailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]{2,}\.+[A-Z]{2,}$/i
    const shareurltoEmployeers = () => {
        seterrormsg("")
        let data = selectedRow
        
        if (toemail) {
            let url = `apiV1/email/videos/?attempt_id=${data.attempt_id}&test_id=${id}&name=${data.candidate_info.name}&email=${data.candidate_info.email}&phone=${data.candidate_info.phone}&is_qualified=${data.is_qualified}&to_email=${toemail}`
            let token = sessionStorage.getItem("Admintoken");
            const config = {
                headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
            }
            setLinkGenShowModal2(false)
            setLinkGenShowModal(true)
            axios.get(url,config).then((res) => {
                
                console.log(res.data)
                 
            }).catch((e) => {
                setLoader(false)
            })
        }else{
            seterrormsg("Please enter valid email")
        }
    }
    const handlePageChange = page => {
        GetTestDashboardList(page);
        setCurrentPage(page);
        dispatch(PAGENUMBERTESTS(page));
      };
      const handlePerRowsChange = async (newPerPage, page) => {
        dispatch(PAGESIZETESTS(newPerPage));
        GetTestDashboardList(page, newPerPage);
        setPerPage(newPerPage);
      };
    
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const [testnameGlobal, settestnameGlobal] = useState("")
    const [testcriticality, settestcriticality] = useState("")
    const GetTestDashboardList = async (page1, size = perPage) => {
        setLoader(true)
        let token = sessionStorage.getItem("Admintoken");
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
        }
       
        let offcet = parseInt(page1)-1 
        
        axios.get(`apiV1/attempted-test-list/${id}/?limit=${size}&offset=${offcet*size}${getValues('searchValue') && `&search=${getValues('searchValue')}`}`,config).then((res) => {
            if(res.data) {
                setTotalRows(res.data.count)
                setLoader(false)

                res.data.results.map((item, i)=>{
                    let sections = [];
                    item.score_info.map((subitem, k)=>{
                        sections.push(subitem.section__name)  
                        return subitem
                    })   
                    item.sectionslist = sections
                 return item
                })
                if(res.data.results.length !== 0){
                    settestnameGlobal(res.data.results[0].test_info.name)
                    settestcriticality(res.data.results[0].score_info[0].criticality)
                }
                setData(res.data.results)
                setPerPage(size)
                setCurrentPage(page1)
            }
        }).catch((e) => {
            setLoader(false)
        })
    }
    const onSubmits = async (data) => {
        setLoader(true)
        let token = sessionStorage.getItem("Admintoken");
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
        }
       
        dispatch(PAGESEARCHTESTS(getValues('searchValue')));
        axios.get(`apiV1/attempted-test-list/${id}/?limit=${10}&offset=${0}${data.searchValue && `&search=${data.searchValue}`}`,config).then((res) => {
            if(res.data) {
                setTotalRows(res.data.count)
                setLoader(false)

                res.data.results.map((item, i)=>{
                    let sections = [];
                    item.score_info.map((subitem, k)=>{
                        sections.push(subitem.section__name)  
                        return subitem
                    })   
                    item.sectionslist = sections
                 return item
                })
                if(res.data.results.length !== 0){
                    settestnameGlobal(res.data.results[0].test_info.name)
                }
                 
                setLoader(false)
                setData(res.data.results)
                 
            }
        }).catch((e) => {
            setLoader(false)
        })
    }

    const onSubmits2 = async (data) => {
        setLoader(true)
        let token = sessionStorage.getItem("Admintoken");
        const config = {
            headers: { Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data' }
        }
       
        dispatch(PAGESEARCHTESTS(data));
        axios.get(`apiV1/attempted-test-list/${id}/?limit=${10}&offset=${0}${data && `&search=${data}`}`,config).then((res) => {
            if(res.data) {
                res.data.results.map((item, i)=>{
                    let sections = [];
                    item.score_info.map((subitem, k)=>{
                        sections.push(subitem.section__name)  
                        return subitem
                    })   
                    item.sectionslist = sections
                 return item
                })
                setTotalRows(res.data.count)
                setLoader(false)
                setData([...res.data.results])
            }
        }).catch((e) => {
            setLoader(false)
        })
    }
    useEffect(()=>{
        let selectedpage  =  selectedpages ? selectedpages : 10
        let pagenumner2 = pagenumner ? pagenumner : 1
        if(searchvalue){
            setValue("searchValue", searchvalue)
        }
        GetTestDashboardList(pagenumner2, selectedpage, );
    }, [])
    const [selectedrows, setselectedrows] = useState([])
    const [selectedattcan, setselectedattcan] = useState()
    const handleChangeCheckBox = (e) => {

        let temp = ""
        let allselected = e.selectedRows
        let all = allselected.filter((item, i)=>{
            if(item.is_attempted){
                return item
            }
        })
        all.map((item, i)=>{
            if(item.attempt_id && item.candidate_info.candidate_id){
                if(allselected.length-1 == i){
                    temp += item.attempt_id+","+item.candidate_info.candidate_id+":"
                }else{
                    temp += item.attempt_id+","+item.candidate_info.candidate_id+":"
                }
            }
        })
        
        setselectedrows(allselected)
        setselectedattcan(temp)
    };
    const ExpandedComponent = ({ data }) => (
        
        //<pre>{JSON.stringify(console.log(data.clockDetails.overtimeHours,'ll'),data, null, 2)}</pre>
        <>
        <div className="col-md-12">
          <div className='row mx-1'  >
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>No Of Questions</th>
                        <th>Category Score %</th>
                        {/*<th>Cutoff score</th>*/}
                        <th>Candidate Total score</th> 
                        <th>Facial Gesture score</th>
                        <th>Qualified</th>
                        <th>Beginner Score </th>
                        <th>Intermediate Score</th>
                        <th>Advanced Score</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.score_info.map((item, i)=>{
                        return (
                            <tr>
                                <th>{item.section__name} </th>
                                <td>{item.no_of_questions_attempted + "/" +item.no_of_questions } </td>
                                <td>{item.percentage}%</td>
                                {/* <td>{item.cutoff_score}</td> */}
                                <td>{item.score_secured+"/" +item.total_score} </td>
                                <td>{item.behavioural_score} </td>
                                <td>{item.is_qualified ? "Yes": "No"}</td>
                                <td>{ item.total_score_in_beginner ? item.score_secured_in_beginner + "/" +item.total_score_in_beginner : "NA" }</td>
                                <td>{ item.total_score_in_intermediate ? item.score_secured_in_intermediate + "/" +item.total_score_in_intermediate : "NA"  }</td>
                                <td>{ item.total_score_in_advanced ? item.score_secured_in_advanced + "/" +item.total_score_in_advanced : "NA"  }</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

          </div>
          </div>
        </>
      );
      const handleChangeCheckBox2 = (e) => {
        console.log(e)
      }
      const [reloader, setreloader] = useState(false)
      const deleteUser2 = async () => {
        setreloader(true)
        axios.defaults.headers.common = { 'Authorization': `${sessionStorage.getItem("token")}` }
        await  axios.put(`/apiV1/submit-test/${attempt_id}/`).then((res) => {
                 if(res.data){
                    setdeleteconfim2(false); 
                    setdeactivesuccess2(true)
                    setreloader(false)
                 }
                
        }).catch((e) => {
            setreloader(false)
            console.log(e)
        });
      }
    const downloadallpdfformate = () => {
        let req = selectedattcan
        setdisabledpdf(true)
        const config = {
            responseType: 'blob',
        }
        if(req){
            axios.get(`apiV1/test-result-download/${id}/?candidate_data=${req.slice(0, -1)}&bulk_download=true`,config).then((res) => {
                setdisabledpdf(false)
                var datass = new Blob([res.data], { type: 'text/pdf' });
                var csvURL = window.URL.createObjectURL(datass);
                const link = document.createElement("a");
                link.download = `bulkdownload.zip`;
                link.href = csvURL;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                console.log(res.data)
                
            }).catch((e) => {
                setdisabledpdf(false)
                setLoader(false)
            })
        }else{
            setdisabledpdf(false)
            alert("Please select valid tests to download")
        }
    }
    return (
        <>
            <Layout>
            <div className="row mx-5 pt-4 pb-5">
                <div className="col-md-8">
                    <div className="" >
                        <div className="" style={{fontSize:"30px"}}>
                            Test Attempt Candidate List - {testnameGlobal}
                        </div>

                        <div className="" style={{fontSize:"16px"}}>
                            Criticality Level - {testcriticality == 'easy' ? "Beginner" : testcriticality == 'medium' ? "Intermediate" : testcriticality == 'hard' ? "Advanced" : "" }
                        </div>
                        
                        
                    </div>
                </div>
                <div className="col-md-2 text-end">
                    {selectedrows.length !== 0 ?
                    <button onClick={()=>downloadallpdfformate()} disabled={disabledpdf} className="btn btn-primary DarkBlue3_BgColor MyBtnSize font_weight_500 font_20" >Download All</button> : "" }
                </div>
                <div className="col-md-2 text-end">
                    <form className='AddNewDept_form d-inline-block me-2' id='AddNewDept_form' name='AddNewDept_form' onSubmit={handleSubmit(onSubmits)}>
                        <div className="SearchBoxContainer ms-3" style={{ width: "200px" }}>
                        <div className="input-group mb-3 SearchBoxContent">
                            <input {...register("searchValue")} type="text" autoComplete="off" className="form-control border SearchBoxOne" placeholder="Search" aria-label="Search" aria-describedby="SearchIconBtn" onKeyUp={(e) => { onSubmits2(e.target.value); trigger("searchValue"); setSearchErrorMsg(''); haveValuesInserch(e.target.value) }} />
                            <button type="button" className={SearchTrue ? "btn SearchCloseBtn d-none" : "btn SearchCloseBtn d-none"} id="SearchCloseBtn" onClick={() => { setValue('searchValue', ''); setSearchErrorMsg(''); dispatch(PAGESEARCHTESTS(""));  setSearchTrue(false);GetTestDashboardList(1); }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16"><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/></svg></button>
                            <button className="btn border d-none" type="submit" id="SearchIconBtn" ><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/></svg></button>
                        </div>
                        <small className='text-danger font-bold'>{searchErrorMsg}</small>
                        </div>
                    </form>
                </div>
                <div className="col-md-12">
                    {/* <div className="pt-4 pb-5" style={{fontSize:"30px"}}>
                        Test Attempt Candidate List - {testnameGlobal}
                    </div> */}
                    
                    {!loader ? 
                    <DataTable 
                        columns={columns} 
                        data={ data} 
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        paginationServer
                        fixedHeaderScrollHeight='600px'
                        fixedHeader
                        persistTableHead
                        paginationTotalRows={totalRows}
                        expandableRowsComponent={ExpandedComponent}
                        selectableRows={selectedRows}
                        onSelectedRowsChange={(e) => handleChangeCheckBox(e)}
                        clearSelectedRows={toggledClearRows}
                        paginationDefaultPage={currentPage}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        expandableRows
                        
                    />: 
                    <div className='text-center'>
                        <div className='spinner-border ' role='status'>
                            <span className='sr-only'></span>
                        </div>
                    </div>
                    }
                </div>
                <div className="col-md-12 text-end">
                    <Link   to={`/testdashboard`} className="btn btn-primary DarkBlue3_BgColor MyBtnSize font_weight_500 font_20" >Back</Link>
                </div>

            </div>
            </Layout>
            <Modal size='md' show={deleteconfim} onHide={() => setdeleteconfim(false)} className='text-center' backdrop="static" keyboard={false}>
                <Modal.Header closeButton className='modal-header_box bg-my-primary p-3 d-flex justify-content-between font_weight_700'>
                <h3>Deactivate</h3>
                </Modal.Header>
                <Modal.Body>
                <div className='text-center'>
                    <div className='text-success font_54 text-danger'> <i className='fa fa-trash-o' aria-hidden='true'></i> </div>
                    <h4 className='blue_dark_text font_weight_700'> Are you sure you want to deactivate this link! </h4>
                </div>
                <div className='py-2 mb-3'>
                    <div className='row text-center'>
                    <div className='col-6 d-grid'>
                        <button type='button' className='btn btn-primary DarkBlue3_BgColor btn-sm modal2 closeBtn d-block'  onClick={()=>setdeleteconfim(false)}> Cancel </button>
                    </div>
                    <div className='col-6 d-grid'>
                        <button type='submit' className='btn btn-primary DarkBlue3_BgColor btn-sm modal2 addBtn btn-block' onClick={deleteUser}> Deactivate </button>
                    </div>
                    </div>
                </div>
                </Modal.Body>
            </Modal>
            <Modal size='md' show={deleteconfim2} onHide={() => setdeleteconfim2(false)} className='text-center' backdrop="static" keyboard={false}>
                <Modal.Header closeButton className='modal-header_box bg-my-primary p-3 d-flex justify-content-between font_weight_700'>
                <h3>Re Evaluate</h3>
                </Modal.Header>
                <Modal.Body>
                <div className='text-center'>
                    <div className='text-success font_54 text-danger'> <i className='fa fa-trash-o' aria-hidden='true'></i> </div>
                    <h4 className='blue_dark_text font_weight_700'> Are you sure you want to Re Evaluate this test! </h4>
                </div>
                <div className='py-2 mb-3'>
                    <div className='row text-center'>
                        <div className="col-md-12 md-5">
                            {reloader ? 
                            <div className='text-center'>
                                <div className='spinner-border ' role='status'>
                                    <span className='sr-only'></span>
                                </div>
                            </div> : ""}
                        </div>
                    <div className='col-6 d-grid'>
                        <button type='button' className='btn btn-primary DarkBlue3_BgColor btn-sm modal2 closeBtn d-block' disabled={reloader} onClick={()=>setdeleteconfim2(false)}> Cancel </button>
                    </div>
                    <div className='col-6 d-grid'>
                        <button type='submit' className='btn btn-primary DarkBlue3_BgColor btn-sm modal2 addBtn btn-block' disabled={reloader} onClick={deleteUser2}> Re Evaluate </button>
                    </div>
                    </div>
                </div>
                </Modal.Body>
            </Modal>

            <Modal size="md" show={linkGenShowModal2} onHide={()=>setLinkGenShowModal2(false)}>
                <Modal.Header>
                    <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Share With Related Interviewer Team</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="formgroup">
                        <label className="form-field-label" title="Provided Hr Emails to share Candidate Information">Interviewer Emails</label>
                        <input type="email" placeholder="Interviewer Emails with common separation" value={toemail} onChange={(e)=>{settoemail(e.target.value); seterrormsg("")}} className="form-control" />
                        <small className="text-danger"> {errormsg}</small>
                    </div>
                    <div className='text-center m-3'>
                        <button className='btn btn-primary DarkBlue3_BgColor font_20 font_weight_500' id="ScoreBoard_Assign_UpdateOkayBtn" onClick={ ()=>setLinkGenShowModal2(false) }>   Close </button>
                        <button type="button" class="btn btn-primary DarkBlue3_BgColor font_20 font_weight_500" onClick={()=>shareurltoEmployeers()}>Share</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="md" show={linkGenShowModal} onHide={handleModalLinkGenClose}>
                <Modal.Header>
                    <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Share</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <h4 className='text-center'>Thanks! We're merging question videos into one. Check email soon.</h4>
                    <div className='text-center m-3'>
                        <button className='btn btn-primary DarkBlue3_BgColor font_20 font_weight_500' id="ScoreBoard_Assign_UpdateOkayBtn"
                        onClick={ handleModalLinkGenClose }>   OK
                        </button>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal size="md" show={deactivesuccess} onHide={()=>setdeactivesuccess(false)}>
                <Modal.Header>
                    <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <h4 className='text-center'>Your test link has deactivate Successfully</h4>
                    <div className='text-center m-3'>
                        <button className='btn btn-primary DarkBlue3_BgColor font_20 font_weight_500' id="ScoreBoard_Assign_UpdateOkayBtn"
                        onClick={()=> setdeactivesuccess(false) }>   OK
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size="md" show={deactivesuccess2} onHide={()=>setdeactivesuccess2(false)}>
                <Modal.Header>
                    <Modal.Title className="DarkBlue5_Color font_35 font_weight_600 mx-auto">Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <h4 className='text-center'>Your test re evaluate Successfully</h4>
                    <div className='text-center m-3'>
                        <button className='btn btn-primary DarkBlue3_BgColor font_20 font_weight_500' id="ScoreBoard_Assign_UpdateOkayBtn"
                        onClick={()=> setdeactivesuccess2(false) }>   OK
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

             
        </>
    )
}

export default Attemptedtestlist2