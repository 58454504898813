export const SELECTED_COMPANYS = 'SELECTED_COMPANYS';
export const PAGESIZE = 'PAGESIZE';
export const PAGENUMBER = 'PAGENUMBER';
export const PAGESEARCH = 'PAGESEARCH';

export const PAGENUMBERTEST = 'PAGENUMBERTEST';
export const PAGESIZETEST = 'PAGESIZETEST';
export const PAGESEARCHTEST = 'PAGESEARCHTEST';
export const QUESTIONINFO = 'QUESTIONINFO'
export const INCREMENT = 'INCREMENT'
export const DECREMENT = 'DECREMENT'
export const STARTDATE = 'STARTDATE'
export const ENDDATE = 'ENDDATE'
export const RESUMEPARSINGERR = 'RESUMEPARSINGERR'
export const BIOPARSINGERR = 'BIOPARSINGERR'


export const SELECTED_COMPANY = (data) => ({
    
    type: SELECTED_COMPANYS,
    payload: data,
});

export const PAGENUMBERS = (data) => ({
    
    type: PAGENUMBER,
    payload: data,
});

export const PAGESIZES = (data) => ({
    
    type: PAGESIZE,
    payload: data,
});

export const PAGENUMBERTESTS = (data) => ({
    
    type: PAGENUMBERTEST,
    payload: data,
});

export const PAGESIZETESTS = (data) => ({
    
    type: PAGESIZETEST,
    payload: data,
});


export const PAGESEARCHS = (data) => ({
    
    type: PAGESEARCH,
    payload: data,
});

export const PAGESEARCHTESTS = (data) => ({
    
    type: PAGESEARCHTEST,
    payload: data,
});


export const QUESTIONINFOS = (data) => ({
    
    type: QUESTIONINFO,
    payload: data,
});
export const increment = (data) => ({
    type: 'INCREMENT',
    payload: data
  });
  
  export const decrement = (data) => ({
    type: 'DECREMENT',
    payload: data
  });

  export const startdates = (data) => ({
    type: 'STARTDATE',
    payload: data
  });
  
  export const endates = (data) => ({
    type: 'ENDDATE',
    payload: data
  });

  export const RESUMEPARSINGERRS = (data) => ({
    type: 'RESUMEPARSINGERR',
    payload: data
  });
  export const BIOPARSINGERRS = (data) => ({
    type: 'BIOPARSINGERR',
    payload: data
  });