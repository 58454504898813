import React, { useEffect, useState} from "react";
import { Navigate } from "react-router-dom";
import TopHeader from "./Components/TopHeader";
const  PrivateRouter =( {children} ) => {
    const [sideBar, setSideBar] = useState(false);
    // const token = sessionStorage.getItem("Admintoken")
    // const testLogin = sessionStorage.getItem("testLogin")
    const changesidebar = (data) => {
        setSideBar(data);
    }
    const getCookie = () => {
        return sessionStorage.getItem("Admintoken")
    }
    
    useEffect(()=>{
         
        getCookie();
    })
    return (
        <>
            <div id="Wrapper" className={sideBar ? "toggled" : ""}>
               {getCookie() ? 
                    <> <TopHeader changesidebar={(data)=>changesidebar(data)} />  {children} </> :  
                    <Navigate to="/" />
                }
            </div>
        </>
    )
}

export default PrivateRouter;