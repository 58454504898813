import React, { useState } from "react";
import ResumeParsing from "./ResumeParsing";
import BiometricScoring from "./BiometricScoring";
import Layout from "../../Components/Layout";

import PropTypes from "prop-types";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Typography } from "@mui/material";

import NavTabIcon1 from './../../Images/NavTabIcon_1.png';
import NavTabIcon2 from './../../Images/NavTabIcon_3.png';
import { Link } from "react-router-dom";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const DemoSite = () => {
    const [value, setValue] = useState(0);
    document.title = "Talent Scan - Resume Parsing"
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Layout>
                <div className=""></div>
                <div className="row mx-0">
                    <div className="col-12 px-0">
                        {/* <Box sx={{ width: '100%' }} className=" px-0">
                            <Box className="text-center">
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Resume Parsing" {...a11yProps(0)} id="ResumeParsingTab" />
                                    <Tab label="Biometric Scoring" {...a11yProps(1)} id="BiometricScoringTab" />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <ResumeParsing />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <BiometricScoring />
                            </CustomTabPanel>
                        </Box> */}
                    </div>
                    {/* <div className="row mx-0">
                        <div className="col-12 py-5">
                            <div className="text-center">
                                <Link to="/demosite" className="btn btn-primary font_18 font_weight_500 DarkBlue3_BgColor  MyBtnSize border-0 mobileMarging10">Resume Parsing</Link>
                                <Link to="/biometricscoring" className="btn btn-primary font_18 font_weight_500 LightGrey3_BgColor DarkBlue3_Color MyBtnSize ms-3 border-0">Biometric Scoring</Link>
                            </div>
                        </div>
                    </div> */}
                    <div className="row mx-0">
                        <ResumeParsing />
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default DemoSite;