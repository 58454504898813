import React from "react";
import { NavLink,Link, useLocation } from "react-router-dom";

import Logout from "./Logout";
import talentscanlogo from './../Images/talent-scan-logo-final-white.png';
const TopHeader = () => {
    const location = useLocation()
    return (
        <>
            <nav className="navbar navbar-expand-lg" id="TopHeaderNavBar1">
                <div className="container-fluid mx-5 rounded-4  DarkBlue3_BgColor">
                    
                    <Link className="navbar-brand ps-4" to="/dashboard">
                    <img src={talentscanlogo}/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation ">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item">
                                <NavLink className="nav-link font_20 font_weight_300" activeclassname="active" aria-current="page" to="/resumeparsing">Resume Parsing</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link font_20 font_weight_300" activeclassname="active" aria-current="page" to="/biometricscoring">Biometric Scoring</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink  className={location.pathname == `/questionresult` || location.pathname == `/attemptedtestlist2` ? "nav-link font_20 font_weight_300 active" : "nav-link font_20 font_weight_300"}  activeclassname="active" to="/testdashboard" >Dashboard</NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link font_20 font_weight_300" aria-current="page" href="#">Get API Credentials</a>
                            </li>
                            
                            <li className="nav-item">
                                <a className="nav-link font_20 font_weight_300" aria-current="page" href="#">Buy Credits</a>
                            </li>
                             */}
                            {/* <li className="nav-item">
                                <a className="nav-link font_20 font_weight_300" aria-current="page" href="#">Developer Center</a>
                            </li> */}
                        </ul>
                        <ul className="navbar-nav float-end">
                            <li className="nav-item dropdown  pe-4">
                                <a className="nav-link dropdown-toggle  font_20 font_weight_300" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa fa-user-circle-o" aria-hidden="true"></i> Profile
                                </a>
                                <ul className="dropdown-menu" id="ProfileDropdown">
                                    
                                    <li><NavLink className="dropdown-item"  to='/changepassword'>Change Password</NavLink></li>
                                    <li><Logout /></li>
                                    {/* <li>
                                        <Link></Link>
                                        <a className="dropdown-item" href="./index.html">Logout</a>
                                    </li> */}
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default TopHeader;